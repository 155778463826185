import {
    MERCHANT_INFO,
    MERCHANT_DOCUMENT_VERSIONS,
    OVERVIEW_SUMMARY,
    COMPLIANCE_SUMMARY,
    ALL_MERCHANT_USERS,
    MERCHANT_ASVSCANS_REQUESTS,
    MERCHANT_PENTESTS_REQUESTS,
    ALL_COMPUTERS,
    ALL_APPLICATION_SETUPS,
    ALL_SCANS_EXCEPTIONS,
    ALL_MERCHANT_PEOPLE,
    ALL_EXEMPTED_SCANS,
    GITHUB_ACCOUNTS,
    ALL_ACTIVITIES,
    ALL_VULNERABILITY_SCANS,
    NDPR_SUMMARY_BY_CATEORY,
    NDPR_GENERAL_SUMMARY,
    ALL_MERCHANT_INFRASTRUCTURES,
    MERCHANT_INFRASTRUCTURE_INFO,
    MERCHANT_ASSET_GROUP,
    ONE_MERCHANT_PERSON,
    ISO_SUMMARY_BY_ANNEX,
    ISO_GENERAL_SUMMARY,
    ALL_MERCHANT_RISK_ASSESSMENTS,
    FILTERED_MERCHANT_RISK_ASSESSMENTS,
    ALL_MERCHANT_INTELLIGENCE_REPORT,
    FILTERED_MERCHANT_INTELLIGENCE_REPORT,
    SPOOL_RISK_ASSESSMENT,
    SPOOL_INTELLIGENCE_REPORT,
    SOC2_GENERAL_SUMMARY,
    SOC2_SUMMARY_BY_CONTROL,
    SERVER_SCAN_RESULTS,
    ALL_CODE_REVIEW,
    USER_DEVICE_TOKEN,
    ASV_REPORTS_BY_ID,
    USER_SURVEY_RESPONSE,
    PENTESTS_REPORT_BY_ID,
    RISK_ASSESSMENT_SUMMARY,
    ALL_SPOOL_REPORTS,
    ALL_NEEDED_DOCS,
    ALL_MERCHANT_DOCS_RESPONSES,
    ALL_MERCHANT_EXTRA_DOCS,
    USER_DETAILS,
    USER_TYPE,
    USER_ID,
    COMPANY_ID,
    USER_INFO,
    USER_PRICING_PLANS,
    COMPANY_DETAILS,
    FIRST_LOGIN,
} from '../constants';
import { GetUserDetails } from './generalActions';
import axiosInstance from '../../utils/https';
import axios from 'axios';
import { setType, setDomain, getType } from 'utils';
// import { setToken } from 'utils';

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const GetCompanyDetails = (company) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'implementer' ? company : getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/user_mgt/merchants/${company_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_INFO, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateCompanyDetails = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${company_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetCompanyDetails());
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ChangeCompanyLogo = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${company_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetCompanyDetails());
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//ACTIVITIES
export const GetAllActivities = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/activity_log/logs/?organization=${company_id}&page=${credentials?.page}&date_after=${credentials?.start}&date_before=${credentials?.end}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ACTIVITIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//VULNERABILITY
export const GetAllVulnerabilityScans = (merchantID) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || merchantID;
    try {
        const res = await axiosInstance().get(`/scans/vunerabilty_scan/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_VULNERABILITY_SCANS, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RequestVulnerabilityScan = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/vunerabilty_scan/`, { ...credentials, merchant_id: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilityScans());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteVulnerabiltyScan = (scan_id, merchantID) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/vunerabilty_scan/${scan_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilityScans(merchantID));
        return {
            success: true,
            message: 'Vulnerability Scan Deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// COMPLIANCE
// DOCUMENTS
export const GetMerchantDocumentVersions = (doc_id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/compliance/user_document/?merchant_id=${company_id}&document_id=${doc_id}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_DOCUMENT_VERSIONS, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetServerScanResults = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/get_scans/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SERVER_SCAN_RESULTS, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// P E O P L E
export const GetMerchantPeople = (merchantID) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchantID : getState().authReducers?.company_id || merchantID;
    try {
        const res = await axiosInstance().get(`/compliance/people/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_MERCHANT_PEOPLE,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetOneMerchantPerson = (token) => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/compliance/people/?token=${token}`);

        if (res?.status !== 200) throw new Error(res?.message);
        dispatch({
            type: ONE_MERCHANT_PERSON,
            payload: res?.data?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateOneMerchantPerson = (credentials, id, token) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/people/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetOneMerchantPerson(token));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AcknowledgeOnePolicy = (credentials, id, token) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/policy/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetOneMerchantPerson(token));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CompleteBackgroundCheck = (credentials, token) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/backgroundcheck/?token=${token}`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetOneMerchantPerson(token));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddMerchantPeople = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/people/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPeople());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadMerchantPeopleFile = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/people_upload/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPeople());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateMerchantPerson = (credentials, id, merchantID) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/people/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPeople(merchantID));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteMerchantPeople = (id, merchantID) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/people/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPeople(merchantID));
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllComputers = (merchantID) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || merchantID;
    try {
        const res = await axiosInstance().get(`/scans/scripts/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_COMPUTERS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// A P P L I C A T I O N S   S E T U P S
export const GetAllApplicationSetup = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/public_ip/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_APPLICATION_SETUPS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddApplicationSetup = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/public_ip/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApplicationSetup());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateApplicationSetup = (credentials, id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/scans/public_ip/${id}/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApplicationSetup());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteApplicationSetup = (server_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/public_ip/${server_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApplicationSetup());
        return {
            success: true,
            message: 'Server deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Infrastructure Setup
export const GetAllInfrastructures = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/assets/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_MERCHANT_INFRASTRUCTURES,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadMerchantInfrastructureFile = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/server_upload/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllInfrastructures());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetInfrastructureInfo = (ip_address, merchantID) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || merchantID;
    try {
        const res = await axiosInstance().get(`/scans/assets/?merchant_id=${company_id}&ip=${ip_address}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: MERCHANT_INFRASTRUCTURE_INFO,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddAssets = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/assets/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllInfrastructures());
        return {
            success: true,
            message: res.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UploadAssets = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant_id', company_id);
    try {
        const res = await axiosInstance().post(`/scans/asset_upload/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllInfrastructures());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateAssets = (credentials, id, merchantID) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/scans/assets/${id}/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllInfrastructures(merchantID));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetAssetGroups = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/asset_group/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: MERCHANT_ASSET_GROUP,
            payload: res?.data,
        });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddAssetGroup = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/asset_group/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAssetGroups());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateAssetGroup = (credentials, id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/scans/asset_group/${id}/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAssetGroups());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteAssetGroup = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/asset_group/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAssetGroups());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteInfrastucture = (id, merchantID) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/assets/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllInfrastructures(merchantID));
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// T W O   F A C T O R   A C C O U N T
export const GetTwoFactorAccounts = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/github_accounts/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: GITHUB_ACCOUNTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddTwoFactorAccount = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/github_accounts/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetTwoFactorAccounts());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateTwoFactorAccount = (credentials, id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/scans/github_accounts/${id}/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetTwoFactorAccounts());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteTwoFactorAccount = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/github_accounts/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetTwoFactorAccounts());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
//Overview Summary
export const GetMerchantOverviewSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/get_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: OVERVIEW_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantComplianceSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/general_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: COMPLIANCE_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantNDPRSummaryByCategory = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_get_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: NDPR_SUMMARY_BY_CATEORY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantNDPRGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_general_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: NDPR_GENERAL_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantIsoSummaryByAnnex = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_get_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_SUMMARY_BY_ANNEX, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantIsoGeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_general_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_GENERAL_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantSoc2SummaryByControl = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/soc2_get_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOC2_SUMMARY_BY_CONTROL, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantSoc2GeneralSummary = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/soc2_general_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOC2_GENERAL_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//users
export const GetAllMerchantUsers = (id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || id;
    try {
        const res = await axiosInstance().get(`/user_mgt/employees/?organization=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_USERS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};

export const AddMerchantUser = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/user_mgt/employees/`, { ...credentials, organization: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantUsers());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateMerchantUser = (credentials, id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/employees/${id}/`, {
            ...credentials,
            organization: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantUsers());
        return {
            success: true,
            message: 'Successfully updated a user info.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateMerchantSupportUser = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${company_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantUsers());
        dispatch(GetUserDetails());
        return {
            success: true,
            message: 'Successfully updated your support info.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddMerchantSupportUser = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${company_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantUsers());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteMerchantUser = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/users/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantUsers());
        return {
            success: true,
            message: 'User Deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// pentents and asvscan
export const GetMerchantASVscanRequests = (id) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? id : getState().authReducers?.company_id || id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/asvc_requests/avscansrequest/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_ASVSCANS_REQUESTS, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetMerchantAsvscanReportById = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/asv_report/?asvrequest=${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ASV_REPORTS_BY_ID, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RequestASVscan = (credentials, merchantID) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id || merchantID;
    const auditor_id = getState().authReducers?.user_details?.user?.organization?.auditor_detail?.id;
    try {
        const res = await axiosInstance().post(`/asvc_requests/avscansrequest/`, {
            ...credentials,
            merchant_id,
            auditor_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantASVscanRequests(merchantID));
        return {
            success: true,
            message: 'PCI scan request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateASVScan = (credentials, merchantID) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id || merchantID;
    credentials.append('merchant_id', merchant_id);
    try {
        const res = await axiosInstance().post(`/asvc_requests/avscansrequest/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantASVscanRequests(merchantID));
        return {
            success: true,
            message: 'PCI Scan report Uploaded Successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadASVScanCSV = (credentials, merchantID) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id || merchantID;
    credentials.append('merchant', merchant_id);
    try {
        const res = await axiosInstance().post(`/asvc_requests/asvscanuploads/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantASVscanRequests());
        return {
            success: true,
            message: 'PCI scan request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateASVScanRequest = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/asvc_requests/avscansrequest/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantASVscanRequests());
        return {
            success: true,
            message: 'PCI scan updated successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteASVscanRequest = (id, merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/asvc_requests/avscansrequest/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantASVscanRequests(merchant_id));
        return {
            success: true,
            message: 'PCI scan request deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantPentestRequests = (merchantID) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchantID : getState().authReducers?.company_id || merchantID;
    try {
        const res = await axiosInstance().get(`/asvc_requests/pentest_request/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_PENTESTS_REQUESTS, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RequestPentest = (credentials, merchantID) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id || merchantID;
    const auditor_id = getState().authReducers?.user_details?.user?.organization?.auditor_detail?.id;
    try {
        const res = await axiosInstance().post(`/asvc_requests/pentest_request/`, {
            ...credentials,
            merchant_id,
            auditor_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPentestRequests(merchantID));
        return {
            success: true,
            message: 'Penetration test request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreatePentest = (credentials, merchantID) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id || merchantID;
    credentials.append('merchant_id', merchant_id);
    try {
        const res = await axiosInstance().post(`/asvc_requests/pentest_request/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPentestRequests(merchantID));
        return {
            success: true,
            message: 'Penetration test request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdatePentestRequest = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/asvc_requests/pentest_request/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPentestRequests());
        return {
            success: true,
            message: 'Penetration test updated successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeletePentestRequest = (id, merchantID) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/asvc_requests/Pentestrequest/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPentestRequests(merchantID));
        return {
            success: true,
            message: 'Pentest request deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantPentestReportById = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/pentest_report/?pentest_id=${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PENTESTS_REPORT_BY_ID, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data ?? [],
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// exceptions
export const GetAllExemptedScans = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/excempted_scans/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EXEMPTED_SCANS, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllScanExceptions = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/exceptions/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SCANS_EXCEPTIONS, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateScanException = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/exceptions/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedScans());
        dispatch(GetServerScanResults());
        dispatch(GetAllScanExceptions());
        return {
            success: true,
            message: 'Scan added to exception list',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RemoveScanException = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/exceptions/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedScans());
        dispatch(GetServerScanResults());
        dispatch(GetAllScanExceptions());
        return {
            success: true,
            message: 'Scan removed from exception list',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Risk Assessment

export const GetAllMerchantRiskAssessments = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_RISK_ASSESSMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFilteredMerchantRiskAssessments = (page, params, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment/?merchant=${company_id}&page=${page}`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: FILTERED_MERCHANT_RISK_ASSESSMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantIntelligenceReport = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/intelligence_report/?=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_INTELLIGENCE_REPORT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFilteredIntelligenceReport = (page, params, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    const page_ = page ? page : 1;
    try {
        const res = await axiosInstance().get(`/scans/intelligence_report/?merchant=${company_id}&page=${page_}`, {
            params: params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: FILTERED_MERCHANT_INTELLIGENCE_REPORT,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFilteredReports = (page, params, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    const page_ = page ? page : 1;
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment_report/?merchant=${company_id}&page=${page_}`, {
            params: params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_SPOOL_REPORTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteReport = (report_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/risk_assessment_report/${report_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredReports());
        return {
            success: true,
            message: 'Vulnerability Scan Deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetSpoolRiskAssessment = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/spool_risk_assessment/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SPOOL_RISK_ASSESSMENT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetSpoolIntelligenceReport = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/intelligence_report/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SPOOL_INTELLIGENCE_REPORT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddMerchantRiskAssessments = (credentials, page) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials?.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/scans/risk_assessment/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredMerchantRiskAssessments(page));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddMerchantIncidentReport = (credentials, page) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/scans/intelligence_report/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredIntelligenceReport(page));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UploadBulkRiskAssessments = (credentials, merchantID) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || merchantID;
    credentials.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/scans/risk_assessment_uploads/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantRiskAssessments(merchantID));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateMerchantRiskAssessments = (credentials, id, page) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials?.append('merchant', company_id);
    try {
        const res = await axiosInstance().patch(`/scans/risk_assessment/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredMerchantRiskAssessments(page));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateMerchantIncidentReport = (credentials, id, page) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/scans/intelligence_report/${id}/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredIntelligenceReport(page));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteMerchantRiskAssessments = (id, page) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/risk_assessment/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredMerchantRiskAssessments(page));
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetRiskAssessmentSummary = (params, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment_summary/?merchant_id=${company_id}`, {
            params: params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: RISK_ASSESSMENT_SUMMARY, payload: res?.data?.matrix });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteMerchantIncidentReport = (id, page) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/intelligence_report/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFilteredIntelligenceReport(page));
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCodeReview = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/codereview/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CODE_REVIEW, payload: res?.data });
        return {
            success: true,
            message: 'Code review request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const RequestCodeReview = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/codereview/`, {
            ...credentials,
            merchant,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCodeReview());
        return {
            success: true,
            message: 'Code review request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Push Notifications
export const GetUserDeviceToken = () => async (dispatch, getState) => {
    const user_id = getState().generalReducers?.user_info?.id || getType('id');
    try {
        const res = await axiosInstance().get(`/user_mgt/device_token/?user=${user_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: USER_DEVICE_TOKEN, payload: res?.data });
        return {
            success: true,
            message: 'Device token sent successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const SendDeviceToken = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/user_mgt/device_token/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: 'Device token sent successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateDeviceToken = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/device_token/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDeviceToken());
        return {
            success: true,
            message: 'Device token sent successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllSurveyResponse = (user_id) => async (dispatch, getState) => {
    const user = getState().authReducers?.user_id || user_id;
    try {
        const res = await axiosInstance().get(`/user_mgt/survey/?user=${user}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: USER_SURVEY_RESPONSE, payload: res?.data });
        return {
            success: true,
            message: 'Code review request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SubmitSurveyResponse = (credentials, merchant_id, user_id) => async (dispatch, getState) => {
    const organization = getState().authReducers?.company_id || merchant_id;
    const user = getState().authReducers?.user_id || user_id;
    try {
        const res = await axiosInstance().post(`/user_mgt/survey/`, {
            ...credentials,
            organization,
            user,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurveyResponse(''));
        return {
            success: true,
            message: 'Code review request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateSurveyResponse = (id, credentials, merchant_id, user_id) => async (dispatch, getState) => {
    const organization = getState().authReducers?.company_id || merchant_id;
    const user = getState().authReducers?.user_id || user_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/survey/${id}/`, {
            ...credentials,
            organization,
            user,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllSurveyResponse(''));
        return {
            success: true,
            message: 'Code review request successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Data Room
export const GetAllNeededDocs = (company) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id =
        user_type === 'auditor' ? company : user_type === 'implementer' ? company : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/datadoc_upload/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_NEEDED_DOCS, payload: res?.data });
        return {
            success: true,
            message: 'Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantDocumentResponse = (company) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const client_id = getState().implementerReducer?.client_id;
    const company_id =
        user_type === 'auditor'
            ? company
            : user_type === 'implementer'
            ? client_id
            : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/datadoc_response/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_DOCS_RESPONSES, payload: res?.data });
        return {
            success: true,
            message: 'Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadMerchantDocumentResponse = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/datadoc_response/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocumentResponse());
        return {
            success: true,
            message: 'Document Uploaded Successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteMerchantDocumentResponse = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/datadoc_response/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocumentResponse());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantExtraDocs = (company) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const client_id = getState().implementerReducer?.client_id;
    const company_id =
        user_type === 'auditor'
            ? company
            : user_type === 'implementer'
            ? client_id
            : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/doc_upload_files/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_EXTRA_DOCS, payload: res?.data });
        return {
            success: true,
            message: 'Successful',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadMerchantExtraDocs = (company, credentials) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'implementer' ? company : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/doc_file_upload/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantExtraDocs());
        return {
            success: true,
            message: 'Document(s) Uploaded Successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteMerchantExtraDocument = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/doc_upload_files/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantExtraDocs());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SwitchToken = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`${baseUrl}/user_mgt/switch_token/`, credentials);
        const data = res?.data;
        if (res?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        return {
            success: true,
            message: res.message,
            data: data,
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message || err.message };
    }
};

export const SwitchToSecure = (token) => async (dispatch) => {
    try {
        const res = await axios.post(`${baseUrl}/user_mgt/switch_account/?token=${token}`);
        const data = res?.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        // setToken(data?.data?.key);
        setType('type', data?.data?.user?.user_type);
        setDomain();
        setType('role', data?.data?.user?.role);
        setType('id', data?.data?.user?.id);
        // setType('org_id', data?.data?.user?.organization?.id);
        setType('mfa', data?.data?.user?.mfa);
        setType('plan', data?.data?.user?.organization?.merchant_plan?.tag);
        dispatch({ type: FIRST_LOGIN, payload: data?.first_login });
        dispatch({
            type: USER_TYPE,
            payload: data?.data?.user?.user_type,
        });
        dispatch({ type: USER_ID, payload: data?.data?.user?.id });
        dispatch({
            type: COMPANY_ID,
            payload: data?.data?.user?.organization?.id,
        });
        dispatch({ type: USER_DETAILS, payload: data?.data });
        dispatch({ type: USER_INFO, payload: data?.data?.user });
        dispatch({ type: USER_PRICING_PLANS, payload: data?.data?.user?.organization?.merchant_plan });
        dispatch({
            type: COMPANY_DETAILS,
            payload: data?.data?.user?.organization,
        });
        return {
            success: true,
            message: 'User Login Successfully',
            role: data?.data?.user?.role,
            user_type: data?.data?.user?.user_type,
            mfa: data?.data?.user?.mfa,
            mfa_type: data?.data?.user?.mfa_type,
            email: data?.data?.user?.email,
            first_login: data?.first_login,
            company_details: data?.data?.user?.organization,
            user_details: data?.data,
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message || err.message };
    }
};

export const GetDefaultKeywords = () => async () => {
    try {
        const res = await axiosInstance().get(`${baseUrl}/user_mgt/default_keywords/`);
        const data = res?.data;
        if (res?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        return {
            success: true,
            message: res.message,
            data: data,
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message || err.message };
    }
};

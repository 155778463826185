import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import AppButton from 'components/new_components/AppButton';
import PlayIcon from 'assets/img/PlayVideo.svg';
import FileIcon from 'assets/img/PolicyDoc.svg';
import DownloadIcon from 'assets/img/DownloadPolicy.svg';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { formatDateObjectHandler } from 'utils';
import { toast } from 'react-toastify';
import { GetDownloadLinkUnauthorized } from 'store/actions/generalActions';
import OnboardInfo from '../../components/OnboardInfo';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { formatAcknowledgementTimestamp } from '../../utils';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

const PolicyAcknowledgement = (props) => {
    const {
        policies,
        handleAcknowledge,
        acknowledgeSinglePolicy,
        one_merchant_person,
        check,
        id,
        loading,
        increaseOnboardingPolicy,
    } = props;
    const [loadingLink, setLoadingLink] = useState({ id: null, loading: false });
    const [acknowledgeLink, setAcknowledgeLink] = useState({ id: null, loading: false });
    const [downloads, setDownloads] = useState(['']);
    const theme = useTheme();
    const { t } = useTranslation('onboardingPolicy');

    const getDownloadLink = async (id) => {
        if (id && !loadingLink.loading) {
            setLoadingLink({ id, loading: true });
            const res = await GetDownloadLinkUnauthorized('compliance', 'onboarding', id)();
            setLoadingLink({ id, loading: false });
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
        setDownloads((prev) => [...prev, id]);
    };

    const handleAcknowledgeSingle = async (id) => {
        setAcknowledgeLink({
            id,
            loading: true,
        });

        await acknowledgeSinglePolicy({
            policies_downloaded: {
                ...one_merchant_person?.policies_downloaded,
                [id]: formatAcknowledgementTimestamp(Date.now()),
            },
        });
        setAcknowledgeLink({
            id: null,
            loading: false,
        });
    };
    const acknowledged = useMemo(() => {
        return Object.keys(one_merchant_person?.policies_downloaded || {});
    }, [one_merchant_person?.policies_downloaded]);

    const isCompleted = useMemo(() => {
        return (
            policies?.filter((policy) => !policy?.is_deactivated)?.length ==
            Object.keys(one_merchant_person?.policies_downloaded || {})?.length
        );
    }, [one_merchant_person?.policies_downloaded, policies]);

    return (
        <div className="w-full flex justify-center py-10 h-fit">
            <Grid
                item
                md={7}
                // xl={8}
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: '2px',
                }}
            >
                <Stack>
                    <Box
                        sx={{
                            pt: 3,
                            px: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: '18px',
                            }}
                        >
                            {t('steps.step1')}
                        </Typography>
                        <OnboardInfo info={t(`info`)} />
                        {policies?.find((policy) => !policy?.is_deactivated) ? (
                            policies
                                ?.filter((policy) => !policy?.is_deactivated && policy?.tag == 'document')
                                ?.map((policy, ind) => {
                                    let isAcknowledged = acknowledged?.includes(String(policy?.id));
                                    return (
                                        <React.Fragment key={policy?.id + '' + ind}>
                                            <Card
                                                key={policy?.id}
                                                sx={{
                                                    px: 2,
                                                    py: 1.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    boxShadow: 'none',
                                                    borderBottom: '1px solid #F1F5F9',
                                                    height: 'fit-content',
                                                    justifyContent: 'space-between',
                                                    flexWrap: {
                                                        xs: 'wrap',
                                                        md: 'nowrap',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 3,
                                                    }}
                                                >
                                                    {policy?.tag === 'document' ? (
                                                        <img src={FileIcon} alt="File Icon" />
                                                    ) : (
                                                        <img src={PlayIcon} alt="Play Icon" />
                                                    )}
                                                    <Stack
                                                        sx={{
                                                            height: 'fit-content',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: '#395BA9',
                                                                fontSize: '15px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {policy?.name}
                                                        </Typography>

                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: '#94A3B8',
                                                                        fontSize: '13px',
                                                                        fontWeight: 400,
                                                                    }}
                                                                >
                                                                    {formatDateObjectHandler(
                                                                        policy?.date_created,
                                                                        'DD-MM-YYYY'
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                </Box>

                                                <div className="flex gap-1 items-center w-full md:w-fit justify-end">
                                                    <IconButton onClick={() => getDownloadLink(policy?.id)}>
                                                        <img src={DownloadIcon} alt="Download Icon" />
                                                    </IconButton>
                                                    <LoadingButton
                                                        name="Acknowledge"
                                                        variant="contained"
                                                        color="white"
                                                        disabled={
                                                            isAcknowledged
                                                                ? false
                                                                : !downloads.includes(policy?.id) ||
                                                                  acknowledgeLink.loading
                                                        }
                                                        target="_blank"
                                                        loading={
                                                            acknowledgeLink.id === policy?.id && acknowledgeLink.loading
                                                        }
                                                        rel="noreferrer"
                                                        sx={{
                                                            height: 'fit-content',
                                                            border: '1px solid #E2E8F0',
                                                            borderRadius: '4px',
                                                            py: 0.25,
                                                            px: 1,
                                                            fontWeight: '500',
                                                            backgroundColor: '#ffffff',
                                                            textTransform: 'capitalize',
                                                            color: isAcknowledged ? '#55BE8B' : '#64748B',
                                                            boxShadow: '0 0 0 0 !important',

                                                            '&.Mui-disabled': {
                                                                color: '#CBD5E1',
                                                                background: '#F8FAFC',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            isAcknowledged ? null : handleAcknowledgeSingle(policy.id)
                                                        }
                                                    >
                                                        Acknowledge
                                                    </LoadingButton>
                                                </div>
                                            </Card>
                                        </React.Fragment>
                                    );
                                })
                        ) : (
                            <EmptyComponent text="No policy document have been uploaded yet. Check again later." />
                        )}
                    </Box>

                    {check || id?.toString()?.length <= 4 || !isCompleted ? (
                        <AppButton
                            name="Next"
                            color="primary"
                            variant="contained"
                            onClick={increaseOnboardingPolicy}
                            loading={loading}
                            sx={{
                                width: 'fit-content',
                                height: '48px',
                                mb: '20px',
                                mt: 3,
                                ml: 3,
                            }}
                        />
                    ) : (
                        <AppLoadingButton
                            text="Save & Continue"
                            color="primary"
                            variant="contained"
                            onClick={() => handleAcknowledge({ policy: true })}
                            loading={loading}
                            disabled={check || id?.toString()?.length <= 4}
                            sx={{
                                width: 'fit-content',
                                height: '48px',
                                mb: '20px',
                                mt: 3,
                                ml: 3,
                            }}
                        />
                    )}
                </Stack>
            </Grid>
        </div>
    );
};

export default PolicyAcknowledgement;

import React, { useState } from 'react';
// import { useTheme } from '@mui/material/styles';

// core components
import AuthViewContainer from './components/AuthViewContainer';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import PageHeader from 'components/new_components/PageHeader';

// validation
import { passwordValidation } from './utils/authValidation';

// redux
import { UpdatePassword } from 'store/actions/generalActions';
import { UpdateMerchantUser } from 'store/actions/merchantActions';
import { updateAuditorPersonalDetails } from 'store/actions/auditorActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateUserDetails } from 'store/actions/generalActions';

const ChangePassword = (props) => {
    const { UpdatePassword, UpdateMerchantUser, user_info, history, updateAuditorPersonalDetails, UpdateUserDetails } =
        props;
    // state
    const [loading, setLoading] = useState(false);

    // functions

    const UpdateEmployee = async () => {
        const body = {
            first_login: false,
        };
        const user_id = user_info?.employee?.id;

        let res;
        let res2;

        try {
            if (user_id) {
                res = await UpdateMerchantUser(body, user_id);
            }

            res2 = await UpdateUserDetails({ password_reset: false });
            setLoading(false);

            if ((res && !res?.success) || !res2?.success) {
                throw new Error(res?.message || res2?.message || 'An error occurred');
            }
            toast.success('Password updated successfully.');
            history.push(
                user_info?.user_type === 'merchant' || user_info?.user_type === 'employee'
                    ? '/merchant/index'
                    : user_info?.user_type === 'admin' ||
                      user_info?.user_type === 'aud_admin' ||
                      user_info?.user_type === 'aud_admin'
                    ? '/admin'
                    : user_info?.user_type === 'auditor'
                    ? '/auditor'
                    : '/merchant/index'
            );
        } catch (error) {
            setLoading(false);
            toast.error(error?.message || 'Failed to update. Please try again.');
        }
    };

    const updateAuditor = async () => {
        const body = {
            first_login: false,
        };
        const user_id = user_info?.organization?.id;

        let res;
        let res2;

        try {
            if (user_id) {
                res = await updateAuditorPersonalDetails(body, user_id);
            }

            res2 = await UpdateUserDetails({ password_reset: false });
            setLoading(false);

            if ((res && !res?.success) || !res2?.success) {
                throw new Error(res?.message || res2?.message || 'An error occurred');
            }

            toast.success('Password updated successfully.');
            history.push('/admin');
        } catch (error) {
            setLoading(false);
            toast.error(error?.message || 'Failed to update. Please try again.');
        }
    };

    // async functions
    const handleSubmit = async (values) => {
        setLoading(true);
        const res = await UpdatePassword(values);
        if (res?.success) {
            if (user_info?.user_type === 'aud_admin') {
                updateAuditor();
            } else {
                UpdateEmployee();
            }
        } else {
            setLoading(false);
            toast.error(res?.message);
        }
    };

    return (
        <>
            <PageHeader browserTitle="Change Pasword | Smartcomply" />
            <AuthViewContainer
                title="Change Pasword"
                subtitle="Input your credential"
                altLink={{ text: 'create account', to: '/auth/add-merchant' }}
                sx={{ width: { xs: '100%', md: '50%' }, mx: 'auto' }}
            >
                <AppForm
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        confirm_password: '',
                    }}
                    onSubmit={handleSubmit}
                    validate={(values) => passwordValidation(values)}
                >
                    <AppFormInput
                        type="password"
                        label="Old Password"
                        placeholder="Enter old password"
                        name="old_password"
                    />
                    <AppFormInput
                        type="password"
                        label="New Password"
                        placeholder="Enter new password"
                        name="new_password"
                    />
                    <AppFormInput
                        type="password"
                        label="Confirm Password"
                        placeholder="Re-enter new password"
                        name="confirm_password"
                    />
                    <AppSubmitButton
                        text="Submit"
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        sx={{ width: '100%', mt: 3 }}
                        loading={loading}
                    />
                </AppForm>
            </AuthViewContainer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user_info: state?.generalReducers?.user_info,
    };
};

export default connect(mapStateToProps, {
    UpdatePassword,
    UpdateMerchantUser,
    updateAuditorPersonalDetails,
    UpdateUserDetails,
})(ChangePassword);

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui components
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Redux
import { connect } from 'react-redux';
import { CompleteBackgroundCheck, GetOneMerchantPerson, UpdateOneMerchantPerson } from 'store/actions/merchantActions';
import { GetAllMerchantOnboardingItems, GetAllOnboardingItemsForMerchant } from 'store/actions/onboardingActions';

//logo
import logo from 'assets/img/brand/logo.svg';
import Steps from './Stepper/Steps';
import SecurityAwarenessTraining from './StepperViews/SecurityAwarenessTraining';
import WelcomeEmployee from './WelcomeEmployee';

//translation
import { useTranslation } from 'react-i18next';

import IdentityVerification from './StepperViews/IdentityVerification';
import FinishedOnboarding from './StepperViews/FinishedOnboarding';
import PolicyAcknowledgement from './StepperViews/PolicyAcknowledgement';
import CircularLoader from 'components/Loaders/CircularLoader';

const VerifySecurityTraining = (props) => {
    const {
        GetOneMerchantPerson,
        match,
        one_merchant_person,
        UpdateOneMerchantPerson,
        CompleteBackgroundCheck,
        GetAllMerchantOnboardingItems,
        all_merchant_onboarding_items,
        GetAllOnboardingItemsForMerchant,
        all_onboarding_items_for_merchant,
    } = props;

    const { t } = useTranslation('onboardingPolicy');
    const [loading, setLoading] = useState({ page: true });
    const [merchantItems, setMerchantItems] = useState([]);
    const [adminItems, setadminItems] = useState([]);
    const [onboardingPolicy, setOnboardingPolicy] = useState(0);
    const theme = useTheme();

    const increaseOnboardingPolicy = () => {
        setOnboardingPolicy(onboardingPolicy + 1);
    };

    const decreaseOnboardingPolicy = () => {
        setOnboardingPolicy((prev) => (prev >= 0 ? prev - 1 : 0));
    };

    const handleVideoFinish = async (data) => {
        if (one_merchant_person?.[0]?.training) return;
        setLoading({ ...loading, video: true });
        const res = await UpdateOneMerchantPerson(data, one_merchant_person?.[0]?.id, match?.params?.id);
        setLoading({ ...loading, video: false });
        if (res?.success) {
            toast.success('Security Awareness Training completed successfully.');
            increaseOnboardingPolicy();
        } else {
            toast.error(res?.message);
        }
    };

    const handleAcknowledge = async (data) => {
        setLoading({ ...loading, policy: true });
        const res = await UpdateOneMerchantPerson(data, one_merchant_person?.[0]?.id, match?.params?.id);
        setLoading({ ...loading, policy: false });
        if (res?.success) {
            toast.success('All policies acknowledged successfully.');
            increaseOnboardingPolicy();
        } else {
            toast.error(res?.message);
        }
    };
    const acknowledgeSinglePolicy = async (data) => {
        const res = await UpdateOneMerchantPerson(data, one_merchant_person?.[0]?.id, match?.params?.id);

        if (res?.success) {
            toast.success('Policy acknowledged successfully.');
        } else {
            toast.error(res?.message);
        }
    };

    const handleBackgroundCheck = async (data) => {
        setLoading({ ...loading, background: true });
        const res = await CompleteBackgroundCheck({ ...data }, match?.params?.id);
        setLoading({ ...loading, background: false });
        if (res?.success) {
            increaseOnboardingPolicy();
            toast.success('Background Check Completed Successfully');
        } else {
            toast.error(res?.message);
        }
    };

    const getPersonDetails = async () => {
        const res = await GetOneMerchantPerson(match?.params?.id);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getMerchantOnboardingItems = async (merchant) => {
        const res = await GetAllMerchantOnboardingItems(merchant);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllOnboardingItems = async () => {
        const res = await GetAllOnboardingItemsForMerchant();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (match?.params?.id?.toString()?.length >= 4) {
            getPersonDetails();
        }
    }, [match?.params?.id]);
    useEffect(() => {
        if (one_merchant_person?.[0]?.merchant) {
            getMerchantOnboardingItems(one_merchant_person?.[0]?.merchant);
            getAllOnboardingItems();
        }
    }, [one_merchant_person?.[0]?.merchant]);
    useEffect(() => {
        if (all_merchant_onboarding_items?.length) {
            setMerchantItems(all_merchant_onboarding_items);
        }
        if (all_onboarding_items_for_merchant?.length) {
            setadminItems(all_onboarding_items_for_merchant);
        }
    }, [all_merchant_onboarding_items, all_onboarding_items_for_merchant]);

    //  show completed screen when all is done
    useEffect(() => {
        if (one_merchant_person?.[0]) {
            const employee = one_merchant_person?.[0];

            if (employee?.policy && employee?.training && employee?.background_check) {
                setOnboardingPolicy(4);
            }

            setLoading((prev) => ({ ...prev, page: false }));
        }
    }, [one_merchant_person?.[0]]);

    return !one_merchant_person?.[0] || loading?.page ? (
        <CircularLoader size="large" />
    ) : (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.white.main,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: {
                            xs: 2,
                            lg: 5,
                        },
                        py: onboardingPolicy === 0 ? 2 : 0.5,
                        borderBottom: '1px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                            fontSize: '18px',
                        }}
                    >
                        {t('employeeView.employeeOnboarding')}
                    </Typography>
                    <img src={logo} alt="SmartComply logo" width={123} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        backgroundColor: theme.palette.white.main,
                        height: 'fit-content',
                        minHeight: '90dvh',
                    }}
                >
                    {/* <AppSideBar midColour={midColour} /> */}

                    <Stack sx={{ width: '100%' }}>
                        <Box
                            sx={
                                {
                                    // mx: '75px',
                                }
                            }
                        >
                            {onboardingPolicy === 0 && (
                                <WelcomeEmployee
                                    match={match}
                                    one_merchant_person={one_merchant_person}
                                    increaseOnboardingPolicy={increaseOnboardingPolicy}
                                />
                            )}{' '}
                            {onboardingPolicy === 4 && <FinishedOnboarding />}
                            {onboardingPolicy > 0 && onboardingPolicy < 4 ? (
                                <Grid
                                    container
                                    sx={{
                                        backgroundColor: '#f8fafc',
                                        height: '100%',
                                        minHeight: '90dvh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Steps
                                        onboardingPolicy={onboardingPolicy}
                                        setOnboardingPolicy={setOnboardingPolicy}
                                        check={one_merchant_person?.[0]}
                                    />
                                    {onboardingPolicy === 1 ? (
                                        <PolicyAcknowledgement
                                            acknowledgeSinglePolicy={acknowledgeSinglePolicy}
                                            increaseOnboardingPolicy={increaseOnboardingPolicy}
                                            handleAcknowledge={handleAcknowledge}
                                            handlePrev={decreaseOnboardingPolicy}
                                            loading={loading.policy}
                                            check={one_merchant_person?.[0]?.policy}
                                            one_merchant_person={one_merchant_person?.[0]}
                                            policies={Array.from(
                                                new Map(
                                                    adminItems
                                                        ?.filter((item) => item?.tag === 'document')
                                                        ?.map((item) => {
                                                            const one_item = merchantItems?.find(
                                                                (merchant_item) => merchant_item?.name === item?.name
                                                            );
                                                            return one_item || item; // Prioritize merchantItems if found
                                                        })
                                                        ?.map((item) => [item.name, item])
                                                ).values() // Extract unique values
                                            )}
                                            id={match?.params?.id}
                                        />
                                    ) : onboardingPolicy === 2 ? (
                                        <SecurityAwarenessTraining
                                            acknowledgeSinglePolicy={acknowledgeSinglePolicy}
                                            increaseOnboardingPolicy={increaseOnboardingPolicy}
                                            handleVideoFinish={handleVideoFinish}
                                            handlePrev={decreaseOnboardingPolicy}
                                            check={one_merchant_person?.[0]?.training}
                                            one_merchant_person={one_merchant_person?.[0]}
                                            loading={loading?.video}
                                            videos={Array.from(
                                                new Map(
                                                    adminItems
                                                        ?.filter((item) => item?.tag === 'video')
                                                        ?.map((item) => {
                                                            const one_item = merchantItems?.find(
                                                                (merchant_item) => merchant_item?.name === item?.name
                                                            );
                                                            return one_item || item; // Prioritize merchantItems if found
                                                        })
                                                        ?.map((item) => [item.name, item]) //make sure repeated document title, only appear once
                                                ).values()
                                            )}
                                            id={match?.params?.id}
                                        />
                                    ) : onboardingPolicy === 3 ? (
                                        <IdentityVerification
                                            increaseOnboardingPolicy={increaseOnboardingPolicy}
                                            match={match}
                                            one_merchant_person={one_merchant_person?.[0]}
                                            id={match?.params?.id}
                                            loading={loading?.background}
                                            check={one_merchant_person?.[0]?.background_check}
                                            handleBackgroundCheck={handleBackgroundCheck}
                                            handleNext={increaseOnboardingPolicy}
                                            handlePrev={decreaseOnboardingPolicy}
                                        />
                                    ) : null}
                                </Grid>
                            ) : null}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        one_merchant_person: state?.merchantReducers?.one_merchant_person,
        all_merchant_onboarding_items: state?.merchantReducers?.all_merchant_onboarding_items,
        all_onboarding_items_for_merchant: state?.merchantReducers?.all_onboarding_items_for_merchant,
    };
};
export default connect(mapStateToProps, {
    GetOneMerchantPerson,
    UpdateOneMerchantPerson,
    CompleteBackgroundCheck,
    GetAllMerchantOnboardingItems,
    GetAllOnboardingItemsForMerchant,
})(VerifySecurityTraining);

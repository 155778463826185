/* eslint-disable no-unused-vars */
import { ReactComponent as Logo } from 'assets/img/brand/logo.svg';
import { ReactComponent as Loading } from 'assets/img/loading.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../utils/https';

export default function SpoolVendorReport({ targetRef, vendorReport, setVendorReport }) {
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    async function getVendorReport(merchantID) {
        try {
            const response = await axiosInstance().post('/compliance/get_vendor_details/', {
                merchant_id: merchantID,
            });
            setVendorReport(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (merchant_info.id) getVendorReport(merchant_info.id);
    }, [merchant_info.id]);

    // <div className="fixed -bottom-[200%] -right-[200%] h-[85%] w-[746.76px] z-50 bg-white overflow-y-scroll overflow-x-hidden">
    return (
        <div className="fixed -bottom-[200%] -right-[200%] h-[85%] w-[746.76px] z-50 bg-white overflow-y-scroll">
            <main ref={targetRef}>
                <section className="w-[746.76px] h-[1075px] relative">
                    <div className="absolute top-10 left-0">
                        <Logo className="w-[120px]" />
                    </div>

                    <div className="absolute top-1/2 text-center left-1/2 -translate-y-1/2 -translate-x-1/2 transform">
                        <h2 className="text-[#334155] font-bold text-[36px]">VENDOR REPORT</h2>

                        <p className="text-[#475569] font-medium text-xl mt-9">{vendorReport?.report_date}</p>

                        <h3 className="mt-20 text-[#475569] font-semibold text-[28px]">
                            {vendorReport?.merchant_name}
                        </h3>
                    </div>
                </section>

                {vendorReport?.vendor_data?.length &&
                    vendorReport?.vendor_data?.map((vendor, index) => (
                        <section className={`!w-full p-0 relative ${index > 0 && 'mt-20'}`}>
                            <div className={`relative left-0 h-full w-full`}>
                                <h3 className="text-[#2B3674] font-semibold text-xl ml-2">
                                    {index + 1}. {vendor?.vendor_name}
                                </h3>
                                <p className="my-4 text-[#475569] font-semibold text-[17px]">Vendor Details</p>

                                <div className="flex gap-6">
                                    <div className="flex-1 border-t border-[#F1F5F9]">
                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Vendor name
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px]">
                                                    {vendor?.vendor_name}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Vendor type
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px]">
                                                    {vendor?.vendor_type}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="flex-1 border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Vendor email
                                            </p>

                                            <div className="w-[65%] relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] text-wrap">
                                                    {vendor?.vendor_email?.slice(0, 26)}
                                                    {vendor?.vendor_email?.length > 25 && (
                                                        <>
                                                            <br /> {vendor?.vendor_email?.slice(26)}
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Data shared
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] capitalize">
                                                    {vendor?.vendor_data_shared}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Products
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] capitalize">
                                                    {vendor?.vendor_products}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-1 border-t border-[#F1F5F9]">
                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Description of service
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] capitalize">
                                                    {vendor?.vendor_service_desc}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Intended risk level
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <div className="flex-1">
                                                    <div className="w-fit flex mx-2 px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.intended_risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.intended_risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.intended_risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Risk alert level
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px]">
                                                    {vendor?.vendor_risk_alert_level}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {vendor?.questionnaires?.length < 1 ? (
                                    <div className="flex justify-center items-center flex-col gap-2 mb-4 mt-2">
                                        <div className="w-[316px]">
                                            <Loading className="w-[170px]" />
                                        </div>

                                        <p className="text-[#395BA9] font-medium text-lg -mt-[10px]">
                                            No Questionnaire response from this vendor yet
                                        </p>
                                    </div>
                                ) : (
                                    vendor?.questionnaires?.map((q, index) =>
                                        q.questionnaire_has_responded ? (
                                            <div>
                                                <p className="mt-1 relative mb-2 pb-2 text-[#475569] font-semibold text-[17px]">
                                                    {q?.questionnaire_name}
                                                    <br />
                                                </p>

                                                <div className="flex gap-6">
                                                    <div className="flex-1 border-t border-[#F1F5F9]">
                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Business Continuity / Disaster Recovery
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.bus_cont_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.bus_cont_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.bus_cont_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.bus_cont_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Incident Response
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.incident_res_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.incident_res_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.incident_res_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.incident_res_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Risk Management/Auditing /Regulatory Compliance
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.risk_mgt_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.risk_mgt_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.risk_mgt_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.risk_mgt_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Availability
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.avail_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.avail_info?.risk_level === 'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.avail_info?.risk_level ===
                                                                                          'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.avail_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex-1 border-t border-[#F1F5F9]">
                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Organizational Information Security
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.org_info_sec_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.org_info_sec_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.org_info_sec_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.org_info_sec_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                General Security
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.gen_sec_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.gen_sec_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.gen_sec_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.gen_sec_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Network Security
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.net_sec_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.net_sec_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.net_sec_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.net_sec_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                Security Monitoring
                                                            </p>

                                                            <div className="flex-1 relative flex items-center">
                                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                    {q?.sec_mon_info?.percent}
                                                                </p>

                                                                <div className="flex-1">
                                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                        <div>
                                                                            <div
                                                                                className={`w-2 h-2 rounded-full ${
                                                                                    q?.sec_mon_info?.risk_level ===
                                                                                    'Low'
                                                                                        ? 'bg-[#55BE8B]'
                                                                                        : q?.sec_mon_info
                                                                                              ?.risk_level === 'Medium'
                                                                                        ? 'bg-[#F2C021]'
                                                                                        : 'bg-[#FF5449]'
                                                                                }`}
                                                                            />
                                                                        </div>

                                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                            {q?.sec_mon_info?.risk_level}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {q?.aml_info && (
                                                            <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                                <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                                    AML
                                                                </p>

                                                                <div className="flex-1 relative flex items-center">
                                                                    <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                                        {q?.aml_info?.percent}
                                                                    </p>

                                                                    <div className="flex-1">
                                                                        <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                                            <div>
                                                                                <div
                                                                                    className={`w-2 h-2 rounded-full ${
                                                                                        q?.aml_info?.risk_level ===
                                                                                        'Low'
                                                                                            ? 'bg-[#55BE8B]'
                                                                                            : q?.aml_info
                                                                                                  ?.risk_level ===
                                                                                              'Medium'
                                                                                            ? 'bg-[#F2C021]'
                                                                                            : 'bg-[#FF5449]'
                                                                                    }`}
                                                                                />
                                                                            </div>

                                                                            <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                                {q?.aml_info?.risk_level}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="flex gap-2  items-center p-0 h-[42px] justify-center text-[#64748B] border-y-[1px] border-solid border-[#E2E8F0]">
                                                    <strong className="-mt-3.5 font-semibold inline-block text-[#64748B] text-[13px] pr-5 h-fit py-1.5">
                                                        Score card total
                                                    </strong>
                                                    <div className="flex gap-1 items-center p-0">
                                                        <strong className="block font-semibold -mt-4 h-fit text-[16px]  text-[#395BA9]">
                                                            {q?.questionnaire_scorecard}%
                                                        </strong>
                                                        <div className="w-fit flex h-fit px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                            <div
                                                                className={`w-2 h-2 inline-block rounded-full ${
                                                                    q?.questionnaire_risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : q?.questionnaire_risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                            <p className="text-[#64748B] font-medium h-fit inline-blocks text-xs -mt-[15px]">
                                                                {q?.questionnaire_risk_level
                                                                    ? `${q?.questionnaire_risk_level} risk`
                                                                    : ''}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <p className="pl-2 text-[12px] p-0 h-[42px] flex items-center  border-l-[1px]  border-[#E2E8F0] ">
                                                        {' '}
                                                        <span className="-mt-3">Answered by:&nbsp;</span>
                                                        <span className="-mt-3 text-[#395BA9]">
                                                            {q?.recipient_email}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    )
                                )}
                            </div>
                        </section>
                    ))}
            </main>
        </div>
    );
}

import React from 'react';
import { useState } from 'react';

// redux
import { connect } from 'react-redux';
import { SubmitMerchantAnswers } from 'store/actions/complianceActions';
import { GetAllFormsByAnnexTwo } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Isogroups from './components/Isogroups';
import ResourceView from './components/ResourceView';
import { AppForm } from 'components/new_components/forms';
import { validateForm } from '../validation';
import { AppFormContext } from 'components/new_components/forms';

const AuditQuestions = (props) => {
    const {
        loading: loadingIsogroups,
        getIsogroups,
        category,
        view,
        handleViewChange,
        goBack,
        selectedIsogroup,
        GetAllFormsByAnnexTwo,
        SubmitMerchantAnswers,
        all_iso_forms,
        merchant_answers,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
    } = props;

    // state
    const [loading, setLoading] = useState({ content: false });
    const [formPayload, setFormPayload] = useState({});
    const [touchedFields, setTouchedFields] = useState({});

    // async functions
    const getIsogroupQuestions = async (isogroupId) => {
        setLoading((curr) => ({ ...curr, content: true }));
        const res = await GetAllFormsByAnnexTwo(isogroupId);
        setLoading((curr) => ({ ...curr, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error:::::', res?.message);
        }
    };
    const handleQuestionExempted = (isogroupId) => {
        getIsogroups(category);
        getIsogroupQuestions(isogroupId);
    };
    const handleSubmitAnswers = async (values) => {
        setLoading((curr) => ({ ...curr, submit: true }));
        const res = await SubmitMerchantAnswers({
            answer: values,
            form_id: formPayload?.form_id,
        });
        setLoading((curr) => ({ ...curr, submit: false }));
        if (res?.success) {
            toast.success("You have successfully answered this question, click 'NEXT' to go to the next question.");
        } else {
            toast.error('Something went wrong!');
            console.log('Error::::', res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (selectedIsogroup) {
            getIsogroupQuestions(selectedIsogroup);
        }
    }, [selectedIsogroup]);

    return view === 0 ? (
        <Isogroups
            resource="form"
            onViewChange={handleViewChange}
            loading={loadingIsogroups}
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        />
    ) : (
        <AppForm
            initialValues={formPayload.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            <AppFormContext getTouched={setTouchedFields}>
                <ResourceView
                    goBack={goBack}
                    isogroupID={selectedIsogroup}
                    resource="form"
                    merchantResources={merchant_answers}
                    isogroupResources={all_iso_forms}
                    setFormPayload={setFormPayload}
                    loading={loading?.content}
                    touchedFields={touchedFields}
                    submitting={loading?.submit}
                    handleResourceExempted={handleQuestionExempted}
                    openTaskAssignModal={openTaskAssignModal}
                    all_compliance_assigned_tasks={all_compliance_assigned_tasks}
                />
            </AppFormContext>
        </AppForm>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_answers: state?.complianceReducers?.merchant_answers,
        all_iso_forms: state?.adminReducers?.all_isotwo_forms,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};
export default connect(mapStateToProps, {
    SubmitMerchantAnswers,
    GetAllFormsByAnnexTwo,
})(AuditQuestions);

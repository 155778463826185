/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Add, Groups } from '@mui/icons-material';

//core components
import AppTable from 'components/new_components/app-table';
import AppButton from 'components/new_components/AppButton';
import AppTag from 'components/new_components/AppTags';
import EmployeeTableActions from './EmployeeTableActions';
import EmployeeModal from './EmployeeModal';
import AppUploadCSVModal from 'components/new_components/AppUploadCSVModal';
import EmployeeViewModal from './EmployeeViewModal';
import EmployeeDeleteModal from './EmployeeDeleteModal';
import EmployeeAccessDrawer from './EmployeeAccessDrawer';
import useSearch from 'hooks/useSearch';
import FormTooltip from 'components/new_components/FormTooltip';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Menu } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';

//redux
import { connect, useSelector } from 'react-redux';
import { GetMerchantPeople, UploadMerchantPeopleFile } from 'store/actions/merchantActions';
import { GetUserDetails } from 'store/actions/generalActions';
import { RequestKyc } from 'store/actions/FraudGauardianActions';
import { GetAllKycResults, GetKycResults } from 'store/actions/FraudGauardianActions';

//utils
import { initialEmployeeValues } from './utils/constants';
import PageHeader from 'components/new_components/PageHeader';
import SubscriptionAlert from '../../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../../components/Pricing/usePricingHook';

//userguide
import AppUserGuide from 'components/new_components/AppUserGuide';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { steps } from './steps';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const NO_PER_PAGE = 8;

const Employee = ({
    GetMerchantPeople,
    GetUserDetails,
    UploadMerchantPeopleFile,
    all_employees,
    pricing_plan,
    userguide_info,
    GetKycResults,
    merchant_id,
    // employee_id,
    // all_kyc_results,
    // kyc_result,
    GetAllKycResults,
    all_article_header,
}) => {
    //state
    const [loading, setloading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    // const [accessOpen, setAccessOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');
    const [modalPayload, setModalPayload] = useState(initialEmployeeValues);
    const [modalPayloadId, setModalPayloadId] = useState();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [viewAccessOpen, setViewAccessOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState();
    // const [accessPayload, setAccessPayload] = useState();
    const [viewPayload, setViewPayload] = useState();
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [exportDropdownAnchor, setExportDropdownAnchor] = useState(null);
    const [addEmployeeAnchor, setAddEmployeeAnchor] = useState(null);

    //theme
    const theme = useTheme();

    //userguide
    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    // console.log('kyc-results', kyc_result);

    //translation
    const { t } = useTranslation('inventory');

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Employee Onboarding'),
        [all_article_header]
    );
    console.log(oneArticle);

    //functions
    const addEmployeeOption = Boolean(addEmployeeAnchor);
    const exportDropdown = Boolean(exportDropdownAnchor);
    const handleClick = (event) => {
        setAddEmployeeAnchor(event.currentTarget);
    };
    const handleClose = () => {
        setAddEmployeeAnchor(null);
    };
    const openExportDropdown = (event) => {
        setExportDropdownAnchor(event.currentTarget);
    };
    const closeExportDropdown = () => {
        setExportDropdownAnchor(null);
    };

    const openModal = (mode, payload = initialEmployeeValues, id) => {
        setModalOpen(true);
        setModalMode(mode);
        setModalPayload(payload);
        setModalPayloadId(id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalPayload({}), 1000);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ employee: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, employee: false });
        setOpenArticle(false);
    };

    //Upload CSV Modal
    const openUploadCSVModal = () => {
        setUploadModalOpen(true);
        handleClose();
    };
    const closeUploadCSVModal = () => {
        setUploadModalOpen(false);
    };

    //View Modal
    const openViewModal = (payload = initialEmployeeValues) => {
        setViewModalOpen(true);
        setViewPayload(payload);
    };
    const closeViewModal = () => {
        setViewModalOpen(false);
        setTimeout(() => setViewPayload({}, 1000));
    };

    const openAccessModal = (payload = initialEmployeeValues) => {
        setViewAccessOpen(true);
        // setViewPayload(payload);
        // setAccessPayload(payload);
    };
    const closeAccessModal = () => {
        setViewAccessOpen(false);
        // setTimeout(() => setViewPayload({}, 1000));
    };

    //Delete Modal
    const openDeleteModal = (payload = initialEmployeeValues, id) => {
        setDeleteModalOpen(true);
        setDeletePayload(payload);
        setModalPayloadId(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayload({}), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    // hooks
    const { data, handleSearch } = useSearch(all_employees, [
        'first_name',
        'last_name',
        // 'email',
        // 'position',
        'phone',
        'department',
        'address',
    ]);

    //function
    const columns = [
        {
            title: t('employeeTable.column1'),
            render: (row) => <>{row?.first_name + ' ' + row?.last_name}</>,
        },
        // {
        //     title: t('employeeTable.column2'),
        //     key: 'email',
        //     render: (row) => <>{row?.email}</>,
        // },
        {
            title: t('employeeTable.column3'),
            key: 'role',
            render: (row) => <>{row?.position || 'N/A'}</>,
        },
        {
            title: t('employeeTable.column4'),
            key: 'department',
            render: (row) => <>{row?.department || 'N/A'}</>,
        },
        // {
        //     title: t('employeeTable.column5'),
        //     key: 'location',
        //     render: (row) => <>{truncateText(row?.address) || 'N/A'}</>,
        // },
        {
            title: (
                <>
                    {t('employeeTable.column7')}
                    <FormTooltip
                        placement="top-end"
                        text="This metric gauges an employee's operational risk, considering system health, department, card data scan, and background check, to preemptively tackle potential threats."
                    />
                </>
            ),
            key: 'risk_score',
            render: (row) => <>{`${row?.personal_risk_score || 0}%`}</>,
        },
        {
            title: t('employeeTable.column6'),
            key: 'background_check',
            render: (row) => (
                <>
                    <AppTag
                        text={row?.background_check ? t('employeeTable.pass') : t('employeeTable.failed')}
                        type={row?.background_check ? 'success' : 'failed'}
                        sx={{ borderRadius: '4px', fontStyle: 'normal' }}
                        hasIcon={true}
                        noItalic
                    />
                </>
            ),
        },
        {
            title: t('employeeTable.column8'),
            align: 'center',
            key: 'policy_status',
            render: (row) => (
                <>
                    <AppTag
                        hasIcon={true}
                        noItalic
                        icon={
                            <>
                                <CircleIcon
                                    sx={{
                                        color: `${
                                            row?.policy
                                                ? `${theme.palette.success[500]}`
                                                : `${theme.palette.warning[500]}`
                                        }`,
                                        fontSize: '10px',
                                        mx: '2px',
                                        fontStyle: 'normal!important',
                                    }}
                                />
                                {/* removed temporarily because we can't currently display the acknowledged policies */}
                                {/* <CircleIcon
                                sx={{
                                    color: `${
                                        row?.total_policies_downloaded === row?.total_policy_count
                                            ? `${theme.palette.success[500]}`
                                            : `${theme.palette.warning[500]}`
                                    }`,
                                    fontSize: '10px',
                                   mx: '2px',
                                    fontStyle: 'normal',
                                }}
                            /> */}
                            </>
                        }
                        text={`${
                            row?.policy ? t('viewEmployeeModal.acknowledged') : t('viewEmployeeModal.notAcknowledged')
                        } `}
                        sx={{
                            borderRadius: '4px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 8px 4px 8px',
                            gap: '4px',
                            fontStyle: 'revert!important',
                            maxWidth: 'fit-content',
                        }}
                    />
                    {/* temporarily removed policy */}
                    {/*  <AppTag
                        hasIcon={true}
                        icon={
                            <CircleIcon
                                sx={{
                                    color: `${
                                        row?.total_policies_downloaded === row?.total_policy_count
                                            ? `${theme.palette.success[500]}`
                                            : `${theme.palette.warning[500]}`
                                    }`,
                                    fontSize: '10px',
                                    mx: '2px',
                                    fontStyle: 'normal',
                                }}
                            />
                        }
                        text={`${row?.total_policies_downloaded}/${row?.total_policy_count} accepted`}
                        sx={{
                            borderRadius: '4px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 8px 4px 8px',
                            gap: '4px',
                            textTransform: 'lowercase',
                            fontStyle: 'normal',
                            maxWidth: 'fit-content',
                        }}
                    /> */}
                </>
            ),
        },
        {
            title: t('employeeTable.column9'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <EmployeeTableActions
                    row={row}
                    openModal={openModal}
                    openViewModal={openViewModal}
                    openDeleteModal={openDeleteModal}
                    user_type={user_type}
                    role={role}
                />
            ),
        },
    ];

    useEffect(() => {
        const getMerchantPeople = async () => {
            setloading(true);
            const res = await GetMerchantPeople(activeMerchant);
            if (res?.success) {
                setloading(false);
            } else {
                setloading(false);
                toast.error(res?.message);
            }
        };
        getMerchantPeople();
    }, [activeMerchant]);
    useEffect(() => {
        const getAllKycResult = async () => {
            setloading(true);
            const res = await GetAllKycResults();
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllKycResult();
    }, []);
    useEffect(() => {
        const getKycResults = async () => {
            setloading(true);
            const res = await GetKycResults();
            if (res?.success) {
                setloading(false);
            } else {
                setloading(false);
                toast.error(res?.message);
            }
        };
        getKycResults();
    }, []);
    useEffect(() => {
        const getUserDetails = async () => {
            setloading(true);
            const res = await GetUserDetails();
            if (res?.success) {
                setloading(false);
            } else {
                setloading(false);
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.employee ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.employee]);

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.background_check, all_employees?.length);

    const csv_file = `https://iqsa.herokuapp.com/api/compliance/employee_table/?merchant_id=${merchant_id}`;
    const employeePolicyRecord = `http://iqsa.herokuapp.com/api/compliance/employee_policy_report/?merchant_id=${merchant_id}`;

    const exportTableta = (url) => {
        const filename = url.split('/').pop();
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportPolicyRecord = (url) => {
        const filename = url.split('/').pop();
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const convertToCSV = (data) => {
    //     const csvData = [];
    //     const headerRow = ['Full Name', 'Role', 'Department', 'Background Check', 'Risk Score', 'Policy Status'];
    //     csvData.push(headerRow);

    //     data.forEach((row) => {
    //         const tableData = [
    //             `${row?.first_name} ${row?.last_name}`,
    //             row?.position,
    //             row?.department,
    //             row?.background_check,
    //             row?.personal_risk_score,
    //             row?.policy_status,
    //         ];
    //         csvData.push(tableData);
    //     });
    //     return csvData;
    // };

    return (
        <>
            <PageHeader browserTitle="Employee | Smartcomply" />
            {showReminder && <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} employees`} />}
            <AppTable
                columns={columns}
                data={pageData}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                title={t('title')}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={
                    user_type === 'auditor'
                        ? [
                              <Box>
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '3px',
                                      }}
                                  >
                                      <IconButton
                                          sx={{
                                              color: theme.palette.gray[900],
                                              border: '1px solid #E2E8F0',
                                              borderRadius: '4px',
                                              marginLeft: '3px',
                                              width: 'fit-content',
                                          }}
                                          id="toggle-export"
                                          onClick={openExportDropdown}
                                      >
                                          <MoreVert />
                                      </IconButton>
                                  </Box>
                                  <Menu
                                      anchorEl={exportDropdownAnchor}
                                      id="toggle-export"
                                      open={exportDropdown}
                                      onClose={closeExportDropdown}
                                      MenuListProps={{
                                          'aria-labelledby': 'toggle-export',
                                          role: 'listbox',
                                      }}
                                      PaperProps={{
                                          elevation: 0,
                                          sx: {
                                              overflow: 'visible',
                                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                              mt: 1.5,
                                              borderRadius: '4px',
                                          },
                                      }}
                                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                  >
                                      {/* <CSVLink
                                                    data={convertToCSV(pageData)}
                                                    filename="table_record.csv"
                                                    target="_blank"
                                                    sx={{ textDecoration: 'none', color: 'inherit' }}
                                                >
                                                    <MenuItem
                                                        sx={{ fontSize: 13, fontWeight: 500, color: theme.palette.neutral[50] }}
                                                        onClick={() => exportTableta(csv_file)}
                                                    >
                                                        Export table record
                                                    </MenuItem>
                                                </CSVLink> */}
                                      <MenuItem
                                          sx={{
                                              fontSize: 13,
                                              fontWeight: 500,
                                              color: theme.palette.neutral[50],
                                          }}
                                          onClick={() => {
                                              exportTableta(csv_file);
                                              console.log('table link clicked');
                                          }}
                                      >
                                          Export table record
                                      </MenuItem>
                                      <MenuItem
                                          sx={{
                                              fontSize: 13,
                                              fontWeight: 500,
                                              color: theme.palette.neutral[50],
                                          }}
                                          onClick={() => exportTableta(employeePolicyRecord)}
                                      >
                                          Export employee policy record
                                      </MenuItem>
                                  </Menu>
                              </Box>,
                          ]
                        : role === 'audior'
                        ? [
                              <Box>
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '3px',
                                      }}
                                  >
                                      <IconButton
                                          sx={{
                                              color: theme.palette.gray[900],
                                              border: '1px solid #E2E8F0',
                                              borderRadius: '4px',
                                              marginLeft: '3px',
                                              width: 'fit-content',
                                          }}
                                          id="toggle-export"
                                          onClick={openExportDropdown}
                                      >
                                          <MoreVert />
                                      </IconButton>
                                  </Box>
                                  <Menu
                                      anchorEl={exportDropdownAnchor}
                                      id="toggle-export"
                                      open={exportDropdown}
                                      onClose={closeExportDropdown}
                                      MenuListProps={{
                                          'aria-labelledby': 'toggle-export',
                                          role: 'listbox',
                                      }}
                                      PaperProps={{
                                          elevation: 0,
                                          sx: {
                                              overflow: 'visible',
                                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                              mt: 1.5,
                                              borderRadius: '4px',
                                          },
                                      }}
                                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                  >
                                      {/* <CSVLink
                                                    data={convertToCSV(pageData)}
                                                    filename="table_record.csv"
                                                    target="_blank"
                                                    sx={{ textDecoration: 'none', color: 'inherit' }}
                                                >
                                                    <MenuItem
                                                        sx={{ fontSize: 13, fontWeight: 500, color: theme.palette.neutral[50] }}
                                                        onClick={() => exportTableta(csv_file)}
                                                    >
                                                        Export table record
                                                    </MenuItem>
                                                </CSVLink> */}
                                      <MenuItem
                                          sx={{
                                              fontSize: 13,
                                              fontWeight: 500,
                                              color: theme.palette.neutral[50],
                                          }}
                                          onClick={() => {
                                              exportTableta(csv_file);
                                              console.log('table link clicked');
                                          }}
                                      >
                                          Export table record
                                      </MenuItem>
                                      <MenuItem
                                          sx={{
                                              fontSize: 13,
                                              fontWeight: 500,
                                              color: theme.palette.neutral[50],
                                          }}
                                          onClick={() => exportTableta(employeePolicyRecord)}
                                      >
                                          Export employee policy record
                                      </MenuItem>
                                  </Menu>
                              </Box>,
                          ]
                        : [
                              <Box>
                                  <Box>
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
                                          <AppButton
                                              icon={<Add />}
                                              name={t('addEmployee')}
                                              color="primary"
                                              variant="contained"
                                              disabled={pricing_plan?.background_check === all_employees?.length}
                                              disableElevation
                                              onClick={() => openModal('add')}
                                              key={1}
                                              ref={(el) => (stepsTargetRef.current[0] = el)}
                                              sx={{ borderRadius: '4px 0px 0px 4px' }}
                                          />
                                          {/* <AppButton
                                            onClick={toggleDropDown}
                                            icon={
                                                dropdown ? (
                                                    <ExpandLess sx={{ color: `white` }} />
                                                ) : (
                                                    <ExpandMore sx={{ color: 'white' }} />
                                                )
                                            }
                                            color="primary"
                                            variant="contained"
                                            disabled={pricing_plan?.background_check === all_employees?.length}
                                            disableElevation
                                            sx={{ height: '100%', width: 'fit-content' }}
                                        /> */}
                                          <Box>
                                              <Box
                                                  sx={{
                                                      background: '#002C72',
                                                      color: '#FFFFFF',
                                                      height: '95%',
                                                      padding: '8px',
                                                      borderRadius: '0px 4px 4px 0px',
                                                      // marginLeft: '-.5px',
                                                      cursor: 'pointer',
                                                  }}
                                                  onClick={handleClick}
                                                  ref={(el) => (stepsTargetRef.current[1] = el)}
                                              >
                                                  {addEmployeeOption ? (
                                                      <ExpandLess sx={{ color: `white` }} />
                                                  ) : (
                                                      <ExpandMore sx={{ color: 'white' }} />
                                                  )}
                                              </Box>
                                              <Menu
                                                  anchorEl={addEmployeeAnchor}
                                                  id="toggle-export"
                                                  open={addEmployeeOption}
                                                  onClose={handleClose}
                                                  MenuListProps={{
                                                      'aria-labelledby': 'toggle-export',
                                                      role: 'listbox',
                                                  }}
                                                  PaperProps={{
                                                      elevation: 0,
                                                      sx: {
                                                          overflow: 'visible',
                                                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                          mt: 1.5,
                                                          borderRadius: '4px',
                                                      },
                                                  }}
                                                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                              >
                                                  <MenuItem
                                                      sx={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                          color: theme.palette.neutral[50],
                                                      }}
                                                      onClick={openUploadCSVModal}
                                                  >
                                                      Upload from CSV
                                                  </MenuItem>
                                              </Menu>
                                          </Box>

                                          <Box>
                                              <Box
                                                  sx={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      gap: '3px',
                                                  }}
                                              >
                                                  <IconButton
                                                      sx={{
                                                          color: theme.palette.gray[900],
                                                          border: '1px solid #E2E8F0',
                                                          borderRadius: '4px',
                                                          marginLeft: '3px',
                                                          width: 'fit-content',
                                                      }}
                                                      id="toggle-export"
                                                      onClick={openExportDropdown}
                                                  >
                                                      <MoreVert />
                                                  </IconButton>
                                              </Box>
                                              <Menu
                                                  anchorEl={exportDropdownAnchor}
                                                  id="toggle-export"
                                                  open={exportDropdown}
                                                  onClose={closeExportDropdown}
                                                  MenuListProps={{
                                                      'aria-labelledby': 'toggle-export',
                                                      role: 'listbox',
                                                  }}
                                                  PaperProps={{
                                                      elevation: 0,
                                                      sx: {
                                                          overflow: 'visible',
                                                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                          mt: 1.5,
                                                          borderRadius: '4px',
                                                      },
                                                  }}
                                                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                              >
                                                  {/* <CSVLink
                                                    data={convertToCSV(pageData)}
                                                    filename="table_record.csv"
                                                    target="_blank"
                                                    sx={{ textDecoration: 'none', color: 'inherit' }}
                                                >
                                                    <MenuItem
                                                        sx={{ fontSize: 13, fontWeight: 500, color: theme.palette.neutral[50] }}
                                                        onClick={() => exportTableta(csv_file)}
                                                    >
                                                        Export table record
                                                    </MenuItem>
                                                </CSVLink> */}
                                                  <MenuItem
                                                      sx={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                          color: theme.palette.neutral[50],
                                                      }}
                                                      onClick={() => {
                                                          exportTableta(csv_file);
                                                          console.log('table link clicked');
                                                      }}
                                                  >
                                                      Export table record
                                                  </MenuItem>
                                                  <MenuItem
                                                      sx={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                          color: theme.palette.neutral[50],
                                                      }}
                                                      onClick={() => exportTableta(employeePolicyRecord)}
                                                  >
                                                      Export employee policy record
                                                  </MenuItem>
                                              </Menu>
                                          </Box>
                                      </Box>
                                  </Box>
                              </Box>,
                              // <AppButton
                              //     icon={<Upload />}
                              //     name={t('uploadFromCSV')}
                              //     color="primary"
                              //     variant="contained"
                              //     disabled={pricing_plan?.background_check === all_employees?.length}
                              //     disableElevation
                              //     // onClick={() => openUploadCSVModal()}
                              //     onClick={() => setDropdown(!dropdown)}
                              //     dropdown={dropdown ? <ExpandLess /> : <ExpandMore />}
                              //     key={2}
                              //     ref={(el) => (stepsTargetRef.current[1] = el)}
                              // />,
                          ]
                }
                search={true}
                onSearch={handleSearch}
            />
            <>
                <EmployeeModal
                    open={modalOpen}
                    handleClose={closeModal}
                    payload={modalPayload}
                    isAddModal={modalMode === 'add'}
                    id={modalPayloadId}
                />
                <AppUploadCSVModal
                    open={uploadModalOpen}
                    handleClose={closeUploadCSVModal}
                    title={t('uploadCSVModal.title')}
                    subheader={t('uploadCSVModal.subtitle')}
                    templateLink="https://secure-gen.s3.amazonaws.com/templates/Employees Upload Template.xlsx"
                    uploadCSVAction={UploadMerchantPeopleFile}
                />
                <EmployeeViewModal
                    open={viewModalOpen}
                    handleClose={closeViewModal}
                    title={t('viewEmployeeModal.title')}
                    width={500}
                    icon={<Groups />}
                    payload={viewPayload}
                    employeeAccess={openAccessModal}
                />
                <EmployeeAccessDrawer
                    open={viewAccessOpen}
                    handleClose={closeAccessModal}
                    title={t('viewEmployeeModal.title')}
                    width={500}
                    icon={<Groups />}
                    payload={viewPayload}
                />
                <EmployeeDeleteModal
                    open={deleteModalOpen}
                    handleClose={closeDeleteModal}
                    payload={deletePayload}
                    id={modalPayloadId}
                />
                <AppUserGuide
                    open={openUserGuide}
                    onClose={CloseUserGuide}
                    steps={steps}
                    targets={stepsTargetRef.current}
                />
                <AppArticle
                    open={openArticle}
                    handleClose={CloseArticle}
                    title="Employee"
                    headerId={oneArticle?.id}
                    icon={true}
                />
            </>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_employees: state?.merchantReducers?.all_merchant_people,
        employee_id: state?.merchantReducers?.all_merchant_people?.id,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.employee,
        kyc_check: state?.generalReducers?.user_info.organization.run_kyc_check,
        all_kyc_results: state?.fraudGuardianReducers?.all_kyc_result,
        kyc_result: state?.fraudGuardianReducers?.kyc_results,
        merchant_id: state.merchantReducers?.merchant_info?.id,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetMerchantPeople,
    UploadMerchantPeopleFile,
    GetUserDetails,
    RequestKyc,
    GetAllKycResults,
    GetKycResults,
})(Employee);

export const TABS = [
    { name: 'Policy Documents', id: 0 },
    { name: 'Awareness Videos', id: 1 },
];

export const notifyOptions = [
    {
        value: true,
        label: {
            title: 'Yes, inform my employees to accept updated policy',
            description:
                'Use this option only if you have made substantial changes that may constitute a new addition to the existing policy.',
        },
    },
    {
        value: false,
        label: {
            title: 'No, don’t inform my employees to accept updated policy',
            description:
                'Use this option only if you have made minor changes like grammatical error and typos which doesn’t affect the policy.',
        },
    },
];
export function formatAcknowledgementTimestamp(timestamp) {
    const date = new Date(timestamp);
    const pad = (num) => num.toString().padStart(2, '0');
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function capitalizeFirstLetter(str) {
    if (!str) return str;
    const [first, ...rest] = str;
    return first.toUpperCase() + rest.join('');
}

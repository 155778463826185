import React from 'react';
import { Box } from '@mui/material';
import ResponseItem from './components/ResponseItem';

const ResponseItemContainer = (props) => {
    const { all_questions, activeTab, setActiveTab } = props;

    // console.log({ all_questions });

    return (
        <>
            <Box sx={{ backgroundColor: '#F1F5F9', pt: 3, px: 1, height: '93dvh', overflow: 'auto' }}>
                {all_questions ? (
                    all_questions
                        ?.sort((a, b) => a?.form_id - b?.form_id)
                        ?.map((response) => {
                            return (
                                <ResponseItem
                                    key={response?.id}
                                    title={response?.form_title}
                                    id={response?.id}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                            );
                        })
                ) : (
                    <div></div>
                )}
            </Box>
        </>
    );
};

export default ResponseItemContainer;

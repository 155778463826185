import { validation } from 'validate';

export const formatAddedDate = (date) => {
    let formatedDate = new Date(date);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return {
        date: formatedDate.toLocaleDateString('en-US', dateOptions),
        time: formatedDate.toLocaleTimeString('en-US', timeOptions),
    };
};
export const questionnaireValidate = (values) => {
    const errors = {};
    const answerValidate = validation(values.answer, 'Answer', true);

    if (!answerValidate.isValid) {
        errors.answer = answerValidate.errorMessage;
    }

    return errors;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
};

export function shortenString(inputString, maxLength = 158) {
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.substring(0, maxLength) + '...';
    }
}
export function formatQuestionnaireName(questionnaireName) {
    const match = questionnaireName.match(/Questionnaire_(AML|NonAML)(\d+)/);
    if (!match) return shortenString(questionnaireName, 19); // Return original name if it doesn't match pattern

    const type = match[1] === 'AML' ? 'aml' : 'non_aml';
    const number = match[2];

    return `${type} ${number}`;
}

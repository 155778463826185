import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material';
import { Box, LinearProgress, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppButton from 'components/new_components/AppButton';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppInput from 'components/new_components/AppInput';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import React, { useState } from 'react';

//redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { UploadReportFileWithStateUpdate } from 'store/actions/auditActions';
import { UploadMerchantExtraDocs } from 'store/actions/merchantActions';

import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';

const AddDocumentModal = (props) => {
    const [formValues, setFormValues] = useState([{ file: '', filename: '' }]);
    const [uploading, setUploading] = useState({
        status: false,
        index: null,
    });
    const [loading, setLoading] = useState(false);
    const [remoteUploadProgress, setRemoteUploadProgress] = useState(0);
    const { open, handleClose, UploadMerchantExtraDocs } = props;

    const theme = useTheme();
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);
    const dispatch = useDispatch();

    //translation
    const { t } = useTranslation('dataroom');

    //functions
    const resetFile = () => {
        const file = document.querySelector('.file');
        file.value = '';
    };
    const handleItemInputChange = (e, index) => {
        const { name, value } = e.target;

        if (/^[A-Za-z]/?.test(value) || value === '') {
            const newItems = formValues || [];
            newItems[index] = { ...newItems?.[index], [name]: value };
            setFormValues([...newItems]);
        }
    };
    ///handle upload item
    const handleItemUpload = async (e, index) => {
        const formData = new FormData();
        formData.append('report', e.target.files[0]);
        setUploading({
            status: true,
            index,
        });
        const res = await dispatch(UploadReportFileWithStateUpdate(formData, setRemoteUploadProgress));
        setUploading({
            status: false,
            index: null,
        });
        if (res?.success) {
            const newItems = formValues || [];
            newItems[index] = { ...newItems?.[index], file: res?.report };
            newItems[index] = {
                ...newItems?.[index],
            };
            setFormValues([...newItems]);
        }
    };
    //handle add and remove docs
    const AddDoc = () => {
        const newItem = { file: '', filename: '' };
        const dataCopy = [...formValues];
        dataCopy.push(newItem);
        setFormValues(dataCopy);
    };
    const removeDoc = (index) => {
        const dataCopy = [...formValues];
        dataCopy?.splice(index, 1);
        setFormValues(dataCopy);
    };
    const closeAll = () => {
        handleClose();
        setFormValues([{ file: '', filename: '' }]);
        resetFile();
    };
    //async action
    const uploadMerchantExtraDocs = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Check if any formValues are missing filename or file
            const hasMissingFields = formValues.some((docs) => !docs?.filename || !docs?.file);

            if (hasMissingFields) {
                setLoading(false);
                toast.info('All fields are required');
            } else {
                // Assuming UploadMerchantExtraDocs returns a promise
                const res = await UploadMerchantExtraDocs(client_id, { files: formValues });

                if (res?.success) {
                    toast.success(res?.message);
                    resetFile();
                    closeAll();
                } else {
                    toast.error(res?.message);
                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred while uploading documents');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form>
            <AppCenteredModal
                open={open}
                handleClose={closeAll}
                title={t('addDocument')}
                width={470}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            my: 2,
                        }}
                    >
                        <AppLoadingButton
                            text={t('upload')}
                            variant="contained"
                            onClick={uploadMerchantExtraDocs}
                            sx={{
                                width: '100%',
                                backgroundColor: '#011947',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#011947',
                                    color: 'white',
                                },
                            }}
                            disabled={uploading?.status}
                            loading={loading}
                            loadingPosition="center"
                        />
                    </Box>
                }
            >
                {formValues?.map((doc, index) => {
                    const isSingleItem = formValues?.length === 1;
                    const isLastItem = index === formValues?.length - 1;
                    return (
                        <React.Fragment key={index}>
                            <AppInput
                                label={t('docName')}
                                type="text"
                                name="filename"
                                value={doc?.filename}
                                onChange={(e) => handleItemInputChange(e, index)}
                                required
                            />
                            <input
                                type="file"
                                className="mt-3 text-[14px] file"
                                onChange={(e) => handleItemUpload(e, index)}
                            />
                            <div className="flex flex-row justify-between my-2">
                                {/* {doc?.file && <p className="break-all text-[12px]">{doc?.file}</p>} */}
                                {uploading?.status && uploading?.index === index && (
                                    <div className="!h-4 !w-full !rounded overflow-hidden relative cursor-wait">
                                        <LinearProgress
                                            variant="determinate"
                                            value={remoteUploadProgress}
                                            sx={{ height: '16px' }}
                                        />
                                        <p className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-[10px] font-semibold text-white">
                                            {remoteUploadProgress}% Uploaded
                                        </p>
                                    </div>
                                )}
                            </div>
                            <Stack flexDirection="row" justifyContent="space-between" sx={{ mt: 1.5 }}>
                                <AppButton
                                    icon={<AddCircleRounded />}
                                    type="text"
                                    color="primary"
                                    name={t('addDoc')}
                                    sx={{
                                        p: 0,
                                        visibility: (isSingleItem && index === 0) || isLastItem ? 'visible' : 'hidden',
                                    }}
                                    onClick={AddDoc}
                                />

                                <AppButton
                                    icon={<RemoveCircleRounded />}
                                    type="text"
                                    name={t('removeDoc')}
                                    sx={{
                                        color: '#FF5449',
                                        p: 0,
                                        '&.Mui-disabled': {
                                            color: theme.palette.gray[600],
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    disabled={formValues?.length === 1}
                                    onClick={() => removeDoc(index)}
                                />
                            </Stack>
                        </React.Fragment>
                    );
                })}
            </AppCenteredModal>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { UploadMerchantExtraDocs })(AddDocumentModal);

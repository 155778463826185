import React, { useState } from 'react';
import { connect } from 'react-redux';

import QuestionnaireView from './Questionnaire/QuestionnaireView';
import ActingAsAVendorTable from './ActingAsVendorTable';
import { Box } from '@mui/material';

const ActingAsAVendorLayout = () => {
    // const { vendor_uploaded_questionnaire } = props;

    // state
    const [view, setView] = useState(0);
    const [selectedQuestionnaire, setSelectedQuestionaire] = useState(null);

    const handleViewChange = (id) => {
        setView(1);
        setSelectedQuestionaire(id);
    };

    const goBack = () => {
        setView(0);
        setSelectedQuestionaire(null);
    };

    // const completed = useMemo(
    //     () => vendor_uploaded_questionnaire?.filter((upload) => upload?.status === 'completed').length,
    //     [vendor_uploaded_questionnaire]
    // );
    // const notCompleted = useMemo(
    //     () => vendor_uploaded_questionnaire?.filter((upload) => upload?.status !== 'completed').length,
    //     [vendor_uploaded_questionnaire]
    // );

    return (
        <Box sx={{ mt: '3rem', mx: '1.3rem', backgroundColor: '#FFFFFF', p: 0, px: '2rem' }}>
            {view === 0 ? (
                <ActingAsAVendorTable handleViewChange={handleViewChange} />
            ) : (
                <QuestionnaireView
                    goBack={goBack}
                    handleViewChange={handleViewChange}
                    selectedQuestionnaire={selectedQuestionnaire}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        vendor_uploaded_questionnaire: state?.vendorReducers?.vendor_uploaded_questionnaire,
    };
};
export default connect(mapStateToProps)(ActingAsAVendorLayout);

import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppButton from 'components/new_components/AppButton';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

//translation
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const WelcomeEmployee = (props) => {
    const { match, one_merchant_person, increaseOnboardingPolicy } = props;

    const theme = useTheme();

    const merchantInfo = useSelector((state) => state?.merchantReducers?.merchant_info);

    console.log(merchantInfo);

    //translation hook
    const { t } = useTranslation('onboardingPolicy');
    return (
        <Box
            className="w-full relative"
            sx={{
                px: {
                    xs: 5,
                    lg: '7rem',
                },
            }}
        >
            <div className="absolute w-[578px] h-[640px] -top-[35%] -right-20 rounded-full bg-[#FFC87F33] rotate-12"></div>
            <Grid
                container
                spacing={10}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: {
                        lg: 550,
                        xs: 'auto',
                    },
                    paddingBottom: {
                        xs: 8,
                        lg: 0,
                    },
                }}
            >
                <Grid item xs={12} lg={7}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            marginTop: 8,
                        }}
                    >
                        <Stack
                            sx={{
                                width: '100%',
                                gap: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: theme.palette.primary.main,
                                    textTransform: 'capitalize',
                                }}
                            >
                                {t('employeeView.welcomeEmployee.welcome')}
                                {', '}
                                {match?.params?.id?.toString()?.length >= 4
                                    ? `${one_merchant_person?.[0]?.first_name} ${one_merchant_person?.[0]?.last_name}`
                                    : merchantInfo?.name}
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '30px',
                                        lg: '48px',
                                    },
                                    fontWeight: 700,
                                    color: '#002C72',
                                    lineHeight: 1,
                                }}
                            >
                                {t('employeeView.welcomeEmployee.paragraph1')}
                            </Typography>

                            <Typography
                                sx={{
                                    mt: 2,
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: theme.palette.neutral[500],
                                }}
                            >
                                {t('employeeView.welcomeEmployee.paragraph2')}
                            </Typography>
                            <Typography
                                sx={{
                                    mb: 2,
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: theme.palette.neutral[500],
                                }}
                                component="ol"
                            >
                                <li>{t('employeeView.welcomeEmployee.point1')}</li>
                                <li>{t('employeeView.welcomeEmployee.point2')}</li>
                                <li>{t('employeeView.welcomeEmployee.point3')}</li>
                            </Typography>

                            <Typography
                                sx={{
                                    mb: 6,
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: theme.palette.neutral[500],
                                }}
                            >
                                {t('employeeView.welcomeEmployee.paragraph3')}
                            </Typography>

                            <AppButton
                                name={
                                    <>
                                        {t('employeeView.welcomeEmployee.cta')}
                                        <ArrowForwardOutlinedIcon sx={{ marginLeft: '10px' }} />
                                    </>
                                }
                                color="primary"
                                variant="contained"
                                onClick={increaseOnboardingPolicy}
                                sx={{
                                    width: '250px',
                                    height: '55px',
                                }}
                            />
                        </Stack>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={0}
                    lg={4}
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box alignItems="center">
                        <img
                            src={'/img/onboarding-policy/Welcome-employee.png'}
                            alt="Welcome to Smart Comply App"
                            className="w-[367.85px] h-[429px] object-contain"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WelcomeEmployee;

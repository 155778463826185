import React, { useState } from 'react';
import { toast } from 'react-toastify';

// core components
import Controls from './components/Controls';
import SubControls from './components/SubControls';
import { diffJson } from 'diff';
// import { AppForm, AppFormContext } from 'components/new_components/forms';

// redux
import { connect, useDispatch } from 'react-redux';
import { SubmitMerchantAnswers } from 'store/actions/complianceActions';

// validations
// import { validateForm } from '../validation';
import { GetFormsByManagement } from 'store/actions/adminActions';

const AuditQuestions = (props) => {
    const {
        loading: loadingControl,
        merchant_answers,
        forms_by_subcontrol,
        getControls,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
        view,
        setView,
    } = props;

    // state
    const [selectedControl, setSelectedControl] = useState(null);
    const [loading, setLoading] = useState({ content: false, submit: false });
    const [formPayload, setFormPayload] = useState({});
    const [previousPayload, setPreviousPayload] = useState({});
    const [others, setOthers] = useState({});
    const [error, setError] = useState(false);
    // const [touchedFields, setTouchedFields] = useState({});
    const dispatch = useDispatch();

    // functions
    // logic functions
    const handleViewChange = (controlID) => {
        setView(1);
        setSelectedControl(controlID);
    };
    const goBack = () => {
        setView(0);
        setSelectedControl(null);
    };

    // async functions
    const getSubcontrolQuestions = async (controlId) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetFormsByManagement(controlId));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch forms.");
        }
    };
    const handleSubmitAnswers = async () => {
        const check_required_fields = Object?.keys(formPayload)?.map((item) => {
            return Object?.keys(others?.requiredValues)?.some(
                (val) => others?.requiredValues[val] === true && formPayload[item] === ''
            );
        });
        if (check_required_fields.includes(true)) {
            setError(true);
        } else {
            setLoading((current) => ({ ...current, submit: true }));
            const res = await dispatch(
                SubmitMerchantAnswers({
                    answer: formPayload,
                    form_id: others.form_id,
                })
            );
            setLoading((current) => ({ ...current, submit: false }));
            if (res?.success) {
                toast.success("You have successfully answered this question, click 'NEXT' to go to the next question.");
                handleFormExempted(others?.subcontrol);
            } else {
                toast.error('Something went wrong!!');
            }
        }
    };
    const handleFormExempted = (subcontrol) => {
        getControls();
        getSubcontrolQuestions(subcontrol);
    };
    // function equal(obj1, obj2, fieldsToCheckFor = fieldsToCheck) {
    //     return fieldsToCheckFor.every((key) => obj1[key] == obj2[key]);
    // }

    // console.log({ answer });
    const diffCheck = diffJson(formPayload || {}, previousPayload || {}, {
        ignoreWhitespace: true,
    });
    return view === 0 ? (
        <Controls
            onViewChange={handleViewChange}
            loading={loadingControl}
            resource="form"
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        />
    ) : (
        <SubControls
            controlId={selectedControl}
            goBack={goBack}
            resource="form"
            loading={loading?.content}
            onSubcontrolChange={getSubcontrolQuestions}
            merchantResources={merchant_answers}
            subcontrolResources={forms_by_subcontrol}
            setFormPayload={setFormPayload}
            submitting={loading.submit}
            handleResourceExempted={handleFormExempted}
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
            formPayload={formPayload}
            handleSubmitAnswers={handleSubmitAnswers}
            setOthers={setOthers}
            error={error}
            previousPayload={previousPayload}
            setPreviousPayload={setPreviousPayload}
            diffCheck={diffCheck}
        />
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_answers: state?.complianceReducers?.merchant_answers,
        forms_by_subcontrol: state?.adminReducers?.all_admin_forms_by_mgt,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
        user_info: state?.generalReducers?.user_info,
    };
};
export default connect(mapStateToProps, {})(AuditQuestions);

import { Menu, MenuItem } from '@mui/material';
import CustomTooltip from 'components/new_components/CustomTooltip';
// import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MitigationControls from './MitigationControls';

const MitigationForm = ({
    riskMitigation,
    setRiskMitigation,
    residualLikelihoodOrImpact,
    error,
    currency,
    setCurrency,
    recommendations,
    mitigation_controls,
    setMitigationControls,
    residual_impact,
    setResidualImpact,
    residual_likelihood,
    setResidualLikelihood,
    loading,
    is_5x5,
}) => {
    const [localStates, setLocalStates] = useState({
        count: riskMitigation?.mitigation_timeline?.count,
        time: riskMitigation?.mitigation_timeline?.time,
        mitigation_cost_amount: riskMitigation?.mitigation_cost_amount,
        comments: riskMitigation?.comments,
    });
    const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
    const [residualLikelihoodAnchorEl, setResidualLikelihoodAnchorEl] = useState(null);
    const [residualImpactAnchorEl, setResidualImpactAnchorEl] = useState(null);
    const [firstReviewDataAnchorEl, setFirstReviewDataAnchorEl] = useState(null);

    const [risk_settings] = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings || []);

    function extractTextInsideBraces(input) {
        if (input) {
            const match = input?.match(/\(([^)]+)\)/);
            return match ? match?.[1] : null;
        }
    }
    return (
        <>
            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B] mb-1">Mitigation within (timeline)</p>
                <div className="flex gap-4">
                    <div className="flex-1">
                        <div className="flex justify-between items-center !border !border-[#E2E8F0] pl-1 pr-3 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75">
                            <input
                                type="number"
                                inputMode="numeric"
                                placeholder="Enter timeline"
                                value={localStates?.count}
                                onChange={(event) => {
                                    setLocalStates((prev) => ({
                                        ...prev,
                                        count: event.target.value,
                                    }));
                                }}
                                onBlur={(event) =>
                                    setRiskMitigation((prev) => ({
                                        ...prev,
                                        mitigation_timeline: {
                                            ...prev.mitigation_timeline,
                                            count: event.target.value,
                                        },
                                    }))
                                }
                                min={0}
                                max={100}
                                step={1}
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none outline-none bg-transparent !rounded-sm !p-2 h-9 border-none placeholder:opacity-95"
                            />
                            <div className="flex flex-col gap-0.5 justify-center items-center">
                                <img
                                    src="/img/risk-assessment/arrow-up-icon.png"
                                    alt="Arrow Up Icon"
                                    className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                                    aria-disabled={localStates.count === 100}
                                    onClick={() =>
                                        setLocalStates((prev) => ({
                                            ...prev,
                                            count: localStates.count + 1,
                                        }))
                                    }
                                />

                                <img
                                    src="/img/risk-assessment/arrow-down-icon.png"
                                    alt="Arrow Down Icon"
                                    className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                                    aria-disabled={localStates.count === 0}
                                    onClick={() =>
                                        setLocalStates((prev) => ({
                                            ...prev,
                                            count: localStates.count - 1,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                        {error && localStates?.count === 0 && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Count is required</span>
                        )}
                    </div>
                    <div className="flex-1">
                        <button
                            aria-controls={firstReviewDataAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={firstReviewDataAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFirstReviewDataAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    localStates?.time ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {localStates?.time ? localStates?.time : 'Select frequency'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={firstReviewDataAnchorEl}
                            open={Boolean(firstReviewDataAnchorEl)}
                            onClose={() => setFirstReviewDataAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="flex flex-col min-w-[149px]">
                                {['Days', 'Weeks', 'Months'].map((duration, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setLocalStates((prev) => ({
                                                ...prev,
                                                time: duration,
                                            }));
                                            setRiskMitigation((prev) => ({
                                                ...prev,
                                                mitigation_timeline: {
                                                    ...prev.mitigation_timeline,
                                                    time: duration,
                                                },
                                            }));
                                            setFirstReviewDataAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        {duration}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                        {error && localStates?.time === 0 && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Please select an option</span>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1">
                    <p className="font-medium text-sm text-[#64748B]">Mitigation controls</p>

                    <CustomTooltip
                        title="When not choosing from the list, use Two commas(,,) to separate multiple controls."
                        placement="top"
                    >
                        <img src="/img/risk-assessment/help-circle.svg" alt="Help Icon" className="object-contain" />
                    </CustomTooltip>
                </div>
                <MitigationControls
                    error={error}
                    recommendations={recommendations}
                    mitigation_controls={mitigation_controls}
                    setMitigationControls={setMitigationControls}
                    loading={loading}
                />
            </div>

            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1">
                    <p className="font-medium text-sm text-[#64748B]">
                        Mitigation cost ({extractTextInsideBraces(currency)})
                    </p>

                    <CustomTooltip
                        title="Estimate the financial or resource cost of implementing mitigation controls."
                        placement="top"
                    >
                        <img src="/img/risk-assessment/help-circle.svg" alt="Help Icon" className="object-contain" />
                    </CustomTooltip>
                </div>

                <div className="flex">
                    <div className="w-[81px]">
                        <button
                            aria-controls={currencyAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={currencyAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setCurrencyAnchorEl(event.currentTarget);
                            }}
                            className="!border-y !border-l !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-tl-sm !rounded-bl-sm bg-[#F1F5F9] !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    currency ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {currency ? extractTextInsideBraces(currency) : 'Select'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={currencyAnchorEl}
                            open={Boolean(currencyAnchorEl)}
                            onClose={() => setCurrencyAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="flex flex-col min-w-[107px] !p-2">
                                {['NGN (₦)', 'USD ($)', 'EUR (€)', 'GBP (£)', 'KES (Ksh)', 'GHS (₵)'].map(
                                    (currency, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setCurrency(currency);
                                                setCurrencyAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                textTransform: 'none',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {currency}
                                        </MenuItem>
                                    )
                                )}
                            </div>
                        </Menu>
                    </div>

                    <div className="flex-1">
                        <input
                            type="text"
                            inputMode="numeric"
                            placeholder="E.g 500,000.00"
                            value={
                                localStates?.mitigation_cost_amount
                                    ? Number(localStates?.mitigation_cost_amount).toLocaleString()
                                    : ''
                            }
                            onChange={(event) => {
                                const value = event.target.value.replace(/,/gi, '');

                                if (isNaN(value)) return;

                                setLocalStates((prev) => ({
                                    ...prev,
                                    mitigation_cost_amount: value,
                                }));
                            }}
                            onBlur={(event) => {
                                const value = event.target.value.replace(/,/gi, '');

                                if (isNaN(value)) return;

                                setRiskMitigation((prev) => ({
                                    ...prev,
                                    mitigation_cost_amount: value,
                                }));
                            }}
                            className="text-[13px] font-medium placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-tr-sm !rounded-br-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />
                    </div>
                </div>
                {error && localStates?.mitigation_cost_amount === '' && (
                    <span className="text-xs text-[#FF0000] font-normal">Mitigation cost is required</span>
                )}
            </div>

            <div className="flex gap-4">
                <div className="flex-1 flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Residual likelihood</p>

                        <CustomTooltip
                            title="Determine the probability of risk occurrence after applying mitigation controls."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <button
                            aria-controls={residualLikelihoodAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={residualLikelihoodAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setResidualLikelihoodAnchorEl(event.currentTarget);
                            }}
                            // disabled={details}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    residual_likelihood ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {residual_likelihood
                                    ? is_5x5
                                        ? risk_settings?.rows_5x5[
                                              residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_likelihood
                                              )?.value - 1
                                          ]
                                            ? risk_settings?.rows_5x5[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_likelihood
                                                  )?.value - 1
                                              ]
                                            : residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_likelihood
                                              )?.name
                                        : risk_settings?.rows_3x3[
                                              residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_likelihood
                                              )?.value - 1
                                          ]
                                        ? risk_settings?.rows_3x3[
                                              residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_likelihood
                                              )?.value - 1
                                          ]
                                        : residualLikelihoodOrImpact?.find(
                                              (item) => item?.value === residual_likelihood
                                          )?.name
                                    : 'Select residual likelihood'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={residualLikelihoodAnchorEl}
                            open={Boolean(residualLikelihoodAnchorEl)}
                            onClose={() => setResidualLikelihoodAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="!p-2 flex flex-col min-w-[421px]">
                                {residualLikelihoodOrImpact?.map((level, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setResidualLikelihood(level.value);
                                            setResidualLikelihoodAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {is_5x5
                                            ? risk_settings?.rows_5x5[level?.value - 1]
                                                ? risk_settings?.rows_5x5[level?.value - 1]
                                                : level?.name
                                            : risk_settings?.rows_3x3[level?.value - 1]
                                            ? risk_settings?.rows_3x3[level?.value - 1]
                                            : level?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>

                        {error && !residual_likelihood && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">
                                Residual likelihood is required
                            </span>
                        )}
                    </div>
                </div>

                <div className="flex-1 flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Residual impact</p>

                        <CustomTooltip
                            title="Evaluate the potential consequence of the risk after mitigation controls are in place."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <button
                            aria-controls={residualImpactAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={residualImpactAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setResidualImpactAnchorEl(event.currentTarget);
                            }}
                            // disabled={details}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    residual_impact ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {residual_impact
                                    ? is_5x5
                                        ? risk_settings?.columns_5x5[
                                              residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_impact
                                              )?.value - 1
                                          ]
                                            ? risk_settings?.columns_5x5[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_impact
                                                  )?.value - 1
                                              ]
                                            : residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_impact
                                              )?.name
                                        : risk_settings?.columns_3x3[
                                              residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_impact
                                              )?.value - 1
                                          ]
                                        ? risk_settings?.columns_3x3[
                                              residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_impact
                                              )?.value - 1
                                          ]
                                        : residualLikelihoodOrImpact?.find((item) => item?.value === residual_impact)
                                              ?.name
                                    : 'Select residual impact'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={residualImpactAnchorEl}
                            open={Boolean(residualImpactAnchorEl)}
                            onClose={() => setResidualImpactAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="!p-2 flex flex-col min-w-[421px]">
                                {residualLikelihoodOrImpact.map((level, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setResidualImpact(level?.value);
                                            setResidualImpactAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {is_5x5
                                            ? risk_settings?.columns_5x5[level?.value - 1]
                                                ? risk_settings?.columns_5x5[level?.value - 1]
                                                : level?.name
                                            : risk_settings?.columns_3x3[level?.value - 1]
                                            ? risk_settings?.columns_3x3[level?.value - 1]
                                            : level?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                        {error && residual_impact === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Residual impact is required</span>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B]">Comments</p>

                <textarea
                    placeholder="Comment"
                    value={localStates.comments}
                    onChange={(event) => {
                        setLocalStates((prev) => ({
                            ...prev,
                            comments: event.target.value,
                        }));
                    }}
                    onBlur={(event) =>
                        setRiskMitigation((prev) => ({
                            ...prev,
                            comments: event.target.value,
                        }))
                    }
                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !min-h-[76px] !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                />
            </div>
        </>
    );
};
export default MitigationForm;

import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
// import { Close } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import AppInput from 'components/new_components/AppInput';
import LightBulb from '../../../../../assets/img/icons/light-bulb.svg';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { ResendQuestionnaire } from 'store/actions/vendorActions';
import { LoadingButton } from '@mui/lab';
// import { AppFormInput } from 'components/new_components/forms';

const ResendQuestionaireModal = (props) => {
    const { open, handleClose, vendor, ResendQuestionnaire } = props;
    const [resending, setResending] = useState(false);
    const [resendDetails, setResendDetails] = useState('');
    const onClose = () => {
        handleClose();
    };

    useEffect(() => {
        setResendDetails(vendor?.email);
    }, [vendor]);

    const resendQuestionnaire = async () => {
        setResending(true);

        console.log(resendDetails);

        const res = await ResendQuestionnaire({ vendor_id: vendor?.id, recipient_email: resendDetails });
        if (res?.success) {
            toast.success('Questionaire sent successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }

        setResending(false);
    };

    const handleChange = (e) => {
        setResendDetails(e.target.value);
    };

    //translation
    const { t } = useTranslation('vendorManagement');

    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {' '}
                    {t('vendorModal.resendQuestionnaire')}
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: 'fit-content',

                        pt: '0rem',
                        pb: '1rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={resendQuestionnaire}
                        loading={resending}
                    >
                        Resend
                    </LoadingButton>
                </Box>
            }
            width="550px"
            height="fit-content"
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        backgroundColor: '#F8FAFC',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '400',
                        p: 2,
                        display: 'flex',
                        alignItems: 'start',
                    }}
                >
                    <img src={LightBulb} alt="light-bulb" style={{ marginRight: '10px' }} />
                    You can change the vendor’s recipient email if you wish to send questionnaire to a different person.
                </Typography>

                <AppInput
                    label="Recipient Email"
                    placeholder="Email address"
                    onChange={handleChange}
                    value={resendDetails}
                />
            </Box>
        </AppCenteredModalNew>
    );
};

export default connect(null, { ResendQuestionnaire })(ResendQuestionaireModal);

/* eslint-disable no-unused-vars */
import { Dialog } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetRiskSettings } from 'store/actions/riskAssessmentActions';
import https from '../../../../../../../utils/https';

const riskLabelsColorCodes = {
    very_low: '#006C46',
    low: '#55BE8B',
    medium: '#F6EB50',
    high: '#DE3730',
    very_high: '#93000A',
};

export default function CustomRiskLabels({ is_5x5 }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        very_low: {
            default: 'very low',
            custom: '',
            is_editing: false,
        },
        low: {
            default: 'low',
            custom: '',
            is_editing: false,
        },
        medium: {
            default: 'medium',
            custom: '',
            is_editing: false,
        },
        high: {
            default: 'high',
            custom: '',
            is_editing: false,
        },
        very_high: {
            default: 'very high',
            custom: '',
            is_editing: false,
        },
    });
    const [risk_settings] = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings || []);
    const userDetails = useSelector((state) => state?.generalReducers?.user_info);

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const getRiskSettings = async () => {
        setLoading(true);
        const res = await dispatch(GetRiskSettings());
        setLoading(false);
        handleClose();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const updateCurrentUserRiskManagementDataMutation = useMutation({
        mutationFn: updateCurrentUserRiskManagementData,
        onSuccess: () => {
            queryClient.invalidateQueries('userDetails');
            getRiskSettings();
        },
        onError: (error) => {
            toast.error(error?.message);
        },
    });

    const isLoading = updateCurrentUserRiskManagementDataMutation.isLoading || loading;

    function resetFormData() {
        if (risk_settings) {
            const { label_3x3, label_5x5 } = risk_settings;

            if (is_5x5) {
                setFormData({
                    very_low: {
                        default: 'very low',
                        custom: label_5x5?.very_low || '',
                        is_editing: false,
                    },
                    low: {
                        default: 'low',
                        custom: label_5x5?.low || '',
                        is_editing: false,
                    },
                    medium: {
                        default: 'medium',
                        custom: label_5x5?.medium || '',
                        is_editing: false,
                    },
                    high: {
                        default: 'high',
                        custom: label_5x5?.high || '',
                        is_editing: false,
                    },
                    very_high: {
                        default: 'very high',
                        custom: label_5x5?.very_high || '',
                        is_editing: false,
                    },
                });
            } else {
                setFormData({
                    very_low: {
                        default: 'very low',
                        custom: '',
                        is_editing: false,
                    },
                    low: {
                        default: 'low',
                        custom: label_3x3?.low || '',
                        is_editing: false,
                    },
                    medium: {
                        default: 'medium',
                        custom: label_3x3?.medium || '',
                        is_editing: false,
                    },
                    high: {
                        default: 'high',
                        custom: label_3x3?.high || '',
                        is_editing: false,
                    },
                    very_high: {
                        default: 'very high',
                        custom: '',
                        is_editing: false,
                    },
                });
            }
        } else {
            setFormData({
                very_low: {
                    default: 'very low',
                    custom: '',
                    is_editing: false,
                },
                low: {
                    default: 'low',
                    custom: '',
                    is_editing: false,
                },
                medium: {
                    default: 'medium',
                    custom: '',
                    is_editing: false,
                },
                high: {
                    default: 'high',
                    custom: '',
                    is_editing: false,
                },
                very_high: {
                    default: 'very high',
                    custom: '',
                    is_editing: false,
                },
            });
        }
    }

    function handleClose() {
        resetFormData();
        setIsDialogOpen(false);
    }

    function saveChanges() {
        const updatedData = {
            merchant: userDetails?.organization?.id,
            ...(is_5x5
                ? {
                      label_5x5: {
                          very_low: formData.very_low.custom,
                          low: formData.low.custom,
                          medium: formData.medium.custom,
                          high: formData.high.custom,
                          very_high: formData.very_high.custom,
                      },
                  }
                : {
                      label_3x3: {
                          low: formData.low.custom,
                          medium: formData.medium.custom,
                          high: formData.high.custom,
                      },
                  }),
        };

        updateCurrentUserRiskManagementDataMutation.mutate({
            riskDataID: risk_settings?.id,
            data: updatedData,
        });
    }

    useEffect(() => {
        if (risk_settings) {
            const { label_3x3, label_5x5 } = risk_settings;

            if (is_5x5) {
                setFormData((prev) => ({
                    ...prev,
                    very_low: {
                        ...prev.very_low,
                        custom: label_5x5?.very_low || '',
                    },
                    low: {
                        ...prev.low,
                        custom: label_5x5?.low || '',
                    },
                    medium: {
                        ...prev.medium,
                        custom: label_5x5?.medium || '',
                    },
                    high: {
                        ...prev.high,
                        custom: label_5x5?.high || '',
                    },
                    very_high: {
                        ...prev.very_high,
                        custom: label_5x5?.very_high || '',
                    },
                }));
            } else {
                setFormData((prev) => ({
                    ...prev,
                    very_low: {
                        ...prev.very_low,
                        custom: '',
                    },
                    low: {
                        ...prev.low,
                        custom: label_3x3?.low || '',
                    },
                    medium: {
                        ...prev.medium,
                        custom: label_3x3?.medium || '',
                    },
                    high: {
                        ...prev.high,
                        custom: label_3x3?.high || '',
                    },
                    very_high: {
                        ...prev.very_high,
                        custom: '',
                    },
                }));
            }
        }
    }, [risk_settings]);

    return (
        <>
            <button
                className="!bg-[#FFFFFF] text-[#395BA9] !p-2 rounded font-semibold text-[13px] border !border-[#E2E8F0] transition-all hover:brightness-95"
                onClick={() => setIsDialogOpen(true)}
            >
                Customize Risk Labels
            </button>

            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '486px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">
                        Customize {is_5x5 ? '5x5' : '3x3'} Matrix Risk Labels
                    </h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={handleClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-4">
                    <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded px-4 !py-2">
                        <p className="text-[#64748B] font-normal text-[13px] leading-5">
                            Edit labels to suit your organization's needs. Map each label to a severity level.
                        </p>
                    </div>

                    <div className="!border !border-[#E2E8F0] !rounded grid grid-cols-2">
                        <>
                            <div className="bg-[#F8FAFC] !py-2 !px-4">
                                <p className="!p-2 text-[#64748B] font-medium text-sm">Severity Mapping</p>
                            </div>

                            <div className="bg-[#F8FAFC] !py-2 !px-4">
                                <p className="!p-2 text-[#64748B] font-medium text-sm">Label Name</p>
                            </div>
                        </>

                        {Object.keys(formData)
                            .filter((key) => (is_5x5 ? true : key !== 'very_low' && key !== 'very_high'))
                            .map((key) => (
                                <Fragment key={key}>
                                    <div className="!border-t !border-[#E2E8F0] !py-2 !px-4">
                                        <div className="flex items-center !gap-1 !py-1 !px-2">
                                            <div
                                                className="!h-2 !w-2 !rounded-full"
                                                style={{
                                                    backgroundColor: riskLabelsColorCodes[key],
                                                }}
                                            />

                                            <p className="capitalize text-[#64748B] font-medium text-[13px]">
                                                {key.replace('_', ' ')}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="!border-t !border-[#E2E8F0] !py-2 !px-4 flex items-center justify-between !gap-4">
                                        {formData[key].is_editing ? (
                                            <input
                                                type="text"
                                                placeholder="Name your label"
                                                value={formData[key].custom}
                                                onChange={(event) =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        [key]: {
                                                            ...prev[key],
                                                            custom: event.target.value,
                                                        },
                                                    }))
                                                }
                                                className="text-xs font-normal placeholder:text-[#CBD5E1] text-[#64748B] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !h-7 !border !border-[#E2E8F0] focus-visible:ring-1 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                                            />
                                        ) : (
                                            <div className="!py-1 !px-2">
                                                <p className="capitalize text-[#64748B] font-normal text-[13px]">
                                                    {formData[key].custom || formData[key].default}
                                                </p>
                                            </div>
                                        )}

                                        {formData[key].is_editing ? (
                                            <div className="flex items-center !gap-4">
                                                <button
                                                    className="hover:scale-90 active:scale-90 transition flex-shrink-0"
                                                    onClick={() =>
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            [key]: {
                                                                ...prev[key],
                                                                is_editing: false,
                                                            },
                                                        }))
                                                    }
                                                >
                                                    <img
                                                        src="/img/risk-assessment/custom-label-check.svg"
                                                        alt="Edit Icon"
                                                        className="object-contain"
                                                    />
                                                </button>

                                                <button
                                                    className="hover:scale-90 active:scale-90 transition flex-shrink-0"
                                                    onClick={() =>
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            [key]: {
                                                                ...prev[key],
                                                                custom: '',
                                                                is_editing: false,
                                                            },
                                                        }))
                                                    }
                                                >
                                                    <img
                                                        src="/img/risk-assessment/custom-label-close.svg"
                                                        alt="Edit Icon"
                                                        className="object-contain"
                                                    />
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                className="hover:scale-90 active:scale-90 transition"
                                                onClick={() =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        [key]: {
                                                            ...prev[key],
                                                            is_editing: true,
                                                        },
                                                    }))
                                                }
                                            >
                                                <img
                                                    src="/img/risk-assessment/custom-label-edit.svg"
                                                    alt="Edit Icon"
                                                    className="object-contain"
                                                />
                                            </button>
                                        )}
                                    </div>
                                </Fragment>
                            ))}
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center"
                        disabled={isLoading}
                        onClick={saveChanges}
                    >
                        {isLoading && <Loader2 className="mr-2 animate-spin h-4 w-4" />}
                        Save Changes
                    </button>
                </div>
            </Dialog>
        </>
    );
}

async function updateCurrentUserRiskManagementData({ riskDataID, data }) {
    const response = await https().patch(`/scans/risk_mgt_settings/${riskDataID}/`, data);
    return response.data;
}

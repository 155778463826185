import React from 'react';
import { Box, Typography } from '@mui/material';

//redux
import { connect } from 'react-redux';
import { AddVendor, EditVendor } from 'store/actions/vendorActions';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import VendorScoreCard from './VendorScoreCardDetails';

const VendorDetailsModal = (props) => {
    const { open, handleClose, vendor_responses, active, details, vendor } = props;

    return (
        <AppDrawer
            open={open}
            handleClose={handleClose}
            title={
                <Box>
                    <Typography sx={{ color: '#202D66', fontSize: '16px', fontWeight: 600 }}>Score Card</Typography>
                </Box>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            width="fit-content"
            height="100dvh"
            sx={{
                overflow: 'auto',
            }}
        >
            <Box sx={{ p: 3, width: 'fit-content', overflow: 'hidden' }}>
                <VendorScoreCard
                    vendor_responses={vendor_responses}
                    details={details}
                    open={open}
                    vendor={vendor}
                    active={active}
                />
            </Box>
        </AppDrawer>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        all_vendors: state?.vendorReducers?.all_vendors,
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
    };
};

export default connect(mapStateToProps, { AddVendor, EditVendor })(VendorDetailsModal);

/* eslint-disable no-unused-vars */

import { MoreHoriz } from '@mui/icons-material';
import useSearch from 'hooks/useSearch';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllMerchantDocuments } from 'store/actions/complianceActions';
import {
    GetAllCategories,
    GetAllTemplate,
    GetAllThreatCategories,
    GetAllThreats,
    GetAllVulnerabilities,
    GetAssetGroups,
} from 'store/actions/riskAssessmentActions';
import { GetAllVendors } from 'store/actions/vendorActions';
import { by3riskLevel, by5riskLevel } from 'utils';
import DeleteModal from './components/DeleteModal';
import RiskAction from './components/RiskActions';
import RiskTableAction from './components/RiskTableAction';
import RiskTitle from './components/RiskTitle';
import ViewDetailsDrawer from './components/ViewDetailsDrawer';
import RiskTemplateDrawer from './RiskTemplate/RiskTemplateDrawer';
import RiskTemplateTable from './RiskTemplateTable';
import { plans, residual3x3Risks, residual5x5Risks } from './utils/constants';

const RiskTemplatesTab = {
    FiveByFive: '5x5',
    ThreeByThree: '3x3',
};

const templateTypes = ['All', 'custom', 'smartcomply'];

const NO_PER_PAGE = 5;

export default function RiskAssessmentSettingsRiskTemplatesTab() {
    const [currentTab, setCurrentTab] = useState(RiskTemplatesTab.FiveByFive);
    const [templateType, setTemplateType] = useState(templateTypes[0]);
    const [category, setCategory] = useState([]);
    const [plan, setPlan] = useState([...plans]);
    const [residualRisk, setResidualRisk] = useState([...residual3x3Risks]);
    const [mitigationOwnerSearchTerm, setMitigationOwnerSearchTerm] = useState('');
    const [mitigationOwner, setMitigationOwner] = useState('');
    const [templateTypeAnchorEl, setTemplateTypeAnchorEl] = useState(null);
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [planAnchorEl, setPlanAnchorEl] = useState(null);
    const [residualRiskAnchorEl, setResidualRiskAnchorEl] = useState(null);
    const [mitigationOwnerAnchorEl, setMitigationOwnerAnchorEl] = useState(null);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [riskTemplateOpen, setRiskTemplateOpen] = useState(false);
    const [riskTemplateEditOpen, setRiskTemplateEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
    const [allData, setAllData] = useState([]);

    // redux
    const dispatch = useDispatch();

    const { risk_assessment_templates, risk_assessment_categories } = useSelector(
        (state) => state.riskAssessmentReducers
    );
    const [risk_settings] = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings || []);

    const { data, handleSearch } = useSearch(risk_assessment_templates, [
        'template_name',
        'mitigation_plan',
        'residual_risk',
        'solution_owner',
    ]);

    const handleDeleteOpen = (payload) => {
        setDetails(payload);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDetails(null);
        setDeleteOpen(false);
    };

    const openViewDetails = (details) => {
        setDetails(details);
        setViewDetailsOpen(true);
    };

    const closeViewDetails = () => {
        setDetails(null);
        setViewDetailsOpen(false);
    };

    useEffect(() => {
        if (risk_assessment_categories?.length) {
            setCategory(risk_assessment_categories);
        }
    }, [risk_assessment_categories]);

    const columns = [
        {
            title: 'Template name',
            key: 'name',
            render: (row) => row?.template_name,
        },
        {
            title: 'Category',
            key: 'category',
            render: (row) => (
                <div className="w-fit grid place-items-center bg-[#F1F5F9] h-7 rounded !py-1 !px-2 text-[#475569] font-medium text-[13px]">
                    {row?.category_detail}
                </div>
            ),
        },
        {
            title: <p className="whitespace-nowrap">Residual risk</p>,
            key: 'residualRisk',
            render: (row) => {
                const residual_risk = row?.residual_likelihood * row?.residual_impact;

                return (
                    <div className="w-fit flex items-center gap-1 h-7 rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                        {residual_risk ? (
                            <>
                                <div
                                    className={`w-2 h-2 rounded-full`}
                                    style={{
                                        backgroundColor:
                                            row?.matrix === '3x3'
                                                ? by3riskLevel(residual_risk).color
                                                : by5riskLevel(residual_risk).color,
                                    }}
                                />

                                <p className="text-[#64748B] font-medium text-[13px] capitalize">
                                    {row?.matrix === '3x3'
                                        ? `${
                                              risk_settings?.label_3x3[
                                                  by3riskLevel(residual_risk).type?.toLowerCase().replace(' ', '_')
                                              ]
                                                  ? risk_settings?.label_3x3[
                                                        by3riskLevel(residual_risk)
                                                            .type?.toLowerCase()
                                                            .replace(' ', '_')
                                                    ]
                                                  : by3riskLevel(residual_risk).type
                                          }`
                                        : `${
                                              risk_settings?.label_5x5[
                                                  by5riskLevel(residual_risk).type?.toLowerCase().replace(' ', '_')
                                              ]
                                                  ? risk_settings?.label_5x5[
                                                        by5riskLevel(residual_risk)
                                                            .type?.toLowerCase()
                                                            .replace(' ', '_')
                                                    ]
                                                  : by5riskLevel(residual_risk).type
                                          }`}
                                </p>
                            </>
                        ) : (
                            '--'
                        )}
                    </div>
                );
            },
        },
        {
            title: <p className="whitespace-nowrap">Mitigation plan</p>,
            key: 'mitigation_plan',
            render: (row) => (
                <div className="flex flex-col gap-1">
                    <p className="text-[#395BA9] font-medium text-sm captialize">{row?.mitigation_plan}</p>
                </div>
            ),
        },
        {
            title: <MoreHoriz />,
            key: 'action',
            render: (row) => (
                <RiskTableAction
                    row={row}
                    handleDeleteOpen={handleDeleteOpen}
                    openViewDetails={openViewDetails}
                    handleEditOpen={() => {
                        setDetails(row);
                        setRiskTemplateEditOpen(true);
                    }}
                />
            ),
        },
    ];

    // Async functions
    const getAllRiskAssessmentThreats = async () => {
        const res = await dispatch(GetAllThreats());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllVendors = async () => {
        const res = await dispatch(GetAllVendors());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllThreatCategories = async () => {
        const res = await dispatch(GetAllThreatCategories());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllMerchantDocuments = async () => {
        const res = await dispatch(GetAllMerchantDocuments());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAssetGroups = async () => {
        const res = await dispatch(GetAssetGroups());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllRiskAssessmentTCategories = async () => {
        const res = await dispatch(GetAllCategories());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllRiskAssessmentVulnerablilties = async () => {
        const res = await dispatch(GetAllVulnerabilities());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // UseEffect

    const getAllRiskTemplate = async () => {
        const res = await dispatch(GetAllTemplate());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        getAllRiskAssessmentThreats();
        getAllRiskAssessmentVulnerablilties();
        getAllRiskAssessmentTCategories();
        getAssetGroups();
        getAllThreatCategories();
        getAllMerchantDocuments();
        getAllVendors();
        getAllRiskTemplate();
    }, []);

    useEffect(() => {
        if (currentTab === RiskTemplatesTab.ThreeByThree) {
            setResidualRisk([...residual3x3Risks]);
        } else {
            setResidualRisk([...residual5x5Risks]);
        }
    }, [currentTab]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let filteredData = [...(data || [])];

        if (filteredData?.length > 0) {
            const category_ids = category?.map((category) => category?.id);
            filteredData = filteredData?.filter((item) => category_ids?.includes(item?.category));
        }
        // if (filteredData?.length > 0) {
        //     filteredData = filteredData?.filter((item) => residualRisk?.includes(item?.inherent_risk_level_slug));
        // }

        if (filteredData?.length > 0) {
            filteredData = filteredData?.filter((item) => plan?.includes(item?.mitigation_plan));
        }
        if (filteredData?.length > 0 && templateType !== 'All') {
            filteredData = filteredData.filter((item) => item?.tag === templateType);
        }

        if (currentTab && filteredData?.length) {
            filteredData = filteredData?.filter((item) => item?.matrix === currentTab);
        }

        const new_data = filteredData?.length ? filteredData?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : [];
        setAllData(filteredData);
        setPageData(new_data);
    }, [page, data, plan, category, templateType, mitigationOwner, currentTab]);
    return (
        <>
            <div className="bg-white rounded max-w-[1260px] w-full mx-auto flex flex-col gap-4 !py-6 !px-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <button
                            className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-[42px] h-8 grid place-items-center ${
                                currentTab === RiskTemplatesTab.FiveByFive
                                    ? 'bg-[#395BA9] text-[#fff]'
                                    : 'text-[#475569] bg-[#fff]'
                            }`}
                            onClick={() => setCurrentTab(RiskTemplatesTab.FiveByFive)}
                        >
                            5x5
                        </button>

                        <button
                            className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-[42px] h-8 grid place-items-center ${
                                currentTab === RiskTemplatesTab.ThreeByThree
                                    ? 'bg-[#395BA9] text-[#fff]'
                                    : 'text-[#475569] bg-[#fff]'
                            }`}
                            onClick={() => setCurrentTab(RiskTemplatesTab.ThreeByThree)}
                        >
                            3x3
                        </button>
                    </div>

                    <div
                        onClick={() => setRiskTemplateOpen(true)}
                        className="bg-[#202D66] rounded-sm flex items-center gap-1 !py-2.5 !px-3 transition-all hover:brightness-90 hover:no-underline cursor-pointer"
                    >
                        <img src="/img/risk-assessment/add.svg" alt="Add Icon" className="object-contain" />

                        <p className="font-semibold text-sm text-white">New template</p>
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <RiskTitle onSearch={handleSearch} />
                    <RiskAction
                        templateType={templateType}
                        setTemplateType={setTemplateType}
                        templateTypeAnchorEl={templateTypeAnchorEl}
                        setTemplateTypeAnchorEl={setTemplateTypeAnchorEl}
                        mitigationOwner={mitigationOwner}
                        setMitigationOwner={setMitigationOwner}
                        mitigationOwnerAnchorEl={mitigationOwnerAnchorEl}
                        setMitigationOwnerAnchorEl={setMitigationOwnerAnchorEl}
                        categoryAnchorEl={categoryAnchorEl}
                        setCategoryAnchorEl={setCategoryAnchorEl}
                        category={category}
                        setCategory={setCategory}
                        setPlanAnchorEl={setPlanAnchorEl}
                        planAnchorEl={planAnchorEl}
                        plan={plan}
                        setPlan={setPlan}
                        currentTab={currentTab}
                        setResidualRiskAnchorEl={setResidualRiskAnchorEl}
                        residualRiskAnchorEl={residualRiskAnchorEl}
                        setResidualRisk={setResidualRisk}
                        residualRisk={residualRisk}
                        setMitigationOwnerSearchTerm={setMitigationOwnerSearchTerm}
                        mitigationOwnerSearchTerm={mitigationOwnerSearchTerm}
                    />
                </div>

                <div>
                    <RiskTemplateTable
                        columns={columns}
                        data={pageData}
                        dataLength={allData?.length}
                        noPerPage={NO_PER_PAGE}
                        page={page}
                        onPageChange={(page) => setPage(page)}
                        search={false}
                        showTitleBar={false}
                    />
                </div>
            </div>

            <RiskTemplateDrawer
                open={riskTemplateOpen}
                handleClose={() => {
                    setDetails(null);
                    setRiskTemplateOpen(false);
                }}
            />
            <RiskTemplateDrawer
                open={riskTemplateEditOpen}
                handleClose={() => {
                    setDetails(null);
                    setRiskTemplateEditOpen(false);
                }}
                details={details}
            />
            <ViewDetailsDrawer open={viewDetailsOpen} handleClose={closeViewDetails} details={details} />
            <DeleteModal open={deleteOpen} handleClose={handleDeleteClose} id={details?.id} />
        </>
    );
}

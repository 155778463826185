import { Stack, Typography } from '@mui/material';
import React from 'react';
import Light from 'assets/img/Light.svg';

export default function OnboardInfo({ info }) {
    return (
        <div className="my-3">
            {' '}
            <Stack
                flexDirection="row"
                sx={{
                    border: `1px solid #F0F0FA`,
                    backgroundColor: '#F8FAFC',
                    borderRadius: '4px',
                    p: '12px',
                    pt: '8px',
                }}
            >
                <img src={Light} alt="file question" className="h-fit w-fit" />
                <Typography
                    sx={{
                        color: '#64748B',
                        fontWeight: 400,
                        fontSize: '13px',
                        ml: 1,
                    }}
                >
                    {info}
                </Typography>
            </Stack>
        </div>
    );
}

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//core component
import { Badge, Box, Button } from '@mui/material';
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import AllTasks from './Components/Modals/AllTasks';
import Exceptions from './Exceptions';
import Scans from './Scans';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import TabLayoutModified from 'components/new_components/tabs/TabLayoutModified';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
// import { steps } from './steps';

import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import SmartIntel from 'components/new_components/SmartIntel';
import Carousel from 'components/new_components/carousel/Carousel';
import { useSmartIntelContext } from 'store/context/SmartIntelContextProvider';

const AutomatedScans = (props) => {
    const location = useLocation();
    const { tab_ } = location?.state ?? {};
    const { userguide_info, user_info, all_article_header } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [view, changeView] = useState(tab_ !== undefined ? tab_ : 'groups');
    const [category, setCategory] = useState(tab_ !== undefined ? tab_ : {});
    const [viewTasks, setViewTasks] = useState(false);

    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    //userguide
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Scans" id={1} length={STEPS_LENGTH} />,
            description: 'This displays the constraints of automated scans carried out on your infrastructure.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Exceptions" id={2} length={STEPS_LENGTH} />,
            description: 'Scan that are not related to your infrastructures(exempted scans) are shown here.',
            target: () => ref2.current,
            placement: 'rightTop',
        },
    ];
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Automated Scans'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ automated_scans: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, automatedscans: false });
        setOpenArticle(false);
    };

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        changeView('groups');
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.automatedscans ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.automatedscans]);

    // constants
    const TABS = [
        { name: 'Scans', id: 0 },
        { name: 'Exemptions', id: 1 },
    ];
    const openViewTasks = () => {
        setViewTasks(true);
    };

    // smart intel

    const keywords = merchant_info?.intel_keywords?.['Servers'];
    const { handleOpen } = useSmartIntelContext();
    const switch_on = merchant_info?.smart_intel;
    const intel =
        switch_on && all_smart_intel?.['Servers']
            ? Object?.values(all_smart_intel?.['Servers'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    return (
        <SmartIntel>
            <Box
                sx={{
                    // margin: view === 'scans' ? '-1.6rem' : '',
                    position: 'relative',
                }}
            >
                <PageHeader browserTitle="Automated Scans | Smartcomply" />
                {view === 'groups' && (
                    <Box sx={{ position: 'relative' }}>
                        <TabLayoutModified
                            onTabChange={handleTabChange}
                            currentTab={currentTab}
                            tabs={TABS}
                            ref1={ref1}
                            ref2={ref2}
                            view={view}
                            tabAction={
                                user_info?.user_type !== 'auditor' && user_info?.user_type !== 'merchant' ? (
                                    <Badge variant="dot" invisible={true} color="error">
                                        <Button
                                            sx={{
                                                border: 'none',
                                                backgroundColor: '#E1E2EC',
                                                color: '#002C72',
                                                textTransform: 'inherit',
                                                fontSize: '13px',
                                                '&:hover': {
                                                    backgroundColor: '#E1E2EC',
                                                },
                                            }}
                                            onClick={openViewTasks}
                                        >
                                            Tasks for you
                                        </Button>
                                    </Badge>
                                ) : null
                            }
                        >
                            <AppTabPanel value={currentTab} index={0}>
                                {switch_on && intel?.length ? (
                                    <div className="mt-6 mb-8 mx-4 transition ease-in-out duration-500">
                                        <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center">
                                            <div className="flex items-start !h-[24px] gap-2 w-[85%]">
                                                <img
                                                    src={SmartIntelLogo}
                                                    alt="SmartIntelLogo"
                                                    className="xl:block md:hidden"
                                                />
                                                <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                                    {intel?.length}
                                                </p>
                                                <Carousel
                                                    slides={intel}
                                                    handleOpen={handleOpen}
                                                    type="Servers"
                                                    data={all_smart_intel?.['Servers']}
                                                    containerStyles="page-slide !h-[24px]"
                                                />
                                            </div>
                                            <img src={ArrowRight} alt="ArrowRight" />
                                        </div>
                                    </div>
                                ) : null}
                                <Scans
                                    view={view}
                                    changeView={changeView}
                                    category={category}
                                    setCategory={setCategory}
                                    type="scans"
                                />
                            </AppTabPanel>
                            <AppTabPanel value={currentTab} index={1}>
                                {switch_on && intel?.length ? (
                                    <div className="mt-6 mb-8 mx-4 transition ease-in-out duration-500">
                                        <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center">
                                            <div className="flex items-start !h-[24px] gap-2 w-[85%]">
                                                <img
                                                    src={SmartIntelLogo}
                                                    alt="SmartIntelLogo"
                                                    className="xl:block md:hidden"
                                                />
                                                <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                                    {intel?.length}
                                                </p>
                                                <Carousel
                                                    slides={intel}
                                                    handleOpen={handleOpen}
                                                    type="Servers"
                                                    data={all_smart_intel?.['Servers']}
                                                    containerStyles="page-slide !h-[24px]"
                                                />
                                            </div>
                                            <img src={ArrowRight} alt="ArrowRight" />
                                        </div>
                                    </div>
                                ) : null}
                                <Exceptions
                                    view={view}
                                    changeView={changeView}
                                    type="exceptions"
                                    category={category}
                                    setCategory={setCategory}
                                />
                            </AppTabPanel>
                        </TabLayoutModified>
                    </Box>
                )}

                {view === 'scans' && (
                    <Scans view={view} changeView={changeView} category={category} setCategory={setCategory} />
                )}

                {view === 'exceptions' && (
                    <Exceptions view={view} changeView={changeView} category={category} setCategory={setCategory} />
                )}

                <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={[ref1, ref2]} />
                <AppArticle
                    open={openArticle}
                    handleClose={CloseArticle}
                    title="Automated Scans"
                    headerId={oneArticle?.id}
                    icon={true}
                />
                <AllTasks open={viewTasks} onClose={() => setViewTasks(false)} />
                <AppArticle
                    open={openArticle}
                    handleClose={CloseArticle}
                    title="Automated Scans"
                    headerId={oneArticle?.id}
                    icon={true}
                />
            </Box>
        </SmartIntel>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.automated_scans,
        user_info: state?.generalReducers?.user_info,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps)(AutomatedScans);

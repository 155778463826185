import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// core components
import ItemSubheader from '../ItemSubheader';
import FieldItem from './FieldItem';
import AnswerHistoryDrawer from './AnswerHistoryDrawer';

// redux
import { connect, useSelector } from 'react-redux';
import { ExemptQuestion } from 'store/actions/complianceActions';
// import ComplianceAISuggestions from '../ComplianceAISuggestions';

//translations
import { useTranslation } from 'react-i18next';

const QuestionItem = (props) => {
    const {
        form,
        standard,
        merchant_answers,
        ExemptQuestion,
        onResourceChange,
        // title,
        aiAnswers,
        isAIOn,
        resource,
        openTaskAssignModal,
        taskInfo,
        assignedForms,
        setFormPayload,
        formPayload,
        error,
    } = props;
    const theme = useTheme();

    // states
    const [loading, setLoading] = useState({ exempt: false, answer: false });
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);
    // const [suggestionModalOpen, setSuggestionModalOpen] = useState(false);
    // const [currentField, setCurrentField] = useState(null);

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    // memos
    // get the current question in view
    const currentQuestion = useMemo(() => {
        return merchant_answers?.find((answer) => answer?.form_id === form?.id);
    }, [form, merchant_answers]);
    //sort form field by names so "if yes" questions can always come first
    // const sortedFormFields = useMemo(() => {
    //     return form?.form_fields?.sort((a, b) => {
    //         if (a?.name?.toLowerCase()?.includes('if yes') && !b?.name?.toLowerCase()?.includes('if yes')) {
    //             return -1;
    //         } else if (!a?.name?.toLowerCase()?.includes('if yes') && b?.name?.toLowerCase()?.includes('if yes')) {
    //             return 1;
    //         } else {
    //             return a?.order - b?.order;
    //         }
    //     });
    // }, [form?.form_fields]);
    // controlling the drawer for form versions
    const openDrawer = () => setDrawerOpened(true);
    const closeDrawer = () => setDrawerOpened(false);

    // const openSuggestionModal = (field) => {
    //     setSuggestionModalOpen(true);
    //     setCurrentField(field);
    // };
    // const closeSuggestionModal = () => setSuggestionModalOpen(false);
    // async functions
    // exempt a question
    const exemptQuestion = async () => {
        const payload = {
            form: form?.id,
            standard,
        };
        setLoading({ ...loading, exempt: true });
        const res = await ExemptQuestion(payload);
        setLoading({ ...loading, exempt: false });
        if (res?.success) {
            toast.success(res?.message);
            onResourceChange();
        } else {
            toast.error(res?.message);
        }
    };

    const handleInputFocus = (id) => {
        setFocusedInput(id);
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                resource="form"
                exempting={loading.exempt}
                onExempt={exemptQuestion}
                menuItems={
                    !assignedForms?.includes(form?.id) && user_type !== 'auditor'
                        ? [
                              {
                                  label: 'Assign to',
                                  action: () =>
                                      openTaskAssignModal(resource, {
                                          standard: standard === 'iso22301' ? 'is022301' : standard,
                                          form: form?.id,
                                          ...taskInfo,
                                      }),
                                  active: Boolean(currentQuestion),
                              },
                              { label: t('viewAnswer'), action: openDrawer },
                          ]
                        : [{ label: t('viewAnswer'), action: openDrawer }]
                }
                assigned={assignedForms?.includes(form?.id)}
                hasResource={Boolean(currentQuestion)}
                standard={standard}
            />
            <Typography sx={{ color: theme.palette.black.main, fontWeight: 700, mb: -2, mt: 2 }}>
                {!language || language?.includes('en')
                    ? form?.card4_title ||
                      form?.lifecycle_title ||
                      form?.quality_mgt_title ||
                      form?.title ||
                      form?.ndpr_title ||
                      form?.gdpa_title ||
                      form?.continuity_iso_title ||
                      form?.iso_title ||
                      form?.soc2_title ||
                      form?.kdpa_title ||
                      form?.criteria_title ||
                      form?.cii_title ||
                      form?.iso_two_title
                    : form?.card4_title_fr ||
                      form?.lifecycle_title_fr ||
                      form?.quality_mgt_title_fr ||
                      form?.title_fr ||
                      form?.ndpr_title_fr ||
                      form?.gdpa_title_fr ||
                      form?.continuity_iso_title_fr ||
                      form?.iso_title_fr ||
                      form?.soc2_title_fr ||
                      form?.kdpa_title_fr ||
                      form?.criteria_title_fr ||
                      form?.cii_title_fr ||
                      form?.iso_two_title_fr}
            </Typography>
            <Box sx={{ mb: 5 }}>
                {form?.form_fields
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((field, index) => {
                        return (
                            <FieldItem
                                field={field}
                                number={index + 1}
                                key={field.id}
                                answers={aiAnswers?.[field?.id]}
                                focusedInput={focusedInput}
                                handleInputFocus={handleInputFocus}
                                // openSuggestionModal={() => openSuggestionModal(field)}
                                isAIOn={isAIOn}
                                standard={standard}
                                formPayload={formPayload}
                                setFormPayload={setFormPayload}
                                error={error}
                            />
                        );
                    })}
            </Box>
            <AnswerHistoryDrawer
                open={drawerOpened}
                handleClose={closeDrawer}
                form={currentQuestion}
                standard={standard}
            />
            {/* <ComplianceAISuggestions
                open={suggestionModalOpen}
                handleClose={closeSuggestionModal}
                answers={aiAnswers?.[currentField?.id]}
                fieldId={currentField?.id}
                question={currentField?.name}
            /> */}
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_answers: state?.complianceReducers?.merchant_answers,
        isAIOn: state?.merchantReducers?.merchant_info?.compliance_ai,
    };
};
export default connect(mapStateToProps, { ExemptQuestion })(QuestionItem);

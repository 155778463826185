import React, { useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import AppInput from 'components/new_components/AppInput';
import LightBulb from '../../../../../assets/img/icons/light-bulb.svg';
import { LoadingButton } from '@mui/lab';

const PassportModal = (props) => {
    const { open, handleClose, handleBackgroundCheck, loading } = props;
    const [passportNumber, setPassportNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [touched, setTouched] = useState({ passport_number: false, date_of_birth: false });
    const [errors, setErrors] = useState({});

    const { t } = useTranslation('onboardingPolicy');

    const validate = ({ passportNumber, dateOfBirth }) => {
        const newErrors = {};

        // Validate passport number
        if (!passportNumber) {
            newErrors.passport_number = 'Passport number is required';
        } else if (!/^[A-Za-z0-9]{6,12}$/.test(passportNumber)) {
            newErrors.passport_number = 'Passport number must be 6-12 alphanumeric characters';
        }

        // Validate Date of Birth
        if (!dateOfBirth) {
            newErrors.date_of_birth = 'Date of birth is required';
        } else {
            // Split "DD MM YYYY" into components
            const [day, month, year] = dateOfBirth.split(' ');

            // Convert to a proper Date object (months are 0-based in JS)
            const inputDate = new Date(`${year}-${month}-${day}`);
            const today = new Date();

            // Reset today’s time to midnight to avoid false positives
            today.setHours(0, 0, 0, 0);

            if (isNaN(inputDate.getTime())) {
                newErrors.date_of_birth = 'Invalid date format';
            } else if (inputDate >= today) {
                newErrors.date_of_birth = 'Date of birth cannot be today or in the future';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'passport_number') {
            setPassportNumber(value);
            setTouched((prev) => ({ ...prev, passport_number: true }));
        } else if (name === 'date_of_birth') {
            setDateOfBirth(value);
            setTouched((prev) => ({ ...prev, date_of_birth: true }));
        }
        setTimeout(() => {
            validate({
                passportNumber: name === 'passport_number' ? value : passportNumber,
                dateOfBirth: name === 'date_of_birth' ? value : dateOfBirth,
            });
        }, 0);
    };

    const onClose = () => {
        handleClose();
    };

    const handleSubmit = async () => {
        if (validate({ passportNumber, dateOfBirth })) {
            await handleBackgroundCheck({
                passport_number: passportNumber,
                date_of_birth: dateOfBirth,
                background_checktype: 'passport',
            });
        }
    };

    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    Passport Verification
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 15, height: 15 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: 'fit-content',
                        pt: '0rem',
                        pb: '1rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        {t('backgroundCheck.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        {t('backgroundCheck.verify')}
                    </LoadingButton>
                </Box>
            }
            width="511px"
            height="fit-content"
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        backgroundColor: '#F8FAFC',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '400',
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'start',
                        border: '1px solid #F0F0FA',
                        borderRadius: '4px',
                    }}
                >
                    <img src={LightBulb} alt="light-bulb" style={{ marginRight: '10px' }} />
                    <p
                        style={{
                            fontSize: '13px',
                            fontWeight: 400,
                        }}
                    >
                        {'Only enter the Passport number on your Passport card.'}
                    </p>
                </Typography>

                <AppInput
                    label="Passport Number"
                    placeholder="Enter Passport Number"
                    onChange={handleChange}
                    name="passport_number"
                    id="passport_number"
                    error={touched.passport_number && errors.passport_number}
                />
                {touched.passport_number && errors.passport_number && (
                    <FormHelperText error>{errors.passport_number}</FormHelperText>
                )}

                <AppInput
                    label="Date of Birth"
                    placeholder="Enter Date of Birth"
                    onChange={handleChange}
                    name="date_of_birth"
                    id="date_of_birth"
                    type="date"
                    sx={{
                        '& input::placeholder': {
                            color: touched.date_of_birth ? '#000' : '#b4b4b5',
                            opacity: 1,
                        },
                        '& input::-webkit-datetime-edit': {
                            color: touched.date_of_birth ? '#000' : '#b4b4b5',
                        },
                        '& input::-webkit-calendar-picker-indicator': {
                            filter: 'invert(50%)',
                        },
                    }}
                    error={touched.date_of_birth && errors.date_of_birth}
                />
                {touched.date_of_birth && errors.date_of_birth && (
                    <FormHelperText error>{errors.date_of_birth}</FormHelperText>
                )}
            </Box>
        </AppCenteredModalNew>
    );
};

export default PassportModal;

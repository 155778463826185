import React, { useState, useEffect, useMemo } from 'react';
import IntroductionModal from './IntroductionModal';
import { Box } from '@mui/material';
import { MoreHoriz, AddCircleOutline } from '@mui/icons-material';
import AppTable from 'components/new_components/app-table';
// import { ALL_DATA } from './util';
import useSearch from 'hooks/useSearch';
import AppButton from 'components/new_components/AppButton';
import StatusTag from './StatusTag';
import SeverityTag from './SeverityTag';
import { formatDateObjectHandler } from 'utils';
import ComplianceTableActions from './ComplianceTableActions';
import RunScanModal from './RunScanModal';
import ScanDeleteModal from './ScanDeleteModal';
import ScanReportModal from './ScanReportModal';

//redux
import { connect } from 'react-redux';
import { GetAllAPIScans, RequestAPIScans } from 'store/actions/apiComplianceActions';
import { GetModuleGuides, CreateModuleGuide, UpdateModuleGuide } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import PageHeader from 'components/new_components/PageHeader';
import { getType } from 'utils';
import { ReactComponent as BulbIcon } from 'assets/img/bulb3.svg';

const NO_PER_PAGE = 8;
const APICompliance = (props) => {
    const {
        GetAllAPIScans,
        RequestAPIScans,
        api_scans,
        GetModuleGuides,
        CreateModuleGuide,
        UpdateModuleGuide,
        guideModal,
    } = props;

    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [runModalOpen, setRunModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [loading, setLoading] = useState({ fetching: false, rerunning: false });
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const formattedTableData = useMemo(() => {
        if (api_scans?.length) {
            return api_scans?.map((scan) => {
                return {
                    id: scan?.id,
                    link: scan?.link,
                    frequency: scan?.frequency,
                    status: scan?.status,
                    severity: scan?.severity ? 'needs attention' : 'Looks okay',
                    date_created: scan?.date_created,
                    date_updated: scan?.date_updated,
                    merchant: scan?.merchant,
                };
            });
        } else {
            return [];
        }
    }, [api_scans]);
    // hooks
    const { data, handleSearch } = useSearch(formattedTableData, ['link', 'frequency', 'status', 'severity']);
    const role = getType('role');

    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };
    //modals controls
    const openRunScanModal = () => {
        setRunModalOpen(true);
    };
    const closeRunScanModal = () => {
        setRunModalOpen(false);
    };
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };
    const openDeleteModal = (data) => {
        setModalPayload(data);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayload({});
    };
    const openReportModal = (data) => {
        setReportModalOpen(true);
        setModalPayload(data);
    };
    const closeReportModal = () => {
        setReportModalOpen(false);
        setTimeout(() => {
            setModalPayload({});
        }, [500]);
    };

    const reRunScanAction = async (payload, closeMenu) => {
        setLoading({ ...loading, rerunning: true });
        const res = await RequestAPIScans(payload);
        setLoading({ ...loading, rerunning: false });
        if (res?.success) {
            toast.success('Scan Rerun Initiated Successfully');
            closeMenu();
        } else {
            toast.error(res?.message);
        }
    };

    const columns = [
        {
            title: 'Endpoint',
            key: 'link',
            render: (row) => <>{row?.link}</>,
        },
        {
            title: 'Date scanned',
            key: 'server',
            render: (row) => <>{formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY')}</>,
        },
        {
            title: 'Frequency',
            key: 'frequency',
            render: (row) => <>{row?.frequency}</>,
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <StatusTag text={row?.status} type={row?.status === 'completed' ? 'success' : 'default'} />
            ),
        },
        {
            title: 'Severity',
            key: 'severity',
            render: (row) =>
                row?.status === 'running' || row?.status === 're-running' ? (
                    <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                ) : (
                    <>
                        <SeverityTag
                            text={row?.severity}
                            type={row?.severity === 'Looks okay' ? 'success' : 'error'}
                            margin="0"
                            fontWeight={500}
                        />
                    </>
                ),
        },
        {
            title: <MoreHoriz />,
            align: 'center',
            key: 'action',
            render: (row) => (
                <ComplianceTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    openReportModal={openReportModal}
                    reRunScanAction={reRunScanAction}
                    rerunning={loading?.rerunning}
                    role={role}
                />
            ),
        },
    ];

    //async functions
    const getAllAPIScans = async () => {
        setLoading({ ...loading, fetching: true });
        const res = GetAllAPIScans();
        setLoading({ ...loading, fetching: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getModuleGuides = async () => {
        const res = GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ apicompliance: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ apicompliance: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        getAllAPIScans();
        getModuleGuides();
    }, []);

    useEffect(() => {
        if (!guideModal?.[0]?.apicompliance) {
            setIntroModalOpen(true);
        }
    }, [guideModal]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);
        return data;
    }, [page, data]);

    useEffect(() => {
        // Check if guideModal is empty or its first element has api_compliance set to false
        const shouldOpenModal =
            guideModal && (guideModal.length === 0 || (guideModal[0] && !guideModal[0]?.apicompliance));

        // Only update the state if needed to avoid unnecessary renders
        if (shouldOpenModal !== introModalOpen) {
            setIntroModalOpen(shouldOpenModal);
        }
    }, [guideModal, introModalOpen]);
    return (
        <>
            <PageHeader browserTitle="API Compliance | Smartcomply" />
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading.fetching}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <AppButton
                        icon={<AddCircleOutline />}
                        name="New Scan"
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openRunScanModal}
                        disabled={role === 'auditor'}
                        key={1}
                    />,
                ]}
                search={true}
                onSearch={handleSearch}
                topContent={
                    <div
                        className={`mt-4 mb-2 flex items-start justify-start gap-4 rounded border !border-[#FDE68A] bg-[#FFFBEB] px-3 py-2`}
                    >
                        <BulbIcon className="flex-shrink-0 w-7 h-7" />
                        <p className={`w-full p-0 text-sm font-normal text-[#755B00]`}>
                            API scans can only be performed on{' '}
                            <span className="font-bold">publicly reachable API endpoints</span>. On-premises APIs, due
                            to their private network location, cannot be scanned.
                        </p>
                    </div>
                }
                sx={{
                    container: {
                        p: 3,
                    },
                    tableTopbar: {
                        pl: '0 !important',
                        pr: '8px !important',
                    },
                }}
            />
            <IntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />
            <RunScanModal open={runModalOpen} handleClose={closeRunScanModal} />
            <ScanDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} payload={modalPayload} />
            <ScanReportModal open={reportModalOpen} handleClose={closeReportModal} payload={modalPayload} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        api_scans: state?.apiComplianceReducers?.api_scans,
        guideModal: state?.generalReducers?.module,
    };
};
export default connect(mapStateToProps, {
    GetAllAPIScans,
    GetModuleGuides,
    RequestAPIScans,
    CreateModuleGuide,
    UpdateModuleGuide,
})(APICompliance);

import React, { useMemo, useState, useEffect } from 'react';

// mui components
// import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// import { ArrowBack } from '@mui/icons-material';

// core component
import VendorInformation from './vendorViewSections/VendorInformation';
import QuestionnaireFormsView from './vendorViewSections/QuestionnaireFormsView';

// redux
import { connect, useDispatch } from 'react-redux';
import { GetOneVendor } from 'store/actions/vendorActions';
// import ExitModal from './vendorViewSections/ExitModal';
import { toast } from 'react-toastify';
// import AppSideBar from 'components/new_components/AppSideBar';

import logo from 'assets/img/vendor_smart_logo.svg';
import Success from './vendorViewSections/Success';
import { UpdateVendorDetails } from 'store/actions/vendorActions';
import { GetQuestionnaireHistory } from 'store/actions/vendorActions';
import useSearchParams from 'hooks/useSearchParams';
import CircularLoader from 'components/Loaders/CircularLoader';
import { Row } from 'antd';

const VendorsView = (props) => {
    // const theme = useTheme();
    // const midColour = '#F8F9FE';
    // props
    const { location, match, GetOneVendor, one_vendor, questionnaireData, GetQuestionnaireHistory } = props;
    const readonly = useMemo(() => location?.state?.readonly, [props]);
    const merchant = useMemo(() => match.params.merchant, [props]);
    const vendor = useMemo(() => match.params.vendor, [props]);
    const query = useSearchParams();
    const questionnaireValue = query.getParam('questionnaire_detail') || '';

    // state
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    // const [exitModal, setExitModal] = useState(false);

    // functions
    const getOneVendor = async () => {
        setLoading(true);
        const res = await GetOneVendor(vendor);
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // const openExitModal = () => setExitModal(true);
    // const closeExitModal = () => setExitModal(false);
    const dispatch = useDispatch();
    const runToMutate = async () => {
        await dispatch(UpdateVendorDetails({ has_responded: false }, vendor, false));
    };

    useEffect(() => {
        if (readonly || one_vendor?.has_confirmed_details) {
            setPage(1);
        }
    }, [readonly]);

    // Fetch questionnaire history
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await GetQuestionnaireHistory(vendor, { vendor_questionnaire: questionnaireValue });
                await getOneVendor();

                if (!res.success) {
                    throw new Error(res.message || 'Failed to fetch questionnaire history');
                }
            } catch (error) {
                toast.error(error.message || 'An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        if (vendor) {
            fetchData();
        }
    }, [vendor, GetQuestionnaireHistory]);

    useEffect(() => {
        runToMutate();
    }, []);
    const questionnaire = useMemo(
        () => questionnaireData?.find((q) => q.id == questionnaireValue),
        [questionnaireData]
    );
    return (
        <>
            {loading ? (
                <Row
                    justify="center"
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <CircularLoader size="large" />
                </Row>
            ) : questionnaire?.has_responded ? (
                <Success />
            ) : (
                <Box>
                    {page === 0 ? (
                        <VendorInformation
                            vendor={vendor}
                            setPage={setPage}
                            one_vendor={one_vendor}
                            contentLoading={loading.content}
                        />
                    ) : (
                        <Box
                            sx={{
                                backgroundColor: '#F8FAFC',
                                height: '100vh !important',
                            }}
                        >
                            <Box sx={{ backgroundColor: '#FFFFFF', py: 2, px: 4, height: '7vh' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '50%',
                                    }}
                                >
                                    <img src={logo} alt="SmartComply logo" />
                                    <Typography sx={{ color: '#002C72', fontSize: 18, fontWeight: 700 }}>
                                        Vendor Questionaire
                                    </Typography>
                                </Box>
                            </Box>
                            <QuestionnaireFormsView
                                readonly={readonly}
                                vendor={vendor}
                                merchant={merchant}
                                page={page}
                                setPage={setPage}
                                questionnaireValue={questionnaireValue}
                                one_vendor={one_vendor}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        one_vendor: state?.vendorReducers?.one_vendor,
        questionnaireData: state?.vendorReducers?.questionnaire_history,
    };
};
export default connect(mapStateToProps, { GetOneVendor, GetQuestionnaireHistory })(VendorsView);

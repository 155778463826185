import React, { useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import AppInput from 'components/new_components/AppInput';
import { LoadingButton } from '@mui/lab';
import LightBulb from '../../../../../assets/img/icons/light-bulb.svg';

const UgandaIdModal = (props) => {
    const { open, handleClose, handleBackgroundCheck, loading } = props;
    const [nationalId, setNationalId] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [touched, setTouched] = useState({ national_id: false, document_id: false, date_of_birth: false });
    const [errors, setErrors] = useState({});

    const { t } = useTranslation('onboardingPolicy');

    const validate = (values) => {
        const newErrors = {};

        // Validate National Id
        if (!values.nationalId) {
            newErrors.national_id = 'National ID is required';
        } else if (!/^[A-Za-z0-9]{6,12}$/.test(values.nationalId)) {
            newErrors.national_id = 'National ID must be 6-12 alphanumeric characters';
        }

        // Validate Document Id
        if (!values.documentId) {
            newErrors.document_id = 'Document ID is required';
        } else if (!/^[A-Za-z0-9]{6,12}$/.test(values.documentId)) {
            newErrors.document_id = 'Document ID must be 6-12 alphanumeric characters';
        }

        // Validate Date of Birth
        if (!values.dateOfBirth) {
            newErrors.date_of_birth = 'Date of birth is required';
        } else {
            // Split "DD MM YYYY" into components
            const [day, month, year] = values.dateOfBirth.split(' ');

            // Convert to a proper Date object (months are 0-based in JS)
            const inputDate = new Date(`${year}-${month}-${day}`);
            const today = new Date();

            // Reset today’s time to midnight to avoid false positives
            today.setHours(0, 0, 0, 0);

            if (isNaN(inputDate.getTime())) {
                newErrors.date_of_birth = 'Invalid date format';
            } else if (inputDate >= today) {
                newErrors.date_of_birth = 'Date of birth cannot be today or in the future';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update state
        setTouched((prev) => ({ ...prev, [name]: true }));

        setNationalId((prev) => (name === 'national_id' ? value : prev));
        setDocumentId((prev) => (name === 'document_id' ? value : prev));
        setDateOfBirth((prev) => (name === 'date_of_birth' ? value : prev));

        // Call validate inside setState's callback to use the latest state
        setTimeout(() => {
            validate({
                nationalId: name === 'national_id' ? value : nationalId,
                documentId: name === 'document_id' ? value : documentId,
                dateOfBirth: name === 'date_of_birth' ? value : dateOfBirth,
            });
        }, 0);
    };

    const handleSubmit = async () => {
        const isValid = validate({ nationalId, documentId, dateOfBirth });
        if (isValid) {
            await handleBackgroundCheck({
                national_id: nationalId,
                document_id: documentId,
                date_of_birth: dateOfBirth,
                background_checktype: 'national_id',
            });
        }
    };

    return (
        <AppCenteredModalNew
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {t('backgroundCheck.nationalId')}
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 15, height: 15 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        pt: '0rem',
                        pb: '1rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={handleClose}
                    >
                        {t('backgroundCheck.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        {t('backgroundCheck.verify')}
                    </LoadingButton>
                </Box>
            }
            width="511px"
            height="fit-content"
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        backgroundColor: '#F8FAFC',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '400',
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'start',
                        border: '1px solid #F0F0FA',
                        borderRadius: '4px',
                    }}
                >
                    <img src={LightBulb} alt="light-bulb" style={{ marginRight: '10px' }} />
                    <p
                        style={{
                            fontSize: '13px',
                            fontWeight: 400,
                        }}
                    >
                        {t('backgroundCheck.nationalIdInfo')}
                    </p>
                </Typography>
                <AppInput
                    label="National ID Number:"
                    placeholder={'Enter ID Number'}
                    onChange={handleChange}
                    name="national_id"
                    error={touched.national_id && errors.national_id}
                />
                {touched.national_id && errors.national_id && (
                    <FormHelperText error>{errors.national_id}</FormHelperText>
                )}

                <AppInput
                    label="Document ID"
                    placeholder="Enter Document ID"
                    onChange={handleChange}
                    name="document_id"
                    error={touched.document_id && errors.document_id}
                />
                {touched.document_id && errors.document_id && (
                    <FormHelperText error>{errors.document_id}</FormHelperText>
                )}

                <AppInput
                    label="Date of Birth"
                    placeholder="Enter Date of Birth"
                    type="date"
                    onChange={handleChange}
                    name="date_of_birth"
                    sx={{
                        '& input::placeholder': {
                            color: touched.date_of_birth ? '#000' : '#b4b4b5',
                            opacity: 1,
                        },
                        '& input::-webkit-datetime-edit': {
                            color: touched.date_of_birth ? '#000' : '#b4b4b5',
                        },
                        '& input::-webkit-calendar-picker-indicator': {
                            filter: 'invert(50%)',
                        },
                    }}
                    error={touched.date_of_birth && errors.date_of_birth}
                />
                {touched.date_of_birth && errors.date_of_birth && (
                    <FormHelperText error>{errors.date_of_birth}</FormHelperText>
                )}
            </Box>
        </AppCenteredModalNew>
    );
};

export default UgandaIdModal;

import { validation } from 'validate';

// first_name: "",
// last_name: "",
// email: "",
// position: "",
// phone: "",
// department: "",
// address: "",
export const employeeValidation = (values) => {
    const errors = {};
    const firstNameValidate = validation(values.first_name, 'first name', true);
    const lastNameValidate = validation(values.last_name, 'last name', true);
    const emailValidate = validation(values.email, 'email', true);
    const positionValidate = validation(values.position, 'role', true);
    const phoneValidate = validation(values.phone, 'phone', true);
    const departmentValidate = validation(values.department, 'department', true);
    const addressValidate = validation(values.address, 'address', true);
    const jobDescriptionValidate = validation(values.job_description, 'job description', true);
    const countryValidate = validation(values.country, 'country', true);
    if (!firstNameValidate.isValid) {
        errors.first_name = firstNameValidate.errorMessage;
    }
    if (!lastNameValidate.isValid) {
        errors.last_name = lastNameValidate.errorMessage;
    }
    if (!emailValidate.isValid) {
        errors.email = emailValidate.errorMessage;
    }
    if (!positionValidate.isValid) {
        errors.position = positionValidate.errorMessage;
    }
    if (!phoneValidate.isValid) {
        errors.phone = phoneValidate.errorMessage;
    }
    if (!departmentValidate.isValid) {
        errors.department = departmentValidate.errorMessage;
    }
    if (!addressValidate.isValid) {
        errors.address = addressValidate.errorMessage;
    }
    if (!jobDescriptionValidate.isValid) {
        errors.job_description = jobDescriptionValidate.errorMessage;
    }
    if (!countryValidate.isValid) {
        errors.country = countryValidate.errorMessage;
    }
    return errors;
};

import {
    ONE_VENDOR,
    ALL_VENDORS,
    QUESTIONNAIRE_FORMS,
    ALL_RESPONSES,
    VENDOR_RESPONSES,
    VENDOR_UPLOADED_QUESTIONNAIRE,
    ONE_VENDOR_UPLOADED_QUESTIONNAIRE,
    ALL_FRAUDGUARDIAN_LIST,
    QUESTIONNAIRE_HISTORY,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_VENDORS:
            return {
                ...state,
                all_vendors: action?.payload,
            };
        case ONE_VENDOR:
            return {
                ...state,
                one_vendor: action?.payload,
            };
        case QUESTIONNAIRE_FORMS:
            return {
                ...state,
                questionnaire_forms: action?.payload,
            };
        case ALL_RESPONSES:
            return {
                ...state,
                all_responses: action?.payload,
            };
        case VENDOR_RESPONSES:
            return {
                ...state,
                vendor_responses: action?.payload,
            };
        case VENDOR_UPLOADED_QUESTIONNAIRE:
            return {
                ...state,
                vendor_uploaded_questionnaire: action.payload,
            };
        case ONE_VENDOR_UPLOADED_QUESTIONNAIRE:
            return {
                ...state,
                one_vendor_uploaded_questionnaire: action.payload,
            };
        case ALL_FRAUDGUARDIAN_LIST:
            return {
                ...state,
                all_fraudguardian_list: action.payload,
            };

        case QUESTIONNAIRE_HISTORY:
            return {
                ...state,
                questionnaire_history: action.payload,
            };
        default:
            return state;
    }
};

import { MoreHoriz } from '@mui/icons-material';
import AppTableModified from 'components/new_components/app-table-modified';
// import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getType } from 'utils';
import AssignTaskModal from './Modals/AssignTaskModal';

const NO_PER_PAGE = 8;

export default function ScanGroupsModified({ handleChangeView, groups, handleCategory, category }) {
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [assignTask, setAssignTask] = useState(false);

    const role = getType('role');

    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);

    const data = groups || [];

    const handlePageChange = (page) => {
        setPage(page);
    };

    const columns = [
        {
            title: 'Scan constraint',
            key: 'name',
            render: (row) => (
                <div className="flex gap-2 capitalize items-center">
                    <span>{row.name}</span>

                    {row?.is_assigned_to_current_user && (
                        <span className="text-[#FFFFFF] font-medium text-xs bg-[#D2A500] rounded-sm px-1.5 py-0.5">
                            Assigned
                        </span>
                    )}
                </div>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: () => <div className="text-[#395BA9] text-sm">Completed</div>,
            // render: (row) =>
            //     row.total === 0 ? (
            //         <div className="text-[#64748B] text-sm italic flex items-center">
            //             <Loader2 className="mr-1 h-4 w-4 animate-spin" /> Running...
            //         </div>
            //     ) : (
            //         <div className="text-[#395BA9] text-sm">Completed</div>
            //     ),
        },
        {
            title: 'Total scanned',
            key: 'total',
            render: (row) =>
                row.total === 0 ? (
                    <div className="text-[#64748B] border-[#E2E8F0] rounded border px-2 py-1 grid place-items-center h-[29px] font-medium text-sm w-fit">
                        ---
                    </div>
                ) : (
                    <div className="text-[#64748B] border-[#E2E8F0] rounded border px-2 py-1 flex items-center justify-center h-[29px] gap-1 font-medium text-sm w-fit">
                        <div className="bg-[#94A3B8] rounded-full w-2 h-2" />

                        <div>{row.total}</div>
                    </div>
                ),
        },
        {
            title: 'Failed',
            key: 'failed',
            render: (row) =>
                row.total === 0 ? (
                    <div className="text-[#64748B] border-[#E2E8F0] rounded border px-2 py-1 grid place-items-center h-[29px] font-medium text-sm w-fit">
                        ---
                    </div>
                ) : (
                    <div className="text-[#FF5449] border-[#E2E8F0] rounded border px-2 py-1 grid place-items-center h-[29px] font-medium text-sm w-fit">
                        {row.failed}
                    </div>
                ),
        },
        {
            title: 'Passed',
            key: 'passed',
            render: (row) =>
                row.total === 0 ? (
                    <div className="text-[#64748B] border-[#E2E8F0] rounded border px-2 py-1 grid place-items-center h-[29px] font-medium text-sm w-fit">
                        ---
                    </div>
                ) : (
                    <div className="text-[#37A372] border-[#E2E8F0] rounded border px-2 py-1 grid place-items-center h-[29px] font-medium text-sm w-fit">
                        {row.passed}
                    </div>
                ),
        },
        {
            title: (
                <div className="flex items-center justify-end pr-4">
                    <MoreHoriz />
                </div>
            ),
            key: 'action',
            render: (row) => (
                <div className="flex gap-2 items-center">
                    <button
                        className="text-[#64748B] text-sm font-medium h-[29px] bg-[#F8FAFC] border-2 border-[#F1F5F9] flex items-center justify-center gap-1 px-2 py-1 rounded disabled:opacity-50 disabled:pointer-events-none hover:brightness-90 transition-all"
                        onClick={() => handleChangeView('scans', row)}
                        disabled={row.total === 0}
                    >
                        <img src="/img/automated-scan/eye-icon.svg" alt="eye" className="object-contain" />

                        <span>View</span>
                    </button>

                    <button
                        className="text-[#64748B] text-sm font-medium h-[29px] border-2 border-[#F1F5F9] flex items-center justify-center px-2 py-1 rounded disabled:opacity-50 disabled:pointer-events-none hover:brightness-90 bg-white transition-all"
                        onClick={(e) => {
                            e.stopPropagation();
                            setAssignTask(true);
                            handleCategory(row);
                        }}
                        disabled={
                            row.total === 0 ||
                            row?.is_assigned_to_current_user ||
                            role === 'auditor' ||
                            organization?.merchant_plan?.name === 'free_version' ||
                            organization?.merchant_plan?.name === '3_days_trial'
                        }
                    >
                        Assign
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data.slice(start, stop));
    }, [page, data]);

    return (
        <div className="mt-6 px-4 !pt-6 bg-white rounded">
            <AppTableModified
                columns={columns}
                data={pageData}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                search={false}
                showTitleBar={false}
                clickableRow={() => true}
                onRowClick={(row) => row.total > 0 && handleChangeView('scans', row)}
            />

            <AssignTaskModal
                open={assignTask}
                onClose={() => setAssignTask(false)}
                type="Scan constraint"
                modalTitle="scan constraint"
                category={category}
            />
        </div>
    );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'assets/img/brand/logo.svg';
import Employees from './Employees';
import InverntoryScan from './InventoryScan';
import moment from 'moment';
import EmployeesTable from './EmployeesTable';
import ReportSummary2 from './ReportSummary2';
import ReportSummary1 from './ReportSummary1';
import Standards from './Standards';
import InventoryScansTable from './InventoryScansTable';
import ThirdParty from './ThirdParty';
import AutomatedScans from './AutomatedScans';
import RiskAssessment from './RiskAssessment';

function SpoolReportPrint2({
    targetRef,
    standards,
    automated_scans,
    view,
    inventory_options,
    third_party_options,
    employees_options,
    risk_options,
    filterYear,
    show_summary_2,
    scans_,
    employees_,
}) {
    const { all_third_party_summary, people_info_summary, inventory_scan_summary } = useSelector(
        (state) => state?.CISOReducers
    );

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const date_joined = useSelector((state) => state?.authReducers?.user_details?.user?.date_joined) ?? null;
    const yearUserSignedUp = date_joined ? moment(date_joined)?.year() : moment()?.year();

    // console.log({
    //     pcidss_ciso_summary,
    // });

    return (
        <div className="fixed -bottom-[200%] -right-[200%] z-50 h-[85%] w-[746.76px] overflow-y-scroll bg-white">
            <main ref={targetRef}>
                {/* <div className="relative my-4 h-full w-full bg-white"> */}
                <section className="relative rounded-md mb-4 h-[800px]" style={{ zIndex: 1 }} hidden={false}>
                    <div className="absolute top-10 left-2">
                        <Logo className="w-[180px]" />
                    </div>

                    <div className="absolute top-1/2 text-center left-1/2 -translate-y-1/2 -translate-x-1/2 transform w-full">
                        <h2 className="text-[#334155] font-bold text-[36px] w-full">
                            CISO REPORT AND COMPLIANCE HEALTH
                        </h2>

                        {filterYear === yearUserSignedUp ? (
                            <p className="text-[#475569] font-medium text-xl mt-9">
                                {moment(merchant_info?.date_created).format('MMMM Do, YYYY')} - December 31,{' '}
                                {yearUserSignedUp}
                            </p>
                        ) : filterYear === JSON?.parse(moment(new Date()).format('YYYY')) ? (
                            <p className="text-[#475569] font-medium text-xl mt-9">
                                January 1, {filterYear} - {moment(new Date()).format('MMMM Do, YYYY')}
                            </p>
                        ) : filterYear !== 'All' ? (
                            <p className="text-[#475569] font-medium text-xl mt-9">
                                January 1, {filterYear} - December 31, {filterYear}
                            </p>
                        ) : (
                            <p className="text-[#475569] font-medium text-xl mt-9">
                                {moment(merchant_info?.date_created).format('MMMM Do, YYYY')} -{' '}
                                {moment(new Date()).format('MMMM Do, YYYY')}
                            </p>
                        )}
                        <h3 className="mt-20 text-[#475569] font-semibold text-[28px]">{merchant_info?.name}</h3>
                    </div>
                </section>

                <section className="relative mt-[24rem]">
                    <section className="relative bg-white rounded-md mb-4">
                        <h4 className="text-[#2B3674] text-[22px] font-bold text-center">Summary of the Report</h4>
                        <ReportSummary1 standards={standards} automated_scans={automated_scans} view={view} isPdf />
                    </section>
                    {show_summary_2 ? (
                        <section className="relative bg-white rounded-md mb-4">
                            <ReportSummary2
                                isPdf
                                thirdpartyOptions={third_party_options}
                                employeeOptions={employees_options}
                                inventoryOptions={inventory_options}
                                scans_={scans_}
                                employees_={employees_}
                            />
                        </section>
                    ) : null}

                    <section className={``} />

                    {(view === 'Report View' || view === 'All') && automated_scans?.length > 0 && (
                        <section className="relative bg-white rounded-md mb-4">
                            <AutomatedScans isPdf />
                        </section>
                    )}
                    {(view === 'Report View' || view === 'All') && standards?.length > 0 && (
                        <section className="relative bg-white rounded-md mb-4">
                            <Standards standards={standards} isPdf />
                        </section>
                    )}
                    <section className="relative bg-white rounded-md mb-4">
                        <RiskAssessment view={view} risk_options={risk_options} isPdf />
                    </section>
                    {(view === 'Graphical Presentation' || view === 'All') && employees_options?.length > 0 && (
                        <section
                            className="relative bg-white rounded-md mb-4"
                            hidden={people_info_summary && people_info_summary?.employees?.length === 0}
                        >
                            <Employees isPdf employeeOptions={employees_options} />
                        </section>
                    )}

                    {(view === 'Report View' || view === 'All') && employees_options?.length > 0 && (
                        <section
                            className="relative bg-white rounded-md mb-4"
                            hidden={people_info_summary && people_info_summary?.employees?.length === 0}
                        >
                            <EmployeesTable employees_options={employees_options} />
                        </section>
                    )}
                    {(view === 'Graphical Presentation' || view === 'All') && inventory_options?.length > 0 && (
                        <section
                            className="relative bg-white rounded-md mb-4"
                            hidden={
                                inventory_scan_summary?.pentest_info?.length === 0 &&
                                inventory_scan_summary?.pci_scan_info?.length === 0 &&
                                inventory_scan_summary?.api_compliance_info?.length == 0 &&
                                inventory_scan_summary?.codebase_security_info?.length === 0
                            }
                        >
                            <InverntoryScan isPdf />
                        </section>
                    )}
                    {(view === 'Report View' || view === 'All') && inventory_options?.length > 0 && (
                        <section
                            className="relative bg-white rounded-md mb-4"
                            hidden={
                                inventory_scan_summary?.pentest_info?.length === 0 &&
                                inventory_scan_summary?.pci_scan_info?.length === 0 &&
                                inventory_scan_summary?.api_compliance_info?.length == 0 &&
                                inventory_scan_summary?.codebase_security_info?.length === 0
                            }
                        >
                            <InventoryScansTable inventory_options={inventory_options} />
                        </section>
                    )}
                    {third_party_options?.length > 0 && (
                        <section
                            className="relative bg-white rounded-md mb-4"
                            hidden={
                                all_third_party_summary?.aml_check_info?.length === 0 &&
                                all_third_party_summary?.vendor_management_info?.length === 0 &&
                                all_third_party_summary?.transaction_fraud_info?.length === 0 &&
                                all_third_party_summary?.transaction_fraud_info?.length === 0
                            }
                        >
                            <ThirdParty third_party_options={third_party_options} view={view} isPdf />
                        </section>
                    )}
                </section>
                {/* </div> */}
            </main>
        </div>
    );
}
export default SpoolReportPrint2;

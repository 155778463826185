/* eslint-disable no-unused-vars */
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Divider, Drawer, Slide, Typography } from '@mui/material';
import FileTypes from 'assets/img/Filetypes.svg';
import CommentIcon from 'assets/img/comment.svg';
import Docx from 'assets/img/docx.svg';
import Download from 'assets/img/download01.svg';
import Img from 'assets/img/img.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import CustomSwitch from 'components/new_components/CustomSwitch';
import moment from 'moment';
import React, { useState } from 'react';
import { Resolution, usePDF } from 'react-to-pdf';
import { by3riskLevel, by5riskLevel } from 'utils';
import { tabs } from '../utils/constants';
// import LoadingState from 'components/new_components/LoadingState';
// import { GetDownloadLink } from 'store/actions/generalActions';
// import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { by3riskValue, by5riskValue } from 'utils';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ViewDetailsDrawer = (props) => {
    const { open, handleClose, details, openReslovedModal } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [loadingLink, setLoadingLink] = useState(false);

    const [risk_settings] = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings || []);

    const amountFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: details?.mitigation_cost_currency ? details?.mitigation_cost_currency : 'NGN',
        })
            .format(value)
            .trim();

    // async function handlePrint() {
    //     try {
    //         if (open) {
    //             window.print();
    //         }
    //     } catch (error) {
    //         console.error('Error printing risk assessment details: ', error);
    //     }
    // }

    // const getDownloadLink = async (link, id, model) => {
    //     if (link && !loadingLink && id) {
    //         setLoadingLink(true);
    //         const res = await GetDownloadLink(model[0], model[1], id)();
    //         setLoadingLink(false);
    //         if (res?.success) {
    //             // open decoded url
    //             window.open(res?.data, '_blank');
    //         } else {
    //             toast.error('Something went wrong!');
    //         }
    //     }
    // };

    const { targetRef, toPDF } = usePDF({
        filename: 'risk-assessment-report.pdf',
        resolution: Resolution.HIGH,
        page: {
            orientation: 'portrait',
        },
        canvas: {
            mimeType: 'image/jpeg',
            qualityRatio: 2,
        },
    });
    return (
        <>
            {open && details && (
                <Drawer
                    open={open}
                    anchor="right"
                    TransitionComponent={Transition}
                    transitionDuration={1000}
                    keepMounted
                    onClose={() => {
                        setCurrentTab(0);
                        handleClose();
                    }}
                    PaperProps={{
                        sx: {
                            boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                            flex: 1,
                            width: '100%',
                            overflow: 'hidden',
                            zIndex: 9999,
                        },
                    }}
                    scroll="paper"
                >
                    <div className="flex justify-between items-center border border-[#F1F5F9] px-[2rem] py-[0.5rem] fixed top-0 w-full h-[50px] bg-white">
                        <div
                            className="cursor-pointer flex items-center gap-3"
                            onClick={() => {
                                setCurrentTab(0);
                                handleClose();
                            }}
                        >
                            <KeyboardBackspaceIcon />
                            <p className="text-[#002C72] text-lg font-bold">Risk Assessment details</p>
                        </div>
                        <div className="flex items-center gap-4">
                            {/* {details?.management_approver && ( */}
                            <div className="flex items-center gap-2">
                                <p className="text-[#475569] text-base font-medium">Management Approval</p>
                                <CustomSwitch
                                    checked={details?.management_approver}
                                    onChange={() => openReslovedModal(details)}
                                    disabled
                                />
                            </div>
                            {/* )} */}
                            {/* <button
                                className="bg-[#F1F5F9] border border-[#E2E8F0] flex items-center gap-1 rounded p-1.5 cursor-pointer"
                                // onClick={handlePrint}
                                onClick={() => {
                                    setLoading(true);
                                    toPDF();
                                    setTimeout(() => setLoading(false), 2000);
                                }}
                            >
                                {loading && <Loader2 className="h-6 w-6 animate-spin" />}{' '}
                                <img src={SpoolIcon} alt="Download Icon" />
                                <p className="text-[#395BA9] text-[13px] font-medium">Print</p>
                            </button> */}
                        </div>
                    </div>
                    <div className="mt-[50px] overflow-y-auto overflow-x-hidden">
                        <div className="px-[4rem]">
                            <Box>
                                <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', mt: 4 }}>
                                    {tabs?.map((tab) => {
                                        return (
                                            <Box key={tab.index} sx={{ cursor: 'pointer' }}>
                                                <Typography
                                                    sx={{
                                                        color: currentTab === tab.index ? '#202D66' : '#94A3B8',
                                                        fontSize: '15px',
                                                        fontWeight: 600,
                                                        textAlign: 'right',
                                                        cursor: 'pointer',
                                                        mt: currentTab === tab.index ? 0 : -0.5,
                                                    }}
                                                    onClick={() => setCurrentTab(tab.index)}
                                                >
                                                    {tab.label}
                                                </Typography>
                                                {currentTab === tab.index && (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#202D66',
                                                            width: '100%',
                                                            mt: 0.4,
                                                            pt: 0.5,
                                                            borderTopLeftRadius: '10px',
                                                            borderTopRightRadius: '10px',
                                                        }}
                                                    ></Box>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Divider sx={{ mx: '-3.5rem' }} />
                            </Box>
                        </div>
                        <div className="px-[4rem] mt-[2rem]">
                            {currentTab === 0 && (
                                <>
                                    {details?.comments && (
                                        <div className="flex items-centerg gap-3 bg-[#F8FAFC] border border-[#F0F0FA] mb-4 p-2">
                                            <img src={CommentIcon} alt="CommentIcon" />
                                            <p className="text-[#64748B] text-[13px] font-normal ">
                                                <strong className="font-bold">Comment:</strong> {details?.comments}
                                            </p>
                                        </div>
                                    )}
                                    {details?.suggestion && (
                                        <div className="flex items-centerg gap-3 bg-[#F8FAFC] border border-[#F0F0FA] mb-4 p-2">
                                            <img src={CommentIcon} alt="CommentIcon" />
                                            <p className="text-[#64748B] text-[13px] font-normal ">
                                                <strong className="font-bold">Suggestion:</strong> {details?.suggestion}
                                            </p>
                                        </div>
                                    )}
                                    {details?.approval_comment && (
                                        <div className="flex items-centerg gap-3 bg-[#F8FAFC] border border-[#F0F0FA] mb-4 p-2">
                                            <img src={CommentIcon} alt="CommentIcon" />
                                            <p className="text-[#64748B] text-[13px] font-normal ">
                                                <strong className="font-bold">Approval Comment:</strong>{' '}
                                                {details?.approval_comment}
                                            </p>
                                        </div>
                                    )}
                                    <div className="flex items-center gap-5">
                                        <div className="flex items-center gap-5">
                                            <p className="text-[#64748B] text-sm font-semibold">
                                                Risk Treatment Status
                                            </p>
                                            <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0] capitalize">
                                                {details?.risk_assessment_status ? (
                                                    <>
                                                        <div
                                                            className={`w-2 h-2 rounded-full ${
                                                                details?.risk_assessment_status?.toLowerCase() ===
                                                                'resolved'
                                                                    ? 'bg-[#55BE8B]'
                                                                    : details?.risk_assessment_status?.toLowerCase() ===
                                                                      'not_resolved'
                                                                    ? 'bg-[#B28C00]'
                                                                    : 'bg-[#FF5449]'
                                                            }`}
                                                        />
                                                        <p className="text-[#64748B] font-medium text-[13px]">
                                                            {details?.risk_assessment_status?.replace(/_/g, ' ')}
                                                        </p>
                                                    </>
                                                ) : (
                                                    '__'
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-5">
                                            <p className="text-[#64748B] text-sm font-semibold">Inherent Risk</p>
                                            <p
                                                className="text-white text-sm font-semibold p-1.5 rounded capitalize"
                                                style={{
                                                    backgroundColor:
                                                        details?.matrix === '3x3'
                                                            ? by3riskLevel(details?.inherent_risk).color
                                                            : by5riskLevel(details?.inherent_risk).color,
                                                }}
                                            >
                                                {details?.matrix === '3x3'
                                                    ? `${
                                                          risk_settings?.label_3x3[
                                                              by3riskLevel(details?.inherent_risk)
                                                                  .type?.toLowerCase()
                                                                  ?.replace(' ', '_')
                                                          ]
                                                              ? risk_settings?.label_3x3[
                                                                    by3riskLevel(details?.inherent_risk)
                                                                        .type?.toLowerCase()
                                                                        ?.replace(' ', '_')
                                                                ]
                                                              : by3riskLevel(details?.inherent_risk).type
                                                      }`
                                                    : `${
                                                          risk_settings?.label_5x5[
                                                              by5riskLevel(details?.inherent_risk)
                                                                  .type?.toLowerCase()
                                                                  ?.replace(' ', '_')
                                                          ]
                                                              ? risk_settings?.label_5x5[
                                                                    by5riskLevel(details?.inherent_risk)
                                                                        .type?.toLowerCase()
                                                                        ?.replace(' ', '_')
                                                                ]
                                                              : by5riskLevel(details?.inherent_risk).type
                                                      }`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="my-[1rem]">
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Risk Subject
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                {details?.name}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Risk Matrix
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4">
                                                {details?.matrix}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Date Created
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                {moment(details?.date_created).format('DD MMM, YYYY hh:mm a')}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Category</p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                {details?.category_detail || 'N/A'}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Asset Type
                                            </p>
                                            {details?.asset_details?.length > 0 ? (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    {details?.asset_details?.map((asset, index) => (
                                                        <span
                                                            className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded"
                                                            key={index}
                                                        >
                                                            {asset?.asset_type}
                                                        </span>
                                                    ))}
                                                </p>
                                            ) : (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    N/A
                                                </p>
                                            )}
                                        </div>
                                        {/* <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Asset Classification</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.asset_classification}
                                </p>
                            </div> */}
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Asset (s)</p>
                                            {details?.asset_details.length ? (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    {details?.asset_details?.map((asset, index) => (
                                                        <span
                                                            className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 rounded"
                                                            key={index}
                                                        >
                                                            {asset?.name}
                                                        </span>
                                                    ))}
                                                </p>
                                            ) : (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    N/A
                                                </p>
                                            )}
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Risk owner
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                {details?.solution_owner || 'N/A'}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Asset Location/branch
                                            </p>
                                            {details?.asset_location ? (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    {details?.asset_location?.map((location, index) => (
                                                        <span
                                                            className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 rounded"
                                                            key={index}
                                                        >
                                                            {location}
                                                        </span>
                                                    ))}
                                                </p>
                                            ) : (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    N/A
                                                </p>
                                            )}
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Threat</p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                {details?.threat_detail?.threat_category_detail?.name} -{' '}
                                                {details?.threat_detail?.name}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Vulnerabilities
                                            </p>
                                            <p className="w-[60%] flex-wrap border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                {details?.vulnerability_detail?.map((vulnerability, index) => (
                                                    <span
                                                        className="w-fit border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded"
                                                        key={index}
                                                    >
                                                        {vulnerability?.name || 'N/A'}
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Existing Controls
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B pl-4">
                                                {details?.existing_controls || 'N/A'}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Inherent Likelihood
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded capitalize">
                                                    {details?.matrix === '3x3'
                                                        ? `${
                                                              risk_settings?.label_3x3[
                                                                  by3riskLevel(details?.inherent_likelihood)
                                                                      .type?.toLowerCase()
                                                                      ?.replace(' ', '_')
                                                              ]
                                                                  ? risk_settings?.label_3x3[
                                                                        by3riskLevel(details?.inherent_likelihood)
                                                                            .type?.toLowerCase()
                                                                            ?.replace(' ', '_')
                                                                    ]
                                                                  : by3riskLevel(details?.inherent_likelihood).type
                                                          }`
                                                        : `${
                                                              risk_settings?.label_5x5[
                                                                  by5riskLevel(details?.inherent_likelihood)
                                                                      .type?.toLowerCase()
                                                                      ?.replace(' ', '_')
                                                              ]
                                                                  ? risk_settings?.label_5x5[
                                                                        by5riskLevel(details?.inherent_likelihood)
                                                                            .type?.toLowerCase()
                                                                            ?.replace(' ', '_')
                                                                    ]
                                                                  : by5riskLevel(details?.inherent_likelihood).type
                                                          }`}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Risk Impact on CIA
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                Confidentiality: {details?.cia_impact?.confidentiality || ' N/A'} ||
                                                Integrity: {details?.cia_impact?.integrity || ' N/A'} || Availability:{' '}
                                                {details?.cia_impact?.availability || ' N/A'}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Inherent Impact
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded capitalize">
                                                    {details?.matrix === '3x3'
                                                        ? `${
                                                              risk_settings?.label_3x3[
                                                                  by3riskLevel(details?.inherent_impact)
                                                                      .type?.toLowerCase()
                                                                      ?.replace(' ', '_')
                                                              ]
                                                                  ? risk_settings?.label_3x3[
                                                                        by3riskLevel(details?.inherent_impact)
                                                                            .type?.toLowerCase()
                                                                            ?.replace(' ', '_')
                                                                    ]
                                                                  : by3riskLevel(details?.inherent_impact).type
                                                          }`
                                                        : `${
                                                              risk_settings?.label_5x5[
                                                                  by5riskLevel(details?.inherent_impact)
                                                                      .type?.toLowerCase()
                                                                      ?.replace(' ', '_')
                                                              ]
                                                                  ? risk_settings?.label_5x5[
                                                                        by5riskLevel(details?.inherent_impact)
                                                                            .type?.toLowerCase()
                                                                            ?.replace(' ', '_')
                                                                    ]
                                                                  : by5riskLevel(details?.inherent_impact).type
                                                          }`}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Linked Framework
                                            </p>
                                            {details?.frameworks?.length ? (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    {(typeof details?.frameworks === 'string'
                                                        ? details?.frameworks?.split(',')
                                                        : details?.frameworks
                                                    )?.map((framework, index) => (
                                                        <span
                                                            className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded"
                                                            key={index}
                                                        >
                                                            {framework}
                                                        </span>
                                                    ))}
                                                </p>
                                            ) : (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                                    N/A
                                                </p>
                                            )}
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Additional Information/comment
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                {details?.additional_notes || 'N/A'}
                                            </p>
                                        </div>
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Supporting Document
                                            </p>
                                            {details?.new_document ? (
                                                <div className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4 flex items-center gap-2">
                                                    <img
                                                        src={
                                                            details?.new_document?.split('.').pop() === 'xlsx'
                                                                ? Xsl
                                                                : details?.new_document?.split('.').pop() === 'csv'
                                                                ? Xsl
                                                                : details?.new_document?.split('.').pop() === 'docx'
                                                                ? Docx
                                                                : details?.new_document?.split('.').pop() === 'png'
                                                                ? Png
                                                                : details?.new_document?.split('.').pop() === 'img'
                                                                ? Img
                                                                : FileTypes
                                                        }
                                                        alt="PdfIcon"
                                                    />
                                                    <p> {details?.new_document?.split('/')?.pop()}</p>
                                                    {/* <Box
                                                    onClick={() =>
                                                        getDownloadLink(
                                                            details?.document_detail?.document || details?.new_document,
                                                            details?.document_detail?.id || details.id,
                                                            details?.document_detail?.id
                                                                ? ['compliance', 'userdoc']
                                                                : ['scans', 'risk_assessment']
                                                        )
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        ml: 2,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {' '}
                                                    {loadingLink ? (
                                                        <LoadingState size={20} />
                                                    ) : (
                                                        <img src={Download} alt={'Download'} />
                                                    )}
                                                </Box> */}
                                                    <Box
                                                        component={'a'}
                                                        href={
                                                            details?.document_detail?.document || details?.new_document
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            ml: 2,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <img src={Download} alt={'Download'} />
                                                    </Box>
                                                </div>
                                            ) : details?.existing_document ? (
                                                <div className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4 flex items-center gap-2">
                                                    <img
                                                        src={
                                                            details?.document_detail?.document?.split('.').pop() ===
                                                            'xlsx'
                                                                ? Xsl
                                                                : details?.document_detail?.document
                                                                      ?.split('.')
                                                                      .pop() === 'csv'
                                                                ? Xsl
                                                                : details?.document_detail?.document
                                                                      ?.split('.')
                                                                      .pop() === 'docx'
                                                                ? Docx
                                                                : details?.document_detail?.document
                                                                      ?.split('.')
                                                                      .pop() === 'png'
                                                                ? Png
                                                                : details?.document_detail?.document
                                                                      ?.split('.')
                                                                      .pop() === 'img'
                                                                ? Img
                                                                : FileTypes
                                                        }
                                                        alt="PdfIcon"
                                                    />
                                                    <p> {details?.document_detail?.document?.split('/')?.pop()}</p>
                                                    {/* 
                                                <Box
                                                    onClick={() =>
                                                        getDownloadLink(
                                                            details?.document_detail?.document || details?.new_document,
                                                            details?.document_detail?.id || details.id,
                                                            details?.document_detail?.id
                                                                ? ['compliance', 'userdoc']
                                                                : ['scans', 'risk_assessment']
                                                        )
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        ml: 2,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {' '}
                                                    {loadingLink ? (
                                                        <LoadingState size={20} />
                                                    ) : (
                                                        <img src={Download} alt={'Download'} />
                                                    )}
                                                </Box> */}
                                                    <Box
                                                        component={'a'}
                                                        href={
                                                            details?.document_detail?.document || details?.new_document
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            ml: 2,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <img src={Download} alt={'Download'} />
                                                    </Box>
                                                </div>
                                            ) : (
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                    N / A
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentTab === 1 && (
                                <>
                                    {details?.mitigation_plan?.toLowerCase() !== 'avoid' &&
                                        details?.mitigation_plan?.toLowerCase() !== 'transfer' && (
                                            <div className="flex items-center gap-5">
                                                <p className="text-[#64748B] text-sm font-semibold">Residual Risk</p>
                                                <p
                                                    className="text-white text-sm font-semibold p-1.5 rounded capitalize"
                                                    style={{
                                                        backgroundColor:
                                                            details?.matrix === '3x3'
                                                                ? by3riskLevel(details?.residual_risk).color
                                                                : by5riskLevel(details?.residual_risk).color,
                                                    }}
                                                >
                                                    {details?.matrix === '3x3'
                                                        ? `${
                                                              risk_settings?.label_3x3[
                                                                  by3riskLevel(details?.residual_risk)
                                                                      .type?.toLowerCase()
                                                                      ?.replace(' ', '_')
                                                              ]
                                                                  ? risk_settings?.label_3x3[
                                                                        by3riskLevel(details?.residual_risk)
                                                                            .type?.toLowerCase()
                                                                            ?.replace(' ', '_')
                                                                    ]
                                                                  : by3riskLevel(details?.residual_risk).type
                                                          }`
                                                        : `${
                                                              risk_settings?.label_5x5[
                                                                  by5riskLevel(details?.residual_risk)
                                                                      .type?.toLowerCase()
                                                                      ?.replace(' ', '_')
                                                              ]
                                                                  ? risk_settings?.label_5x5[
                                                                        by5riskLevel(details?.residual_risk)
                                                                            .type?.toLowerCase()
                                                                            ?.replace(' ', '_')
                                                                    ]
                                                                  : by5riskLevel(details?.residual_risk).type
                                                          }`}
                                                </p>
                                            </div>
                                        )}
                                    <div className="my-[1rem]">
                                        {details?.report_risk_to ? (
                                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                    Report risk to
                                                </p>
                                                {details?.report_risk_to ? (
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                                        {details?.report_risk_to_detail
                                                            ? details?.report_risk_to_detail
                                                            : details?.report_risk_to}
                                                    </p>
                                                ) : (
                                                    <div className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                                        <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                                                            --
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                        <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                Treatment Plan
                                            </p>
                                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                                {details?.mitigation_plan?.toLowerCase() || 'N/A'}
                                            </p>
                                        </div>
                                        {details?.mitigation_plan?.toLowerCase() === 'mitigate' && (
                                            <>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Mitigation Owner
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.solution_owner?.detail ||
                                                            details?.solution_owner ||
                                                            'N/A'}
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Mitigation Controls
                                                    </p>
                                                    {details?.mitigation_controls ? (
                                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                            <p className="flex items-center gap-2 flex-wrap">
                                                                {details?.mitigation_controls
                                                                    ?.split(',')
                                                                    ?.map((item) => (
                                                                        <span>{item}</span>
                                                                    ))}
                                                            </p>
                                                        </p>
                                                    ) : (
                                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                            N/A
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Mitigation timeline
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                                        {moment(details?.timeline_start_date).format('DD MMMM, YYYY')} -{' '}
                                                        {moment(details?.timeline_end_date).format('DD MMMM, YYYY')}
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Mitigation cost ({details?.mitigation_cost_currency})
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {amountFormat(details?.mitigation_cost_amount) || 'N/A'}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        {details?.mitigation_plan?.toLowerCase() === 'accept' && (
                                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                    Justification
                                                </p>
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                    {details?.justification || 'N/A'}
                                                </p>
                                            </div>
                                        )}

                                        {details?.contingency_plan && (
                                            <>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Contingency Plan
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.contingency_plan ? 'True' : 'False'}
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Contingency Plan Text
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                                            {details?.contingency_plan_text}
                                                        </p>
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Contingency Timeline
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                                            {moment(details?.contingency_start_date).format(
                                                                'DD MMMM, YYYY'
                                                            )}{' '}
                                                            -{' '}
                                                            {moment(details?.contingency_end_date).format(
                                                                'DD MMMM, YYYY'
                                                            )}
                                                        </p>
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        {details?.mitigation_plan?.toLowerCase() === 'transfer' && (
                                            <>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Treatment Recepient
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.solution_owner?.detail ||
                                                            details?.solution_owner ||
                                                            'N/A'}
                                                    </p>
                                                </div>

                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Treatment timeline
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                                        {moment(details?.timeline_start_date).format('DD MMMM, YYYY')} -{' '}
                                                        {moment(details?.timeline_end_date).format('DD MMMM, YYYY')}
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Vendor/Partner
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.vendor || 'N/A'}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        {details?.mitigation_plan?.toLowerCase() === 'avoid' && (
                                            <>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Risk Elimination Method
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.risk_elimination_method || 'N/A'}
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Source
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.risk_source || 'N/A'}
                                                    </p>
                                                </div>

                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Treatment Owner
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                        {details?.solution_owner_detail || 'N/A'}
                                                    </p>
                                                </div>
                                                <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                        Treatment timeline
                                                    </p>
                                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                                        {moment(details?.timeline_start_date).format('DD MMMM, YYYY')} -{' '}
                                                        {moment(details?.timeline_end_date).format('DD MMMM, YYYY')}
                                                    </p>
                                                </div>
                                            </>
                                        )}

                                        {details?.mitigation_plan?.toLowerCase() !== 'avoid' &&
                                            details?.mitigation_plan?.toLowerCase() !== 'transfer' && (
                                                <>
                                                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                            Residual Likelihood
                                                        </p>
                                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                            <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded capitalize">
                                                                {details?.matrix === '3x3'
                                                                    ? `${
                                                                          risk_settings?.label_3x3[
                                                                              by3riskLevel(details?.residual_likelihood)
                                                                                  .type?.toLowerCase()
                                                                                  ?.replace(' ', '_')
                                                                          ]
                                                                              ? risk_settings?.label_3x3[
                                                                                    by3riskLevel(
                                                                                        details?.residual_likelihood
                                                                                    )
                                                                                        .type?.toLowerCase()
                                                                                        ?.replace(' ', '_')
                                                                                ]
                                                                              : by3riskLevel(
                                                                                    details?.residual_likelihood
                                                                                )?.type
                                                                      }`
                                                                    : `${
                                                                          risk_settings?.label_5x5[
                                                                              by5riskLevel(details?.residual_likelihood)
                                                                                  .type?.toLowerCase()
                                                                                  ?.replace(' ', '_')
                                                                          ]
                                                                              ? risk_settings?.label_5x5[
                                                                                    by5riskLevel(
                                                                                        details?.residual_likelihood
                                                                                    )
                                                                                        .type?.toLowerCase()
                                                                                        ?.replace(' ', '_')
                                                                                ]
                                                                              : by5riskLevel(
                                                                                    details?.residual_likelihood
                                                                                )?.type
                                                                      }`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                            Residual Impact
                                                        </p>
                                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                            <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded capitalize">
                                                                {details?.matrix === '3x3'
                                                                    ? `${
                                                                          risk_settings?.label_3x3[
                                                                              by3riskLevel(details?.residual_impact)
                                                                                  .type?.toLowerCase()
                                                                                  ?.replace(' ', '_')
                                                                          ]
                                                                              ? risk_settings?.label_3x3[
                                                                                    by3riskLevel(
                                                                                        details?.residual_impact
                                                                                    )
                                                                                        .type?.toLowerCase()
                                                                                        ?.replace(' ', '_')
                                                                                ]
                                                                              : by3riskLevel(details?.residual_impact)
                                                                                    .type
                                                                      }`
                                                                    : `${
                                                                          risk_settings?.label_5x5[
                                                                              by5riskLevel(details?.residual_impact)
                                                                                  .type?.toLowerCase()
                                                                                  ?.replace(' ', '_')
                                                                          ]
                                                                              ? risk_settings?.label_5x5[
                                                                                    by5riskLevel(
                                                                                        details?.residual_impact
                                                                                    )
                                                                                        .type?.toLowerCase()
                                                                                        ?.replace(' ', '_')
                                                                                ]
                                                                              : by5riskLevel(details?.residual_impact)
                                                                                    .type
                                                                      }`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </>
                                            )}

                                        {details?.mitigation_plan?.toLowerCase() !== 'mitigate' && (
                                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                    Continous Monitoring
                                                </p>
                                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                                    <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                                        {details?.continuous_monitoring === false ? 'false' : 'true'}
                                                    </span>
                                                </p>
                                            </div>
                                        )}

                                        {details?.continuous_monitoring && (
                                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                                    Continous Monitoring Details
                                                </p>
                                                <div className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4 flex items-center gap-2">
                                                    {details?.continuous_monitoring_frequency?.frequency && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            {details?.continuous_monitoring_frequency?.frequency}
                                                        </span>
                                                    )}
                                                    {details?.continuous_monitoring_frequency?.number_of_units && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            {details?.continuous_monitoring_frequency?.number_of_units}
                                                        </span>
                                                    )}
                                                    {details?.continuous_monitoring_frequency?.month && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            Month
                                                        </span>
                                                    )}
                                                    {details?.continuous_monitoring_frequency?.day_of_month && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            {details?.continuous_monitoring_frequency?.day_of_month}
                                                        </span>
                                                    )}
                                                    {details?.continuous_monitoring_frequency?.day_of_week && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            {details?.continuous_monitoring_frequency?.day_of_week}
                                                        </span>
                                                    )}
                                                    {details?.continuous_monitoring_frequency?.unit && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            {details?.continuous_monitoring_frequency?.unit}
                                                        </span>
                                                    )}
                                                    {details?.continuous_monitoring_frequency?.time && (
                                                        <span className="text-[#475569] text-[13px font-normal">
                                                            {details?.continuous_monitoring_frequency?.time}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Drawer>
            )}
            <div>
                <PrintAssessmentDetials
                    open={open}
                    details={details}
                    amountFormat={amountFormat}
                    targetRef={targetRef}
                />
            </div>
        </>
    );
};
function PrintAssessmentDetials({ details, amountFormat, targetRef, open }) {
    return open && details ? (
        <main ref={targetRef} className="px-[4rem] bg-white w-full mt-4 overflow-scroll">
            <section className="mt-[5rem] h-[900px] relative bg-white">
                <h4 className="text-center text-3xl text-black font-bold mb-5">Risk Identification</h4>
                {details?.comments && (
                    <div className="flex items-centerg gap-3 bg-[#F8FAFC] border border-[#F0F0FA] mb-4 p-2">
                        <img src={CommentIcon} alt="CommentIcon" />
                        <p className="text-[#64748B] text-[13px] font-normal ">
                            <strong className="font-bold">Comment:</strong> {details?.comments}
                        </p>
                    </div>
                )}
                {details?.suggestion && (
                    <div className="flex items-centerg gap-3 bg-[#F8FAFC] border border-[#F0F0FA] mb-4 p-2">
                        <img src={CommentIcon} alt="CommentIcon" />
                        <p className="text-[#64748B] text-[13px] font-normal ">
                            <strong className="font-bold">Suggestion:</strong> {details?.suggestion}
                        </p>
                    </div>
                )}
                {details?.approval_comment && (
                    <div className="flex items-start gap-3 bg-[#F8FAFC] border border-[#F0F0FA] mb-4 p-2">
                        <img src={CommentIcon} alt="CommentIcon" />
                        <p className="text-[#64748B] text-[13px] font-normal ">
                            <strong className="font-bold">Approval Comment:</strong> {details?.approval_comment}
                        </p>
                    </div>
                )}
                <div className="flex items-center gap-5">
                    <div className="flex items-start gap-5">
                        <p className="text-[#64748B] text-sm font-semibold">Risk Treatment Status</p>
                        <div className="w-fit flex items-center gap-1 !rounded  px-1 px-0.5 pb-3 !border !border-[#E2E8F0] capitalize">
                            {details?.risk_assessment_status ? (
                                <>
                                    <div
                                        className={`rounded-full ${
                                            details?.risk_assessment_status?.toLowerCase() === 'resolved'
                                                ? 'bg-[#55BE8B]'
                                                : details?.risk_assessment_status?.toLowerCase() === 'not_resolved'
                                                ? 'bg-[#B28C00]'
                                                : 'bg-[#FF5449]'
                                        }`}
                                    />
                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        {details?.risk_assessment_status?.replace(/_/g, ' ')}
                                    </p>
                                </>
                            ) : (
                                '__'
                            )}
                        </div>
                    </div>
                    <div className="flex items-start gap-5">
                        <p className="text-[#64748B] text-sm font-semibold">Inherent Risk</p>
                        <p
                            className="text-white text-sm font-semibold px-1 px-0.5 pb-3 rounded"
                            style={{
                                backgroundColor:
                                    details?.matrix === '3x3'
                                        ? by3riskLevel(details?.inherent_risk).color
                                        : by5riskLevel(details?.inherent_risk).color,
                            }}
                        >
                            {details?.matrix === '3x3'
                                ? `${by3riskLevel(details?.inherent_risk).type}`
                                : `${by5riskLevel(details?.inherent_risk).type}`}
                        </p>
                    </div>
                </div>
                <div className="mt-[1.5rem]">
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk Subject</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            {details?.name || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk Matrix</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4">
                            {details?.matrix || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Date Created</p>
                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            {moment(details?.date_created).format('DD MMM, YYYY hh:mm a') || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Category</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            {details?.category_detail || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Asset Type</p>
                        {details?.asset_details?.length > 0 ? (
                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                {details?.asset_details?.map((asset, index) => (
                                    <span
                                        className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded"
                                        key={index}
                                    >
                                        {asset?.asset_type}
                                    </span>
                                ))}
                            </p>
                        ) : (
                            <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                N/A
                            </p>
                        )}
                    </div>
                    {/* <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Asset Classification</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.asset_classification}
                                </p>
                            </div> */}
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Asset (s)</p>
                        {details?.asset_details.length ? (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                {details?.asset_details?.map((asset, index) => (
                                    <span
                                        className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 pb-3 rounded"
                                        key={index}
                                    >
                                        {asset?.name}
                                    </span>
                                ))}
                            </p>
                        ) : (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                N/A
                            </p>
                        )}
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk owner</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            {details?.solution_owner || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Asset Location/branch</p>
                        {details?.asset_location ? (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                {details?.asset_location?.map((location, index) => (
                                    <span
                                        className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 rounded"
                                        key={index}
                                    >
                                        {location}
                                    </span>
                                ))}
                            </p>
                        ) : (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                N/A
                            </p>
                        )}
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Threat</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            {details?.threat_detail?.threat_category_detail?.name} - {details?.threat_detail?.name}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Vulnerabilities</p>
                        {details?.vulnerability_detail?.length ? (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center flex-col gap-2 pl-4">
                                {details?.vulnerability_detail?.map((vulnerability, index) => (
                                    <p
                                        className="border border-[#E2E8F0] text-[#475569] text-[13px] font-normal px-1 px-0.5 pb-3 rounded mb-2"
                                        key={index}
                                    >
                                        {vulnerability?.name}
                                    </p>
                                ))}
                            </p>
                        ) : (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center flex-col gap-2 pl-4">
                                N/A
                            </p>
                        )}
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Existing Controls</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B pl-4">
                            {details?.existing_controls || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Inherent Likelihood</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 pb-3 rounded">
                                {details?.matrix === '3x3'
                                    ? `${by3riskValue(details?.inherent_likelihood).type}`
                                    : `${by5riskValue(details?.inherent_likelihood).type}`}
                            </span>
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk Impact on CIA</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            Confidentiality: {details?.cia_impact?.confidentiality || ' N/A'} || Integrity:{' '}
                            {details?.cia_impact?.integrity || ' N/A'} || Availability:{' '}
                            {details?.cia_impact?.availability || ' N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Inherent Impact</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 pb-3 rounded">
                                {details?.matrix === '3x3'
                                    ? `${by3riskValue(details?.inherent_impact).type}`
                                    : `${by5riskValue(details?.inherent_impact).type}`}
                            </span>
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Linked Framework</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                            {(typeof details?.frameworks === 'string'
                                ? details?.frameworks?.split(',')
                                : details?.frameworks
                            )?.map((framework, index) => (
                                <span
                                    className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 px-0.5 pb-3 rounded"
                                    key={index}
                                >
                                    {framework}
                                </span>
                            ))}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                            Additional Information/comment
                        </p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                            {details?.additional_notes || 'N/A'}
                        </p>
                    </div>
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Supporting Document</p>
                        {details?.new_document ? (
                            <div className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4 flex items-center gap-2">
                                <img
                                    src={
                                        details?.new_document?.split('.').pop() === 'xlsx'
                                            ? Xsl
                                            : details?.new_document?.split('.').pop() === 'csv'
                                            ? Xsl
                                            : details?.new_document?.split('.').pop() === 'docx'
                                            ? Docx
                                            : details?.new_document?.split('.').pop() === 'png'
                                            ? Png
                                            : details?.new_document?.split('.').pop() === 'img'
                                            ? Img
                                            : FileTypes
                                    }
                                    alt="PdfIcon"
                                />
                                <p> {details?.new_document?.split('/')?.pop()}</p>
                            </div>
                        ) : details?.existing_document ? (
                            <div className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4 flex items-center gap-2">
                                <img
                                    src={
                                        details?.document_detail?.document?.split('.').pop() === 'xlsx'
                                            ? Xsl
                                            : details?.document_detail?.document?.split('.').pop() === 'csv'
                                            ? Xsl
                                            : details?.document_detail?.document?.split('.').pop() === 'docx'
                                            ? Docx
                                            : details?.document_detail?.document?.split('.').pop() === 'png'
                                            ? Png
                                            : details?.document_detail?.document?.split('.').pop() === 'img'
                                            ? Img
                                            : FileTypes
                                    }
                                    alt="PdfIcon"
                                />
                                <p> {details?.document_detail?.document?.split('/')?.pop()}</p>
                            </div>
                        ) : (
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                N / A
                            </p>
                        )}
                    </div>
                </div>
            </section>

            <section className="h-[900px] relative bg-white 2xl:mt-[65rem] xl:mt-[35rem] lg:mt-[35rem] md:mt-[35rem] w-full">
                <h4 className="text-center text-3xl text-black font-bold mb-5">Risk Treatment</h4>
                {details?.mitigation_plan?.toLowerCase() !== 'avoid' &&
                    details?.mitigation_plan?.toLowerCase() !== 'transfer' && (
                        <div className="flex items-center gap-5">
                            <p className="text-[#64748B] text-sm font-semibold">Residual Risk</p>
                            <p
                                className="text-white text-sm font-semibold p-1.5 rounded"
                                style={{
                                    backgroundColor:
                                        details?.matrix === '3x3'
                                            ? by3riskLevel(details?.residual_risk).color
                                            : by5riskLevel(details?.residual_risk).color,
                                }}
                            >
                                {details?.matrix === '3x3'
                                    ? `${by3riskLevel(details?.residual_risk).type}`
                                    : `${by5riskLevel(details?.residual_risk).type}`}
                            </p>
                        </div>
                    )}
                <div className="my-[1rem]">
                    {details?.report_risk_to ? (
                        <div className="items center flex justify-between border-t border-[#000000]">
                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Report risk to</p>
                            {details?.report_risk_to ? (
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                    {details?.report_risk_to_detail
                                        ? details?.report_risk_to_detail
                                        : details?.report_risk_to}
                                </p>
                            ) : (
                                <div className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                    <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                                        --
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null}
                    <div className="items center flex justify-between border-t border-[#000000]">
                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Treatment Plan</p>
                        <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                            {details?.mitigation_plan?.toLowerCase()}
                        </p>
                    </div>
                    {details?.mitigation_plan?.toLowerCase() === 'mitigate' && (
                        <>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Mitigation Owner</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.solution_owner?.detail || details?.solution_owner || 'N/A'}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Mitigation Controls</p>
                                {details?.mitigation_controls ? (
                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                        <p className="flex items-center gap-2 flex-wrap">
                                            {details?.mitigation_controls?.split(',')?.map((item) => (
                                                <span>{item}</span>
                                            ))}
                                        </p>
                                    </p>
                                ) : (
                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                        N/A
                                    </p>
                                )}
                            </div>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Mitigation timeline</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                    {moment(details?.timeline_start_date).format('DD MMMM, YYYY')} -{' '}
                                    {moment(details?.timeline_end_date).format('DD MMMM, YYYY')}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                    Mitigation cost ({details?.mitigation_cost_currency} || 'N/A')
                                </p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {amountFormat(details?.mitigation_cost_amount) || 'N/A'}
                                </p>
                            </div>
                        </>
                    )}

                    {details?.mitigation_plan?.toLowerCase() === 'accept' && (
                        <div className="items center flex justify-between border-t border-[#000000]">
                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Justification</p>
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                {details?.justification || 'N/A'}
                            </p>
                        </div>
                    )}

                    {details?.contingency_plan && (
                        <>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Contingency Plan</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.contingency_plan ? 'True' : 'False'}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[##000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Contingency Plan Text</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                        {details?.contingency_plan_text || 'N/A'}
                                    </p>
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Contingency Timeline</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                        {moment(details?.contingency_start_date).format('DD MMMM, YYYY')} -{' '}
                                        {moment(details?.contingency_end_date).format('DD MMMM, YYYY')}
                                    </p>
                                </p>
                            </div>
                        </>
                    )}
                    {details?.mitigation_plan?.toLowerCase() === 'transfer' && (
                        <>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Treatment owner</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.solution_owner?.detail || details?.solution_owner || 'N/A'}
                                </p>
                            </div>

                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Treatment timeline</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                    {moment(details?.timeline_start_date).format('DD MMMM, YYYY')} -{' '}
                                    {moment(details?.timeline_end_date).format('DD MMMM, YYYY')}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Vendor/Partner</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.vendor || 'N/A'}
                                </p>
                            </div>
                        </>
                    )}
                    {details?.mitigation_plan?.toLowerCase() === 'avoid' && (
                        <>
                            <div className="items center flex justify-between border-t border-[##000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                    Risk Elimination Method
                                </p>
                                <p className="w-[60%] border-l border-[##000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.risk_elimination_method || 'N/A'}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Source</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.risk_source || 'N/A'}
                                </p>
                            </div>

                            <div className="items center flex justify-between border-t border-[#000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Treatment Owner</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.solution_owner_detail || 'N/A'}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[##000000]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Treatment timeline</p>
                                <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                    {moment(details?.timeline_start_date).format('DD MMMM, YYYY')} -{' '}
                                    {moment(details?.timeline_end_date).format('DD MMMM, YYYY')}
                                </p>
                            </div>
                        </>
                    )}
                    {details?.mitigation_plan?.toLowerCase() !== 'avoid' &&
                        details?.mitigation_plan?.toLowerCase() !== 'transfer' && (
                            <>
                                <div className="items center flex justify-between border-t border-[#000000]">
                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                        Residual Likelihood
                                    </p>
                                    <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                        <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                            {details?.matrix === '3x3'
                                                ? `${by3riskValue(details?.residual_likelihood).type}`
                                                : `${by5riskValue(details?.residual_likelihood).type}`}
                                        </span>
                                    </p>
                                </div>
                                <div className="items center flex justify-between border-t border-[#000000]">
                                    <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Residual Impact</p>
                                    <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                        <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                            {details?.matrix === '3x3'
                                                ? `${by3riskValue(details?.residual_impact).type}`
                                                : `${by5riskValue(details?.residual_impact).type}`}
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}

                    {details?.mitigation_plan?.toLowerCase() !== 'mitigate' && (
                        <div className="items center flex justify-between border-t border-[#000000]">
                            <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Continous Monitoring</p>
                            <p className="w-[60%] border-l border-[#000000] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal px-1 pt-0.5 pb-3 rounded">
                                    {details?.continuous_monitoring === false ? 'false' : 'true'}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </section>
        </main>
    ) : null;
}

export default ViewDetailsDrawer;

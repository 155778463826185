export const vendorsInitialValues = {
    name: '',
    description: '',
    data: '',
    risk_level: '',
    products: '',
    email: '',
    min_risk_level: '',
    ran_aml: false,
    is_aml_vendor: 'true',
};
export const vendorResponseInitialValues = {
    full_name: '',
    service_description: '',
    data_shared: '',
    products: '',
    email: '',
    is_aml_vendor: 'false',
    risk_level: '',
    min_risk_level: '',
};

export const riskLevelOptions = [
    {
        name: 'Low',
        value: 'low',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'High',
        value: 'high',
    },
];

export const ActiveStatusOptions = [
    {
        name: 'Completed',
        value: 'completed',
    },
    {
        name: 'In Progress',
        value: 'in_progress',
    },
    {
        name: 'Analysing',
        value: 'analysing',
    },
    {
        name: 'Analysed',
        value: 'analysed',
    },
];
export const statusOptions = [
    {
        name: 'not responded',
        value: false,
    },
    {
        name: 'Responded',
        value: true,
    },
];

export const vendorRiskLevels = [
    {
        id: 1,
        name: 'Low',
        notes: 'Vendors who do not have access to customer data; and Vendors whose loss of services would not be disruptive to your organization.',
    },
    {
        id: 2,
        name: 'Medium',
        notes: 'Vendors whose access to your customer information is limited; and/or Vendors whose loss of services would be disruptive to your organization.',
    },
    {
        id: 3,
        name: 'High',
        notes: 'Vendors whose access to your customer data and have a high risk of information loss; and/or Upon whom your organization is highly dependent operationally.',
    },
    {
        id: 4,
        name: 'Critical!',
        notes: 'Vendors who are critical to your organization’s operations. Their failure or inability to deliver outsourced services could result in your organization’s failure.',
    },
];

export const merchantQuestionnaire = [
    {
        name: 'Vendor paystack-questionnaire.xls',
        status: 'completed',
        date: '2022-10-17T10:06:11.042651+01:00',
        percentage: 100,
        id: 1,
    },
    {
        name: 'Vendor flutterwa-questionnaire.xls',
        status: 'inprogress',
        date: '2022-10-17T10:06:11.042651+01:00',
        percentage: 40,
        id: 2,
    },
];

export const questions = [
    {
        id: 1,
        title: 'Security Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: [
                    'Year 1984, during the war',
                    'Year 1984, during the war',
                    'Year 1984, during the war',
                    'Year 1984, during the war',
                ],
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
        ],
    },
    {
        id: 2,
        title: 'Testing Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
        ],
    },
    {
        id: 3,
        title: 'NYEs!! Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
        ],
    },
    {
        id: 4,
        title: 'NYEs!! Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
            },
        ],
    },
    //   {
    //     id: 5,
    //     title: "NYEs!! Compliance and Awareness",
    //   },
    //   {
    //     id: 6,
    //     title: "NYEs!! Compliance and Awareness",
    //   },
];

export const questions2 = [
    {
        id: 1,
        title: 'Security Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the Nothern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the East part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the South part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the Western part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
        ],
    },
    {
        id: 2,
        title: 'Testing Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
        ],
    },
    {
        id: 3,
        title: 'NYEs!! Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
        ],
    },
    {
        id: 4,
        title: 'NYEs!! Compliance and Awareness',
        form_fields: [
            {
                id: 1,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 2,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 3,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
            {
                id: 4,
                name: 'In what year was john doe made to be john doe before reincarnation of the southern part?',
                answers: ['Year 1984,', 'Year 1984,', 'Year 1984,', 'Year 1984,'],
            },
        ],
    },
    //   {
    //     id: 5,
    //     title: "NYEs!! Compliance and Awareness",
    //   },
    //   {
    //     id: 6,
    //     title: "NYEs!! Compliance and Awareness",
    //   },
];

export const data = {
    questions: {
        category_one: {
            0: 'question 1',
            1: 'question 2',
        },
        category_two: {
            2: 'question 1',
            3: 'question 2',
        },
    },
    responses: {
        0: ['response', 'response2'],
        1: ['response1', 'response2'],
        2: ['response', 'response2'],
        3: ['response1', 'response2'],
    },
};

export const data2 = {
    Question: {
        0: 'General security',
        1: 'No',
        2: 'Yes',
        3: 'documentation',
        4: 'No',
        5: "Is a background check required for all employees accessing and handling the organization's data?",
        6: 'No',
        7: 'Does the organization have a written password policy that details the required structure of passwords?',
        8: 'Yes',
        9: 'Do all employees and external party users returned all of the organizational assets in their possession',
        10: 'Yes',
        11: 'Does the organization have an individual with enterprise-wide information security responsibility and authority written in their job description',
        12: 'Yes',
        13: 'Do all staff have access to relevant Information Security Policies and are they willing to comply with the policies as well as the data protection guidelines?',
        14: 'No',
        15: 'Has the organization implemented an IT Governance, Service Management and Security framework/standards such as COBIT, ISO 20000, ISO 27001, PCIDSS, ISO 22301?',
        16: 'Yes',
        17: 'No',
        18: 'Yes',
        19: 'documentation',
        20: 'No',
        21: "YES, Type 'DONE' and continue to the next question,YesDONE",
        22: 'DONE',
        23: 'Does the organization have written information security policies?',
        24: 'Yes',
        25: '   If yes, please provide copies when responding to this assessment',
        26: 'Yes',
        27: 'Does the organization have a formal change control process for IT changes?',
        28: 'Yes',
        29: 'Do all staff receive information security awareness training on regular basis?',
        30: 'Yes',
        31: 'Do you have a member of your organization with dedicated information security function?',
        32: 'Not Applicable',
    },
    Response: {
        0: ['Not Applicable'],
        1: ['Yes'],
        2: ['Yes'],
        3: ['No'],
        4: ['Yes'],
        5: ['No'],
        6: ['Yes'],
        7: ['Yes'],
        8: ['Yes'],
        9: ['Yes'],
        10: ['Yes'],
        11: ['Yes'],
        12: ['Yes'],
        13: ['No'],
        14: ['Yes'],
        15: ['Yes'],
        16: ['Yes'],
        17: ['Yes'],
        18: ['Yes'],
        19: ['No'],
        20: ['Yes'],
        21: ['DONE'],
        22: ['Yes'],
        23: ['Yes'],
        24: ['Yes'],
        25: ['Yes'],
        26: ['Yes'],
        27: ['Yes'],
        28: ['Yes'],
        29: ['Yes'],
        30: ['Yes'],
        31: ['Not Applicable'],
        32: ['Yes'],
    },
    Confidence: {
        0: [46.9313430786],
        1: [17.0750522614],
        2: [24.2977237701],
        3: [51.5108184814],
        4: [17.0750522614],
        5: [77.1230392456],
        6: [17.0750522614],
        7: [51.9154663086],
        8: [24.2977237701],
        9: [89.2788314819],
        10: [24.2977237701],
        11: [92.4680862427],
        12: [24.2977237701],
        13: [75.7495117188],
        14: [17.0750522614],
        15: [61.3707046509],
        16: [24.2977237701],
        17: [17.0750522614],
        18: [24.2977237701],
        19: [51.5108184814],
        20: [17.0750522614],
        21: [33.3729248047],
        22: [14.6429834366],
        23: [88.2722167969],
        24: [24.2977237701],
        25: [48.749835968],
        26: [24.2977237701],
        27: [59.930305481],
        28: [24.2977237701],
        29: [89.4371261597],
        30: [24.2977237701],
        31: [68.1260757446],
        32: [29.0224170685],
    },
    Similar_Question: {
        0: [
            'Does the organization have a designated person responsible for developing and maintaining system security policies?',
        ],
        1: ['Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer'],
        2: ['Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer'],
        3: [
            'Can you provide examples or documentation of how the standards of conduct are communicated, acknowledged, and enforced?',
        ],
        4: ['Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer'],
        5: ['Are background checks performed on all employees prior to employment?'],
        6: ['Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer'],
        7: ['Does the organization have an information security policy that has been approved by management?'],
        8: ['Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer'],
        9: [
            'Do all employees and external party users returned all of the organizational assets in their possession upon termination of their employment, contract or agreement.',
        ],
        10: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        11: [
            'Does the organization have an individual with enterprise-wide information security responsibility and authority written in their job description, or equivalent? (Is there a dedicated, established role  (e.g., CIO, CISO, CSO, or other) for information security across the organization?)',
        ],
        12: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        13: ['Does your organization have an information security policy that has been approved by management?'],
        14: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        15: ['Does the organization have an information security policy that has been approved by management?'],
        16: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        17: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        18: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        19: [
            'Can you provide examples or documentation of how the standards of conduct are communicated, acknowledged, and enforced?',
        ],
        20: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        21: [
            "Are data-retention and disposal policies, procedures, and processes implemented?;If YES, Type 'DONE' and continue to the next questions to give more details",
        ],
        22: [
            'Did you process the personal data of:  (Article 4.1(6) and (7) );Did you process the personal data of  more than 1,000 (one thousand) Data Subjects in the last 6 months;',
        ],
        23: ['Does the organization have an information security policy that has been approved by management?'],
        24: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        25: ['Are the procedures and facilities regularly tested with results recorded?'],
        26: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        27: [
            'Does your organization have a process for revoking system access when there is a position change or when responsibilities change?',
        ],
        28: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        29: [
            'Do all individuals interacting with the organization information system receive information security awareness training?',
        ],
        30: [
            'Are automatic updates and periodic scans enabled and being performed?;Please pick the appropriate answer',
        ],
        31: [
            'Does the organization have a designated person responsible for developing and maintaining system security policies?',
        ],
        32: ['Is there any exclusion from scope?'],
    },
};

export const amlOptions = [
    {
        name: 'AML Vendor Questionnaire',
        value: true,
    },
    {
        name: 'Non-AML Vendor Questionnaire',
        value: false,
    },
];

import React, { useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import Play from 'assets/img/StartVideo.svg';
import AppButton from 'components/new_components/AppButton';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { formatDateObjectHandler } from 'utils';
import PlayIcon from 'assets/img/PlayVideo.svg';
import VideoModal from '../VideoModal';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import { GetDownloadLinkUnauthorized } from 'store/actions/generalActions';
import OnboardInfo from '../../components/OnboardInfo';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { formatAcknowledgementTimestamp } from '../../utils';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

const SecurityAwarenessTraining = (props) => {
    const {
        loading,
        check,
        id,
        handleVideoFinish,
        increaseOnboardingPolicy,
        acknowledgeSinglePolicy,
        videos,
        handlePrev,
        one_merchant_person,
    } = props;
    const [viewLink, setViewLink] = useState({ id: null, loading: false, activeLink: '' });
    const theme = useTheme();
    const [watched, setWatched] = useState(['']);

    const [acknowledgeLink, setAcknowledgeLink] = useState({ id: null, loading: false });
    const getDownloadLink = async (id, callback) => {
        if (id && !viewLink.loading) {
            setViewLink({ ...viewLink, id, loading: true });
            const res = await GetDownloadLinkUnauthorized('compliance', 'onboarding', id)();
            setViewLink({ ...viewLink, id, loading: false });
            if (res?.success) {
                setViewLink({ id, loading: false, activeLink: res.data });
                // open video
                callback();
            } else {
                toast.error('Something went wrong!');
            }
        }
        setWatched((prev) => [...prev, id]);
    };

    const [openVideo, setOpenVideo] = useState({});

    const openVideoModal = (index, videoId) => {
        getDownloadLink(videoId, () => setOpenVideo({ [index]: true }));
    };
    const closeVideoModal = (index) => {
        setOpenVideo({ [index]: false });
        setViewLink({ id: null, loading: false, activeLink: '' });
    };

    const { t } = useTranslation('onboardingPolicy');

    const handleAcknowledgeSingle = async (id) => {
        setAcknowledgeLink({
            id,
            loading: true,
        });
        console.log(check, 'check', watched);
        console.log(one_merchant_person, 'check one merchant');

        await acknowledgeSinglePolicy({
            security_videos_watched: {
                ...one_merchant_person?.security_videos_watched,
                [id]: formatAcknowledgementTimestamp(Date.now()),
            },
        });
        setAcknowledgeLink({
            id: null,
            loading: false,
        });
    };

    const acknowledged = useMemo(() => {
        return Object.keys(one_merchant_person?.security_videos_watched || {});
    }, [one_merchant_person?.security_videos_watched]);

    const isCompleted = useMemo(() => {
        return (
            videos?.filter((video) => !video?.is_deactivated)?.length ==
            Object.keys(one_merchant_person?.security_videos_watched || {})?.length
        );
    }, [one_merchant_person?.security_videos_watched, videos]);

    return (
        <div className="w-full flex justify-center pt-10 h-fit">
            <Grid
                item
                md={7}
                // lg={8}
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: '2px',
                }}
            >
                <Stack>
                    <Box
                        sx={{
                            pt: 3,
                            px: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: '18px',
                            }}
                        >
                            {t('steps.step2')}
                        </Typography>
                        <OnboardInfo info={t(`info`)} />
                        {videos?.find((video) => !video?.is_deactivated) ? (
                            videos
                                ?.filter((video) => !video?.is_deactivated)
                                ?.map((video, index) => {
                                    let isAcknowledged = acknowledged?.includes(String(video?.id));
                                    return (
                                        <React.Fragment key={video?.id}>
                                            <Card
                                                key={video?.id}
                                                sx={{
                                                    px: 2,
                                                    py: 1.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    boxShadow: 'none',
                                                    borderBottom: '1px solid #F1F5F9',
                                                    height: 'fit-content',
                                                    justifyContent: 'space-between',
                                                    flexWrap: {
                                                        xs: 'wrap',
                                                        md: 'nowrap',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 3,
                                                    }}
                                                >
                                                    <img src={PlayIcon} alt="Play Icon" />

                                                    <Stack>
                                                        <Typography
                                                            sx={{
                                                                color: '#395BA9',
                                                                fontSize: '15px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {video?.name}
                                                        </Typography>

                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: '#94A3B8',
                                                                        fontSize: '13px',
                                                                        fontWeight: 400,
                                                                    }}
                                                                >
                                                                    {formatDateObjectHandler(
                                                                        video?.date_created,
                                                                        'DD-MM-YYYY'
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                </Box>

                                                <div className="flex items-center w-full md:w-fit justify-end gap-2">
                                                    <AppButton
                                                        icon={
                                                            <Stack
                                                                flexDirection="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                {viewLink.loading && viewLink.id === video.id && (
                                                                    <LoadingState size={13} styles={{ mr: 1.5 }} />
                                                                )}
                                                                <img src={Play} alt="play" />
                                                            </Stack>
                                                        }
                                                        name="Play Video"
                                                        variant="contained"
                                                        color="white"
                                                        onClick={() => openVideoModal(index, video?.id)}
                                                        sx={{
                                                            height: 'fit-content',
                                                            border: '1px solid #E2E8F0',
                                                            borderRadius: '4px',
                                                            py: 0.25,
                                                            px: 1,
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: '500',
                                                            bacgroundColor: '#ffffff',
                                                            color: video?.acknowledge ? '#55BE8B' : '#64748B',
                                                            boxShadow: '0 0 0 0 !important',

                                                            '&.Mui-disabled': {
                                                                color: '#CBD5E1',
                                                                background: '#F8FAFC',
                                                            },
                                                        }}
                                                    />
                                                    <LoadingButton
                                                        name="Acknowledge"
                                                        variant="contained"
                                                        color="white"
                                                        target="_blank"
                                                        disabled={
                                                            isAcknowledged
                                                                ? false
                                                                : !watched.includes(video?.id) ||
                                                                  acknowledgeLink.loading
                                                        }
                                                        loading={
                                                            acknowledgeLink.id === video?.id && acknowledgeLink.loading
                                                        }
                                                        rel="noreferrer"
                                                        sx={{
                                                            height: 'fit-content',
                                                            border: '1px solid #E2E8F0',
                                                            borderRadius: '4px',
                                                            py: 0.25,
                                                            px: 1,
                                                            fontWeight: '500',
                                                            backgroundColor: '#ffffff',
                                                            textTransform: 'capitalize',
                                                            color: isAcknowledged ? '#55BE8B' : '#64748B',
                                                            boxShadow: '0 0 0 0 !important',

                                                            '&.Mui-disabled': {
                                                                color: '#CBD5E1',
                                                                background: '#F8FAFC',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            isAcknowledged ? null : handleAcknowledgeSingle(video.id)
                                                        }
                                                    >
                                                        Acknowledge
                                                    </LoadingButton>
                                                </div>
                                            </Card>
                                            <VideoModal
                                                open={openVideo?.[index]}
                                                handleClose={() => closeVideoModal(index)}
                                                videos={videos}
                                                videoIndex={index}
                                                handleAcknowledgeSingle={handleAcknowledgeSingle}
                                                decodedLink={viewLink.activeLink}
                                            />
                                        </React.Fragment>
                                    );
                                })
                        ) : (
                            <EmptyComponent text="No policy document have been uploaded yet. Check again later." />
                        )}
                    </Box>

                    <div className="flex gap-4 items-center my-4">
                        <AppButton
                            name="Previous"
                            variant="outlined"
                            onClick={handlePrev}
                            loading={loading}
                            sx={{
                                width: 'fit-content',
                                height: '48px',
                                ml: 3,
                                px: '12px',
                                borderRadius: '8px',
                                background: '#F1F5F9!important',
                                borderColor: '#E2E8F0!important',
                                color: '#334155!important',
                                boxShadow: 'none',
                            }}
                        />
                        {check || id?.toString()?.length <= 4 || !isCompleted ? (
                            <AppButton
                                name="Next"
                                color="primary"
                                variant="contained"
                                onClick={increaseOnboardingPolicy}
                                loading={loading}
                                sx={{
                                    width: 'fit-content',
                                    height: '48px',
                                }}
                            />
                        ) : (
                            <AppLoadingButton
                                text="Save & Continue"
                                color="primary"
                                variant="contained"
                                onClick={() => handleVideoFinish({ training: true })}
                                loading={loading}
                                disabled={check || id?.toString()?.length <= 4}
                                sx={{
                                    width: 'fit-content',
                                    height: '48px',
                                    mb: '20px',
                                    mt: 3,
                                }}
                            />
                        )}
                    </div>
                </Stack>
            </Grid>
        </div>
    );
};

export default SecurityAwarenessTraining;

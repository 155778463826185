import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const TableEndDivider = ({ count = null, isLast = false }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flex: 1,
            }}
        >
            {count && (
                <Typography
                    component="small"
                    sx={{
                        color: theme.palette.gray[900],

                        fontSize: '.8rem',
                    }}
                >
                    {count}
                </Typography>
            )}
            <Box
                sx={{
                    flex: 1,
                    borderTop: '1px solid',
                    borderColor: theme.palette.gray[100],
                }}
            />
            {isLast && (
                <Typography
                    component="small"
                    sx={{
                        color: theme.palette.gray[400],
                        textTransform: 'uppercase',
                        pl: 2,
                        fontSize: '.8rem',
                    }}
                >
                    End of list
                </Typography>
            )}
        </Box>
    );
};

export default TableEndDivider;

import React, { useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import AppInput from 'components/new_components/AppInput';
import LightBulb from '../../../../../assets/img/icons/light-bulb.svg';
import { LoadingButton } from '@mui/lab';

const NINModal = (props) => {
    const { handleClose, open, handleBackgroundCheck, loading } = props;
    // const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null);
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState(null);

    const onClose = () => {
        handleClose();
    };

    const { t } = useTranslation('onboardingPolicy');

    const NINValidate = (val) => {
        if (!val) {
            setErrors('NIN is required');
            return false;
        } else if (!/^\d{11}$/.test(val)) {
            setErrors('NIN must be exactly 11 digits');
            return false;
        }

        setErrors(null);
        return true;
    };

    const handleChange = (e) => {
        setId(e.target.value);
        setTouched(true);
        NINValidate(e.target.value);
    };

    const validateId = async () => {
        if (NINValidate(id)) {
            await handleBackgroundCheck({ identification_number: id, background_checktype: 'nin' });
        }
    };
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {t('backgroundCheck.nin')}
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 15, height: 15 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: 'fit-content',
                        pt: '0rem',
                        pb: '1rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        {t('backgroundCheck.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={validateId}
                        loading={loading}
                    >
                        {t('backgroundCheck.verify')}
                    </LoadingButton>
                </Box>
            }
            width="511px"
            height="fit-content"
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        backgroundColor: '#F8FAFC',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '400',
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'start',
                        border: '1px solid #F0F0FA',
                        borderRadius: '4px',
                    }}
                >
                    <img src={LightBulb} alt="light-bulb" style={{ marginRight: '10px' }} />
                    <ul
                        style={{
                            fontSize: '13px',
                            fontWeight: 400,
                            listStyleType: 'disc',
                            marginLeft: '1rem',
                        }}
                    >
                        <li>
                            {
                                'On your mobile device, dial *346# and then follow the instructions on how to get your NIN Number'
                            }
                        </li>
                        <li> Only enter the NIN number on your printed NIN slip / National ID card</li>
                    </ul>
                </Typography>

                <AppInput
                    label="NIN"
                    placeholder="Enter NIN"
                    onChange={handleChange}
                    name="identification_number"
                    id="identification_number"
                    error={errors}
                />
                {touched && errors && <FormHelperText error>{errors}</FormHelperText>}
            </Box>
        </AppCenteredModalNew>
    );
};
export default NINModal;

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loaders/CircularLoader';
import {
    GetQuestionnaireForms,
    GetVendorResponses,
    GetAllResponses,
    GetQuestionnaireHistory,
} from 'store/actions/vendorActions';

import VendorResponseView from './VendorResponseView';
import useSearchParams from 'hooks/useSearchParams';

const VendorResponseInformation = (props) => {
    const {
        readonly,
        merchant,
        vendor,
        page,
        setPage,
        all_responses,
        GetQuestionnaireForms,
        GetVendorResponses,
        questionnaire_forms,
        vendor_responses,
        activeTab,
        setActiveTab,
        handleChangeView,
        goBack,
        questionnaireData,
        GetQuestionnaireHistory,
    } = props;

    const query = useSearchParams();

    const questionnaireValue = query.getParam('questionnaire') || '';

    const [loading, setLoading] = useState({
        content: false,
        form: false,
    });

    // Find the first responded questionnaire
    const findFirstRespondedQuestionnaire = useCallback(() => {
        if (!questionnaireData || !Array.isArray(questionnaireData)) return null;

        const firstResponded = questionnaireData.find((item) => item.has_responded === true);
        return firstResponded?.id || null;
    }, [questionnaireData]);

    // Handle questionnaire change
    const handleQuestionnaireChange = useCallback(
        (e) => {
            const newValue = e.target.value;
            query.setParam('questionnaire', newValue);
        },
        [query]
    );

    // Fetch questionnaire history
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading((loading) => ({ ...loading, content: true }));
                const res = await GetQuestionnaireHistory(vendor, { has_responded: true });

                if (!res.success) {
                    throw new Error(res.message || 'Failed to fetch questionnaire history');
                }
            } catch (error) {
                toast.error(error.message || 'An error occurred while fetching data');
            } finally {
                setLoading((loading) => ({ ...loading, content: false }));
            }
        };

        if (vendor) {
            fetchData();
        }
    }, [vendor, GetQuestionnaireHistory]);

    // Set default questionnaire if none is selected
    useEffect(() => {
        if (!questionnaireValue && questionnaireData) {
            const firstRespondedId = findFirstRespondedQuestionnaire();
            if (firstRespondedId) {
                query.state('questionnaire', firstRespondedId);
            }
        }
    }, [questionnaireValue, questionnaireData, findFirstRespondedQuestionnaire, query]);

    const getQuestionnaire = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetQuestionnaireForms();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getVendorResponse = async (id) => {
        if (!id) return;

        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetVendorResponses(vendor, {
            vendor_questionnaire: id,
        });
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getQuestionnaire();
    }, []);

    useEffect(() => {
        if (questionnaireValue) {
            getVendorResponse(questionnaireValue);
        }
    }, [questionnaireValue]);

    return (
        <div>
            {!loading.content ? (
                <VendorResponseView
                    all_responses={all_responses}
                    questionnaire_forms={questionnaire_forms}
                    vendor_responses={vendor_responses}
                    readonly={readonly}
                    vendor={vendor}
                    merchant={merchant}
                    setPage={setPage}
                    page={page}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    loading={loading.content}
                    handleChangeView={handleChangeView}
                    goBack={goBack}
                    questionnaireData={questionnaireData}
                    questionnaire={questionnaireValue}
                    handleChange={handleQuestionnaireChange}
                />
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularLoader />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
        questionnaireData: state?.vendorReducers?.questionnaire_history,
        vendor_responses: state?.vendorReducers?.vendor_responses,
        all_responses: state?.vendorReducers?.all_responses,
    };
};

export default connect(mapStateToProps, {
    GetQuestionnaireForms,
    GetVendorResponses,
    GetAllResponses,
    GetQuestionnaireHistory,
})(VendorResponseInformation);

import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import AppInput from 'components/new_components/AppInput';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetCertificateValidity } from 'store/actions/auditActions';

const CertificateCountdown = (props) => {
    const { GetCertificateValidity } = props;
    const [barcode_number, setBarcode_number] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const body = {
            barcode_number,
        };
        const response = await GetCertificateValidity(body);
        setLoading(false);
        if (response?.success) {
            toast.success('Successful');
        } else {
            toast.error(response?.message);
        }
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <AppInput
                        label="Barcode Number"
                        value={barcode_number}
                        placeholder="Enter barcode Number"
                        onChange={(event) => setBarcode_number(event.target.value)}
                    />
                    <AppLoadingButton
                        loading={loading}
                        loadingPosition="center"
                        text="submit"
                        variant="contained"
                        http
                        sx={{ ml: 2, mt: 5 }}
                        onClick={handleSubmit}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        valid_certificate: state?.auditReducers?.valid_certificate,
    };
};
export default connect(mapStateToProps, {
    GetCertificateValidity,
})(CertificateCountdown);

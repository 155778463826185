import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
const Steps = (props) => {
    const { onboardingPolicy, setOnboardingPolicy, check } = props;
    const { t } = useTranslation('onboardingPolicy');

    // Helper function to determine the status of a step
    const getStepStatus = (step) => {
        const isCurrent = onboardingPolicy === step;
        const isCompleted =
            (step === 1 && check?.policy) ||
            (step === 2 && check?.security_check) ||
            (step === 3 && check?.background_check);

        if (isCurrent && isCompleted) return 'Current & Completed';
        if (isCurrent) return 'Current';
        if (isCompleted) return 'Completed';
        return 'Pending';
    };

    // Helper function to render the status icon
    const renderStatusIcon = (status) => {
        if (status.includes('Completed')) return <DoneIcon sx={{ color: '#55BE8B' }} />;
        if (status === 'Current') return <ErrorOutline color="#77777A" fontSize="small" />;
        return <ErrorOutline sx={{ color: '#77777A' }} fontSize="small" />;
    };

    return (
        <Grid
            container
            justifyContent="center"
            sx={{ width: '100%', backgroundColor: '#fff', overflow: 'auto', height: 'fit-content' }}
        >
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        lg: '70%',
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {[1, 2, 3].map((step) => {
                    const status = getStepStatus(step);
                    const isActive = onboardingPolicy === step;

                    return (
                        <Box
                            key={step}
                            onClick={() => setOnboardingPolicy(step)}
                            sx={{
                                cursor: 'pointer',
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderRight: '1px solid #E2E8F0',
                                borderLeft: step == 1 && '1px solid #E2E8F0',
                                borderBottom: isActive ? '3px solid #202D66' : '1px solid #E2E8F0',
                                py: 1,
                                px: 2,
                                backgroundColor: 'transparent',
                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: '10rem',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 400,
                                            color: status?.match(/Current/gi) ? '#55BE8B' : '#4A5568',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {status?.replace('& Completed', '')}
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, fontSize: '15px', color: '#4A5568', mt: 1 }}
                                >
                                    {step}.{' '}
                                    {step === 1
                                        ? t('steps.step1')
                                        : step === 2
                                        ? t('steps.step2')
                                        : step === 3
                                        ? t('steps.step3')
                                        : ''}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>{renderStatusIcon(status)}</Box>
                        </Box>
                    );
                })}
            </Box>
        </Grid>
    );
};

export default Steps;

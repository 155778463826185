import React, { useMemo, useState } from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import AppButton from 'components/new_components/AppButton';
import IdIcon from 'assets/img/IdVerification.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OnboardInfo from '../../components/OnboardInfo';
import { useTranslation } from 'react-i18next';
import NINModal from './NINModal';
import NationalIdModal from './NationalIdModal';
import VotersIdModal from './VotersIdModal';
import PassportModal from './PassportModal';
import UgandaIdModal from './UgandaIdModal';
import { capitalizeFirstLetter } from '../../utils';

// Background check types
/* nin",
"nin_slip",
"bvn",
"passport"
"driver_license"
"national_id"
"voter_id" */

const IdentityVerification = (props) => {
    const { check, id, handleBackgroundCheck, loading, match, one_merchant_person, handlePrev, handleNext } = props;
    /*   const [loadingLink, setLoadingLink] = useState({ id: null, loading: false }); */
    const [modal, setModal] = useState(null);

    const { t } = useTranslation('onboardingPolicy');

    const showModal = (val) => {
        setModal(val);
    };

    const closeModal = () => {
        setModal(null);
    };

    // Background check options for different countries
    const allowedDocuments = useMemo(
        () =>
            one_merchant_person?.country == 'nigeria'
                ? [
                      {
                          id: 'nin',
                          title: 'NIN',
                          description: 'Verify with your NIN',
                          info: ['Make sure the ID Number is typed in correctly', 'Do not use spacebar'],
                      },
                      {
                          id: 'passport',
                          title: 'Passport',
                          description: 'Background check using international passport',
                          info: ['Make sure the ID Number is typed in correctly', 'Do not use spacebar'],
                      },
                  ]
                : one_merchant_person?.country == 'ghana'
                ? [
                      {
                          id: 'vin',
                          title: 'Voters ID',
                          description: 'Verify with your Ghanian Voters’ Card No.',
                          info: ['Make sure the ID Number is typed in correctly', 'Do not use spacebar'],
                      },
                  ]
                : ['rwanda', 'kenya']?.includes(one_merchant_person?.country)
                ? [
                      {
                          id: 'national_id',
                          title: 'National ID',
                          description: `Verify with your ${capitalizeFirstLetter(
                              one_merchant_person?.country
                          )}n national ID Card No.`,
                          info: ['Make sure the ID Number is typed in correctly', 'Do not use spacebar'],
                      },
                  ]
                : one_merchant_person?.country == 'uganda'
                ? [
                      {
                          id: 'uganda_id',
                          title: 'National ID',
                          description: 'Verify with your Ugandan national ID Card No.',
                          info: ['Make sure the ID Number is typed in correctly', 'Do not use spacebar'],
                      },
                  ]
                : [],
        [one_merchant_person?.country]
    );
    return (
        <div className="w-full flex justify-center py-10 h-fit">
            <Grid
                item
                md={7}
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: '2px',
                }}
            >
                <Stack>
                    <Box
                        sx={{
                            pt: 3,
                            px: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: '#202D66',
                                fontWeight: 700,
                                fontSize: '18px',
                            }}
                        >
                            {t('steps.step3')}
                        </Typography>
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: '#64748B',
                                fontWeight: 400,
                                fontSize: '12px',
                            }}
                        >
                            {t('steps.step3Subtitle')}
                        </Typography>
                        <br />

                        {
                            allowedDocuments?.map((doc) => {
                                return (
                                    <React.Fragment key={doc?.id}>
                                        <Card
                                            key={doc?.id}
                                            onClick={() => showModal(doc?.id)}
                                            sx={{
                                                px: 2,
                                                py: 1.5,
                                                display: 'flex',
                                                alignItems: 'center',
                                                boxShadow: 'none',
                                                borderBottom: '1px solid #F1F5F9',
                                                height: 'fit-content',
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: 3,
                                                }}
                                            >
                                                <img src={IdIcon} alt="File Icon" />

                                                <Stack
                                                    sx={{
                                                        height: 'fit-content',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#395BA9',
                                                            fontSize: '15px',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {doc?.title}
                                                    </Typography>

                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography
                                                                sx={{
                                                                    color: '#94A3B8',
                                                                    fontSize: '13px',
                                                                    fontWeight: 400,
                                                                }}
                                                            >
                                                                {doc?.description}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            </Box>

                                            <div className="flex gap-1 items-center">
                                                <ArrowForwardIcon />
                                            </div>
                                        </Card>
                                    </React.Fragment>
                                );
                            })
                            /* ) : (
                            <EmptyComponent text="No policy document have been uploaded yet. Check again later." />
                        ) */
                        }

                        <OnboardInfo
                            info={
                                <ul
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        listStyleType: 'disc',
                                        marginLeft: '1rem',
                                        my: 5,
                                    }}
                                >
                                    <li>Make sure the ID Number is typed in correctly </li>
                                    <li>Do not use spacebar</li>
                                </ul>
                            }
                        />
                    </Box>

                    <div className="flex gap-4">
                        <AppButton
                            name="Previous"
                            variant="outlined"
                            onClick={handlePrev}
                            loading={loading}
                            disabled={check || id?.toString()?.length <= 4}
                            sx={{
                                width: 'fit-content',
                                height: '48px',
                                mb: '20px',
                                ml: 3,
                                px: '12px',
                                borderRadius: '8px',
                                background: '#F1F5F9',
                                borderColor: '#E2E8F0',
                                color: '#334155',
                                boxShadow: 'none',
                            }}
                        />
                        {one_merchant_person?.policy &&
                            one_merchant_person?.training &&
                            one_merchant_person?.background_check && (
                                <AppButton
                                    name="Finalize"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleNext}
                                    loading={loading}
                                    disabled={check || id?.toString()?.length <= 4}
                                    sx={{
                                        width: 'fit-content',
                                        height: '48px',
                                        mb: '20px',
                                    }}
                                />
                            )}
                    </div>
                </Stack>
            </Grid>

            {/* Modal for NIN verification --Nigeria*/}
            <NINModal
                handleBackgroundCheck={handleBackgroundCheck}
                open={modal === 'nin'}
                handleClose={closeModal}
                id={id}
                loading={loading}
                match={match}
            />

            {/* Modal for VIN verification --ghana*/}
            <VotersIdModal
                handleBackgroundCheck={handleBackgroundCheck}
                open={modal === 'vin'}
                handleClose={closeModal}
                id={id}
                loading={loading}
                match={match}
            />

            {/* Modal for National Id verification --kenya, rewanda */}
            <NationalIdModal
                handleBackgroundCheck={handleBackgroundCheck}
                open={modal === 'national_id'}
                handleClose={closeModal}
                id={id}
                loading={loading}
                match={match}
            />

            {/* Modal for National Id verification --Uganda */}
            <UgandaIdModal
                handleBackgroundCheck={handleBackgroundCheck}
                open={modal === 'uganda_id'}
                handleClose={closeModal}
                id={id}
                loading={loading}
                match={match}
            />

            {/* Drawer for passport verification --Nigeria */}
            <PassportModal
                handleBackgroundCheck={handleBackgroundCheck}
                // open={true}
                open={modal === 'passport'}
                handleClose={closeModal}
                id={id}
                loading={loading}
                match={match}
            />
        </div>
    );
};

export default IdentityVerification;

import React, { useState, useEffect } from 'react';

// mui components

// core component
import VendorResponseInformation from './VendorReadOnly/VendorResponseInformation';

// redux
import { connect } from 'react-redux';
import { GetOneVendor, GetAllResponses } from 'store/actions/vendorActions';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CircularLoader from 'components/Loaders/CircularLoader';

const ReadOnlyVendor = (props) => {
    // props
    const { /* GetAllResponses, */ vendorResponses, open, handleClose, merchant, goBack } = props;
    const { id } = useParams();

    //state
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(1);

    //functions
    const getOneVendor = async () => {
        setLoading(true);
        const res = await GetOneVendor();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    /* 
    const getAllResponses = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetAllResponses();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
            }
            }; */

    useEffect(() => {
        getOneVendor();
    }, []);
    useEffect(() => {
        if (vendorResponses) {
            // console.log({ vendorResponse, vendor });
            // setActiveTab(vendorResponse?.[0]?.id);
            setLoading(() => false);
            setActiveTab(vendorResponses?.sort((a, b) => a?.id - b?.id)?.[0]?.id);
        }
    }, [vendorResponses]);

    return loading ? (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularLoader />
        </div>
    ) : (
        <VendorResponseInformation
            merchant={merchant}
            page={page}
            setPage={setPage}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            open={open}
            vendor={id}
            handleClose={handleClose}
            goBack={goBack}
        />
    );
};
const mapStateToProps = (state) => {
    return {
        ...state,
        one_vendor: state?.vendorReducers?.one_vendor,
        all_responses: state?.vendorReducers?.all_responses,
        vendorResponses: state?.vendorReducers?.vendor_responses,
        merchant: state?.generalReducers?.user_info?.organization?.id,
    };
};
export default connect(mapStateToProps, { GetOneVendor, GetAllResponses })(ReadOnlyVendor);

export const AUDIT_STATUS = {
    DRAFT: 'draft',
    IN_REVIEW: 'inreview',
    AUDITING: 'auditing',
    DISAPPROVED: 'disapproved',
    COMPLETE: 'completed',
};

// returns 4.0 or 4.0.1 depending on the date created
export function getPcidss4Version(dateCreated) {
    const cutoffDate = new Date('2025-01-01T00:00:00Z');
    const createdDate = new Date(dateCreated);
    return createdDate < cutoffDate ? '4.0' : '4.0.1';
}

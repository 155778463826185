import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetVendorResponses } from 'store/actions/vendorActions';
import SquareIcon from '@mui/icons-material/Square';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppTag from 'components/new_components/AppTags';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import AppDonutChart from 'components/new_components/AppDonutChart';
import { Row } from 'antd';
import CircularLoader from 'components/Loaders/CircularLoader';
import NoQuestionnaire from '../../../../../assets/img/icons/No-questionnaire-history.svg';
import NoScorecard from '../../../../../assets/img/icons/No-score-card.svg';
import { formatQuestionnaireName } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import AppDonutChart from 'components/new_components/AppDonutChart';

const VendorScorecard = memo((props) => {
    const theme = useTheme();
    const { GetVendorResponses, details } = props;
    const { t } = useTranslation('vendorManagement');

    const [active, setActive] = useState({ id: null });
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState([]);

    const getVendorRepsonses = async (id) => {
        try {
            setLoading(true);
            if (!details?.id || !id) {
                toast.error('Invalid vendor or questionnaire ID');
                return;
            }
            const res = await GetVendorResponses(details.id, { vendor_questionnaire: id });

            if (!res.success) {
                throw new Error(res.message || 'Failed to fetch vendor responses');
            }
            setResponses(res?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.message || 'Failed to fetch vendor responses');
        }
    };

    useEffect(() => {
        if (details?.id && active?.id) {
            getVendorRepsonses(active.id);
        }
    }, [details?.id, active?.id]);

    useEffect(() => {
        if (details?.questionnaire_history?.length && Array.isArray(details?.questionnaire_history)) {
            const defQuestionnaire = details?.questionnaire_history[0];
            setActive(defQuestionnaire);
            setId(defQuestionnaire?.id);
        }
    }, [details?.questionnaire_history]);

    const bgColor = '#E2E8F0';

    const values = useMemo(
        () => [
            [
                active?.overall_score_percent,
                active?.overall_score_percent < 34
                    ? '#FF5449'
                    : active?.overall_score_percent > 33 && active?.overall_score_percent < 67
                    ? '#F2C021'
                    : '#55BE8B',
            ],
        ],
        [active]
    );

    if (!responses) {
        return <Typography>Loading...</Typography>;
    }

    if (loading) {
        return (
            <Row justify="center">
                <CircularLoader />
            </Row>
        );
    }

    return !id || !active || !responses.length ? (
        <div className="flex flex-col items-center gap-5 h-[25rem] justify-center pb-5">
            <img src={NoQuestionnaire} alt="No questionnaire history" />
            <Typography sx={{ color: '#64748B', fontWeight: 500, textAlign: 'center', fontFamily: 'Poppins' }}>
                No questionnaire history
            </Typography>
        </div>
    ) : (
        <>
            {loading ? (
                <Row justify="center">
                    <CircularLoader />
                </Row>
            ) : id && responses?.length ? (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 3,
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: theme.palette.neutral[500],
                                fontWeight: 500,
                                fontSize: '14px',
                                ml: 0.5,
                            }}
                        >
                            {t('currentScorecard')}
                        </Typography>

                        <Select
                            value={id || active?.id || ''}
                            onChange={(e) => {
                                const selected = details?.questionnaire_history?.find((q) => q.id === e.target.value);
                                setActive(selected || {});
                                setId(selected?.id);
                            }}
                            sx={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: 400,
                                fontFamily: 'Poppins',
                                width: '130px',
                                height: '38px',
                                borderRadius: '8px',
                                textTransform: 'capitalize',
                                backgroundColor: '#F8FAFC',
                                // border: '1px solid #E2E8F0',
                                boxShadow: 'none',
                                borderColor: '#E2E8F0',
                                '&:hover': {
                                    borderColor: '#CBD5E1',
                                    boxShadow: 'none',
                                },
                                '& .MuiSelect-select': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: 0,
                                    gap: '3px',
                                    borderColor: '#E2E8F0',
                                    textTransform: 'capitalize',
                                    pl: '5px',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            {details?.questionnaire_history?.map((q) => (
                                <MenuItem key={q.id} value={q.id} sx={{ textTransform: 'capitalize' }}>
                                    {formatQuestionnaireName(q.name)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mr: 3,
                                position: 'relative',
                            }}
                        >
                            <AppDonutChart
                                values={values}
                                bgColor={bgColor}
                                lineWidth={8}
                                height={192}
                                width={192}
                                centerContent={
                                    <Typography
                                        sx={{
                                            position: 'relative',
                                            color: '#202D66',
                                            fontWeight: 700,
                                            fontSize: '34px',
                                        }}
                                    >
                                        {active?.overall_score_percent}
                                    </Typography>
                                }
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                            <Box
                                sx={{
                                    borderRight: '1px solid',
                                    borderColor: theme.palette.grey[200],
                                    px: 3,
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <SquareIcon sx={{ color: '#FF5449', borderRadius: 3 }} />
                                    <Typography
                                        sx={{
                                            color: theme.palette.neutral[500],
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            ml: 0.5,
                                        }}
                                    >
                                        0 - 33
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.primary[40],
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        textAlign: 'center',
                                    }}
                                >
                                    High
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    borderRight: '1px solid',
                                    borderColor: theme.palette.grey[200],
                                    px: 3,
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <SquareIcon sx={{ color: '#F2C021', borderRadius: 3 }} />
                                    <Typography
                                        sx={{
                                            color: theme.palette.neutral[500],
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            ml: 0.5,
                                        }}
                                    >
                                        34 - 66
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        color: theme.palette.primary[40],
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Medium
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    px: 3,
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <SquareIcon sx={{ color: theme.palette.success[70], borderRadius: 3 }} />
                                    <Typography
                                        sx={{
                                            color: theme.palette.neutral[500],
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            ml: 0.5,
                                        }}
                                    >
                                        67 - 100
                                    </Typography>
                                </Box>

                                <Typography
                                    sx={{
                                        color: theme.palette.primary[40],
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Low
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            {responses
                                ?.sort((a, b) => a?.form_id - b?.form_id)
                                ?.map((response) => (
                                    <Box
                                        key={response?.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderTop: '2px solid #F1F5F9',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                width: '60%',
                                                py: 1,
                                            }}
                                        >
                                            {response?.form_title}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center',
                                                width: '40%',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.primary[40],
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    borderLeft: '2px solid #F1F5F9',
                                                    py: 1,
                                                    pl: 1,
                                                    width: '4rem',
                                                }}
                                            >
                                                {response?.score_percent}%
                                            </Typography>
                                            <Box
                                                sx={{
                                                    marginLeft: '3rem',
                                                    py: 1,
                                                }}
                                            >
                                                <AppTag
                                                    text={response.risk_level}
                                                    icon={
                                                        <FiberManualRecordIcon
                                                            sx={{
                                                                width: 12,
                                                                height: 12,
                                                                color:
                                                                    response.risk_level === 'low'
                                                                        ? theme.palette.success[500]
                                                                        : response.risk_level === 'medium'
                                                                        ? theme.palette.warning[900]
                                                                        : response.risk_level === 'high'
                                                                        ? theme.palette.error[500]
                                                                        : theme.palette.error[300],
                                                                mr: 0.5,
                                                            }}
                                                        />
                                                    }
                                                    noRounded
                                                    noItalic
                                                    type="default"
                                                    sx={{
                                                        padding: '4px',
                                                        borderColor: theme.palette.grey[200],
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                    <div className="flex flex-col items-center gap-5 h-full justify-center pb-5">
                        <img src={NoScorecard} alt="No score cards" loading="eager" />
                        <Typography
                            sx={{ color: '#64748B', fontWeight: 500, textAlign: 'center', fontFamily: 'Poppins' }}
                        >
                            No questionnaire score card
                        </Typography>
                    </div>
                </Box>
            )}
        </>
    );
});

const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, { GetVendorResponses })(VendorScorecard);

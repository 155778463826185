import React from 'react';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const NdprReport = ({ isPdf }) => {
    const { ndpr_ciso_summary } = useSelector((state) => state?.CISOReducers);
    const total_document =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const user_document =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const total_form =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const user_form =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    return (
        <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
            <div className="flex items-center gap-1">
                {user_document === total_document && user_form === total_form ? (
                    <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                ) : (
                    <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                )}
                <p className={`text-gray-600 text-xs font-normal ${isPdf ? '-mt-[0.8rem]' : ''}`}>NDPR</p>
            </div>
            <div className="flex items-center gap-2">
                <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                    <p>
                        {user_form}/{total_form} Questions answered
                    </p>
                </div>
                <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                    <p>
                        {user_document}/{total_document} Documents uploaded
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NdprReport;

import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

export default function FinishedOnboarding() {
    const theme = useTheme();
    return (
        <div className="flex pt-[7rem] xs:px-4 md:px-[5rem] lg:px-[10rem] relative h-[90dvh] justify-center bg-[#f8fafc] md:gap-40 overflow-hidden">
            <div className="hidden md:block w-[250px] xl:w-[394px] h-[696px] ">
                <div className="w-[250px] xl:w-[350px] h-[496px] xl:h-[496px] rounded-b-[20rem] absolute top-0  bg-[#FFC87F4D] z-0 flex items-end ">
                    <img
                        src={'/img/onboarding-policy/Onboarding-Done.svg'}
                        alt="Welcome to Smart Comply App"
                        className="w-[250px] xl:w-[350px] h-[250px] xl:h-[350px] object-contain"
                    />
                </div>
            </div>

            <Box className="w-fit pl-4 pr-4 md:pl-1s0 ">
                <Typography
                    sx={{
                        fontSize: {
                            xs: '27px',
                            md: '33px',
                            lg: '40px',
                        },
                        fontWeight: 700,
                        color: '#002C72',
                    }}
                >
                    You have successfully completed your employee onboarding
                </Typography>

                <Typography
                    sx={{
                        mt: 2,
                        mb: 6,
                        fontSize: '14px',
                        fontWeight: 500,
                        color: theme.palette.neutral[500],
                    }}
                >
                    Thank you for completing this process. You can now close this tab. To ensure you are compliant,
                    please download and install the application from your email and run a scan if you haven't already
                    done so.
                </Typography>
            </Box>
        </div>
    );
}

import React, { useState } from 'react';
import { toast } from 'react-toastify';

// core components
import Controls from './components/Controls';
import SubControls from './components/SubControls';
import { AppForm, AppFormContext } from 'components/new_components/forms';

// redux
import { connect } from 'react-redux';
import { GetFormsBySubcontrol } from 'store/actions/adminActions';
import { SubmitMerchantAnswers } from 'store/actions/complianceActions';

// validations
import { validateForm } from '../validation';

const AuditQuestions = (props) => {
    const {
        loading: loadingControl,
        merchant_answers,
        forms_by_subcontrol,
        GetFormsBySubcontrol,
        SubmitMerchantAnswers,
        getControls,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
        view,
        setView,
    } = props;

    // state
    const [selectedControl, setSelectedControl] = useState(null);
    const [loading, setLoading] = useState({ content: false, submit: false });
    const [formPayload, setFormPayload] = useState({});
    const [touchedFields, setTouchedFields] = useState({});

    // functions
    // logic functions
    const handleViewChange = (controlID) => {
        setView(1);
        setSelectedControl(controlID);
    };
    const goBack = () => {
        setView(0);
        setSelectedControl(null);
    };

    // async functions
    const getSubcontrolQuestions = async (controlId) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetFormsBySubcontrol(controlId);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch forms.");
        }
    };
    const handleSubmitAnswers = async (values) => {
        setLoading((current) => ({ ...current, submit: true }));
        const res = await SubmitMerchantAnswers({
            answer: values,
            form_id: formPayload.form_id,
        });
        setLoading((current) => ({ ...current, submit: false }));
        if (res?.success) {
            toast.success("You have successfully answered this question, click 'NEXT' to go to the next question.");
            handleFormExempted(formPayload?.subcontrol);
        } else {
            toast.error('Something went wrong!!');
        }
    };
    const handleFormExempted = (subcontrol) => {
        getControls();
        getSubcontrolQuestions(subcontrol);
    };

    return view === 0 ? (
        <Controls
            onViewChange={handleViewChange}
            loading={loadingControl}
            resource="form"
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        />
    ) : (
        <AppForm
            initialValues={formPayload?.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            {/* App form and form context aren't put in the subrequirement because subrequirement is used for both form and doc. */}
            <AppFormContext getTouched={setTouchedFields}>
                <SubControls
                    controlId={selectedControl}
                    goBack={goBack}
                    resource="form"
                    loading={loading?.content}
                    onSubcontrolChange={getSubcontrolQuestions}
                    merchantResources={merchant_answers}
                    subcontrolResources={forms_by_subcontrol}
                    setFormPayload={setFormPayload}
                    touchedFields={touchedFields}
                    submitting={loading.submit}
                    handleResourceExempted={handleFormExempted}
                    openTaskAssignModal={openTaskAssignModal}
                    all_compliance_assigned_tasks={all_compliance_assigned_tasks}
                />
            </AppFormContext>
        </AppForm>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_answers: state?.complianceReducers?.merchant_answers,
        forms_by_subcontrol: state?.adminReducers?.all_admin_forms_by_control,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};
export default connect(mapStateToProps, {
    GetFormsBySubcontrol,
    SubmitMerchantAnswers,
})(AuditQuestions);

import React, { useState } from 'react';
// import { Stack } from '@mui/material';
// import AppInput from 'components/new_components/AppInput';
// import { useTheme } from '@mui/material/styles';
import { riskLevelOptions } from '../../utils/constants';
// import AppSelectInput from 'components/new_components/AppSelectInput';
// import SearchIcon from '@mui/icons-material/Search';
import SearchIcon from 'assets/img/Searchicon.svg';
import { Menu, MenuItem } from '@mui/material';
import VectorDown from 'assets/img/vector-down.svg';
//translations
// import { useTranslation } from 'react-i18next';

const VendorTitle = (props) => {
    const { onSearch, entity_type, setEntity_type } = props;
    // const theme = useTheme();
    const [riskanchorEl, setRiskAnchorEl] = useState(null);

    //translation
    // const { t } = useTranslation('riskAssessment');

    // Functions
    const riskOpenMenu = (e) => setRiskAnchorEl(e.target);
    const riskCloseMenu = () => setRiskAnchorEl(null);

    const handleRiskChange = (value) => {
        setEntity_type(value);
    };

    return (
        <div className="flex items-center relative gap-4">
            <div className="relative">
                <input
                    placeholder="Search..."
                    onChange={onSearch}
                    className="text-gray-400 text-sm font-400 placeholder:text-gray-400 border border-gray-200 py-2.5 rounded-[4px] w-[230px] px-[30px] font-normal"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-3 w-[17px]" />
            </div>
            <div className="relative">
                <button
                    className={`border border-gray-200 py-2.5 ${
                        entity_type === 'medium'
                            ? 'text-[#395BA9] w-[160px]'
                            : entity_type !== 'all'
                            ? 'text-[#395BA9] w-[140px]'
                            : 'text- gray-500 w-[123px]'
                    } text-[13px] font-medium rounded-[4px] flex justify-between items-center px-2 capitalize`}
                    onClick={riskOpenMenu}
                >
                    Risk Level: {entity_type || 'All'}
                </button>
                <img
                    src={VectorDown}
                    alt="VectorDown"
                    className="text-neutral-40 w-[8px] h-[5px] absolute right-3 top-5 font-normal"
                />
            </div>
            <Menu open={Boolean(riskanchorEl)} anchorEl={riskanchorEl} onClose={riskCloseMenu}>
                {[
                    {
                        name: 'All',
                        value: '',
                    },
                ]
                    .concat(riskLevelOptions)
                    ?.map((option) => (
                        <MenuItem
                            sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, width: '140px' }}
                            onClick={() => {
                                handleRiskChange(option?.value);
                                riskCloseMenu();
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
            </Menu>
        </div>
    );
};

export default VendorTitle;

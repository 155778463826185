import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import Categories from './components/Criterias';
import SubCategory from './components/Subcriteria';

// redux
import { connect } from 'react-redux';
import {
    GetAllCriteria,
    GetDocumentsBySubcriteria,
    GetAllConditionalItems,
    GetAllFormsBySubCriteria,
} from 'store/actions/adminActions';
import { AppForm, AppFormContext } from 'components/new_components/forms';
import {
    SubmitMerchantAnswers,
    GetAllMerchantAnswers,
    ConditionalFormAnswer,
    SubmitConditionalForm,
    GetCriteriaSummaries,
    GetAllMerchantDocuments,
    GetAllBlockchainExceptions,
} from 'store/actions/complianceActions';

// validation
import { validateForm } from './utils/validation';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const Criteria = (props) => {
    const {
        GetAllCriteria,
        all_criteria,
        GetDocumentsBySubcriteria,
        GetAllConditionalItems,
        SubmitMerchantAnswers,
        GetAllFormsBySubCriteria,
        merchant_answers,
        GetAllMerchantAnswers,
        ConditionalFormAnswer,
        SubmitConditionalForm,
        company_id,
        GetCriteriaSummaries,
        GetAllMerchantDocuments,
        GetAllBlockchainExceptions,
    } = props;
    // state
    const [view, setView] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState({ content: false, criterias: false, submit: false });
    const [touchedFields, setTouchedFields] = useState({});
    const [formPayload, setFormPayload] = useState({});
    const [condtitionalPayload, setConditionalPayload] = useState({});

    const { activeMerchant } = useContext(SelectedMerchantContext);

    // async functions
    const getAllConditionalFromAnswer = async () => {
        setLoading((current) => ({
            ...current,
            criterias: true,
        }));
        const res = await ConditionalFormAnswer();
        setLoading((current) => ({ ...current, criterias: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
            console.log(res.message);
        }
    };

    const getAllCriteria = async () => {
        setLoading((current) => ({
            ...current,
            criterias: true,
        }));
        const res = await GetAllCriteria();
        setLoading((current) => ({ ...current, criterias: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch Criterias.");
            console.log(res.message);
        }
    };

    const getAllExemptedBlockchain = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllBlockchainExceptions();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
            console.log('Error::::', res?.message);
        }
    };

    const getAllMerchantDocuments = async () => {
        setLoading((current) => ({
            ...current,
            criterias: true,
        }));
        const res = await GetAllMerchantDocuments(activeMerchant);
        setLoading((current) => ({ ...current, criterias: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch Criterias.");
            console.log(res.message);
        }
    };

    // logic functions
    const handleViewChange = (categoryId) => {
        setView(1);
        setSelectedCategory(categoryId);
    };

    const goBack = () => {
        setView(0);
        setSelectedCategory(null);
    };

    // async functions
    const getSubCriteriaDocumentsById = async (subReqNo) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetDocumentsBySubcriteria(subReqNo);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    const getConditionalForms = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllConditionalItems();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getMerchantAnswers = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllMerchantAnswers(activeMerchant);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getSubCriteriaQuestion = async (subReqNo) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllFormsBySubCriteria(subReqNo);
        // setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getCriteriaSummaries = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetCriteriaSummaries();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    const handleSubmitAnswers = async (values) => {
        if (formPayload?.resource === 'form') {
            setLoading((current) => ({ ...current, submit: true }));
            const res = await SubmitMerchantAnswers({
                answer: values,
                form_id: formPayload.form_id,
                subcriteria: formPayload.subcriteria,
            });

            setLoading((current) => ({ ...current, submit: false }));
            if (res?.success) {
                toast.success("You have successfully answered this question, click 'NEXT' to go to the next question.");
            } else {
                toast.error('Something went wrong!!');
                console.log('Check Question, error:::::', res?.message);
            }
        } else {
            setLoading((current) => ({ ...current, submit: true }));
            const value = Object.values(values).map((value) => value);
            const res = await SubmitConditionalForm({
                answer: value[0],
                form_answer: value[1],
                merchant: company_id,
                conditional: formPayload.conditional,
                document_id: formPayload?.document_id,
                criteria: formPayload.criteria,
            });

            setLoading((current) => ({ ...current, submit: false }));
            if (res?.success) {
                toast.success("You have successfully answered this question, click 'NEXT' to go to the next question.");
            } else {
                toast.error('Something went wrong!!');
            }
        }
    };

    const handleExempted = (subrequirementNo) => {
        getSubCriteriaQuestion(subrequirementNo);
        getSubCriteriaDocumentsById(subrequirementNo);
    };
    const getCurrentValues = (values) => setConditionalPayload(values);

    useEffect(() => {
        getAllCriteria();
        getMerchantAnswers();
        getAllConditionalFromAnswer();
        getCriteriaSummaries();
        getAllMerchantDocuments();
        getConditionalForms();
        getAllExemptedBlockchain();
    }, []);

    return view === 0 ? (
        <Categories loading={loading.criterias} onViewChange={handleViewChange} data={all_criteria} />
    ) : (
        <AppForm
            initialValues={formPayload?.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            <AppFormContext getTouched={setTouchedFields} getValues={getCurrentValues}>
                <SubCategory
                    criteria={selectedCategory}
                    goBack={goBack}
                    loading={loading}
                    setFormPayload={setFormPayload}
                    touchedFields={touchedFields}
                    getSubCriteriaDocumentsByTag={getSubCriteriaDocumentsById}
                    getSubCriteriaQuestion={getSubCriteriaQuestion}
                    handleResourceExempted={handleExempted}
                    merchantResources={merchant_answers}
                    formPayload={formPayload}
                    condtitionalPayload={condtitionalPayload}
                    getAllExemptedBlockchain={getAllExemptedBlockchain}
                />
            </AppFormContext>
        </AppForm>
    );
};
const mapStateToProps = (state) => {
    return {
        all_criteriaLength: state?.adminReducers?.all_criteria?.length,
        all_criteria: state?.adminReducers?.all_criteria,
        merchant_answers: state?.complianceReducers?.merchant_answers,
        company_id: state?.authReducers?.company_id,
    };
};
export default connect(mapStateToProps, {
    GetAllCriteria,
    GetDocumentsBySubcriteria,
    GetAllConditionalItems,
    SubmitConditionalForm,
    GetAllFormsBySubCriteria,
    SubmitMerchantAnswers,
    GetAllMerchantAnswers,
    ConditionalFormAnswer,
    GetCriteriaSummaries,
    GetAllMerchantDocuments,
    GetAllBlockchainExceptions,
})(Criteria);

import { Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    ArcElement,
    Chart as ChartJS,
    Legend,
    Tooltip,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js';

import PolygonMedium from 'assets/img/medium_polygon.png';
import PolygonLow from 'assets/img/ciso_positive_angle.png';
import PolygonHigh from 'assets/img/LowPolygon.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);
ChartJS.defaults.plugins.tooltip.backgroundColor = '#395BA9';
ChartJS.defaults.plugins.tooltip.titleColor = '#fff';
ChartJS.defaults.plugins.tooltip.titleAlign = 'center';
ChartJS.defaults.plugins.tooltip.bodyAlign = 'center';
ChartJS.defaults.plugins.tooltip.displayColors = false;
ChartJS.defaults.plugins.tooltip.titleFont = {
    family: 'Poppins, sans-serif',
    weight: 'bold',
};
ChartJS.defaults.plugins.tooltip.bodyFont = {
    family: 'Poppins, sans-serif',
    weight: 'normal',
};
ChartJS.defaults.plugins.tooltip.boxPadding = 4;
ChartJS.defaults.plugins.tooltip.xAlign = 'center';
ChartJS.defaults.plugins.tooltip.yAlign = 'bottom';

const zeroDataBackgroundPlugin = {
    id: 'zeroDataBackground',
    beforeDraw: (chart) => {
        const { datasets } = chart.data;
        const ctx = chart.ctx;

        // Check if datasets exist and contain data
        if (!datasets || !datasets.length || !datasets[0].data.length) {
            return; // Exit early if no data
        }

        // Check if all data values are zero
        const allDataZero = datasets[0].data.every((value) => value === 0);

        if (allDataZero) {
            const { chartArea } = chart;
            const centerX = (chartArea.left + chartArea.right) / 2;
            const centerY = (chartArea.top + chartArea.bottom) / 2;

            // Ensure the dataset meta is available and has data
            const meta = chart.getDatasetMeta(0);
            if (!meta || !meta.data || !meta.data.length) {
                return; // Exit if meta data is not available
            }

            const { outerRadius, innerRadius } = meta.data[0].getProps(['outerRadius', 'innerRadius'], true);

            ctx.save();
            ctx.fillStyle = '#f0f0f0'; // Set your desired background color here
            ctx.beginPath();
            ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI); // Outer circle
            ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI, true); // Inner circle (inverted)

            ctx.fill();
            ctx.restore();

            // Optional: Add a message on the chart
            ctx.font = '20px Poppins';
            ctx.fillStyle = '#666';
            ctx.textAlign = 'center';
            // ctx.fillText('No Data Available', centerX, centerY);
        }
    },
};

ChartJS.register(zeroDataBackgroundPlugin);

const ThirdParty = ({ third_party_options, view, isPdf }) => {
    const { all_third_party_summary } = useSelector((state) => state?.CISOReducers);
    let colors_2 = ['#7AD3FF', '#FFDF91'];
    const values_1 = [all_third_party_summary?.aml_high_risk_count, all_third_party_summary?.aml_low_risk_count];
    const values_2 = [
        all_third_party_summary?.vendors_without_response,
        all_third_party_summary?.vendors_with_response,
    ];
    const values_3 = [
        all_third_party_summary?.non_fraudulent_cases_count,
        all_third_party_summary?.fraudulent_cases_count,
    ];
    const values_4 = [
        all_third_party_summary?.acting_as_vendor_completed_count,
        all_third_party_summary?.acting_as_vendor_in_progress_count,
    ];

    const data_1 = useMemo(
        () => ({
            labels: ['High Risk Detected', 'Low Risk Detected'],
            datasets: [
                {
                    data: values_1,
                    backgroundColor: ['#FF5449', '#05DEEC'],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [values_1]
    );

    const data_2 = useMemo(
        () => ({
            labels: ['Vendors without response', 'Vendors with response'],
            datasets: [
                {
                    data: values_2,
                    backgroundColor: ['#FF5449', '#FFDF91'],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [values_2]
    );

    const data_3 = useMemo(
        () => ({
            labels: ['Non-fraudulent cases', 'Fradulent cases'],
            datasets: [
                {
                    data: values_3,
                    backgroundColor: ['#05DEEC', '#FF5449'],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [values_3]
    );

    const data_4 = useMemo(
        () => ({
            labels: ['Completed', 'In Progress'],
            datasets: [
                {
                    data: values_4,
                    backgroundColor: [colors_2[1], colors_2[0]],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [values_4]
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            zeroDataBackground: {}, // Enable the plugin
            tooltip: {
                font: {
                    weight: 'bold',
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };
    return (
        <div className="mt-[5rem]">
            <div>
                <div className="flex items-center gap-4 mt-[1.5rem] h-full">
                    <h4 className="text-[#2B3674] text-lg font-bold text-center">Third Party</h4>
                    <div
                        className={`flex items-center gap-1 border border-[#E2E8F0] p-1 rounded  w-[max-content] ${
                            isPdf ? 'mt-[1rem]' : ''
                        }`}
                    >
                        <img
                            src={
                                all_third_party_summary?.total_percentage_passed < 50
                                    ? PolygonHigh
                                    : all_third_party_summary?.total_percentage_passed >= 50 &&
                                      all_third_party_summary?.total_percentage_passed <= 70
                                    ? PolygonMedium
                                    : PolygonLow
                            }
                            alt="PolygonMedium"
                        />
                        <p className={`text-gray-500 text-sm font-semibold ${isPdf ? '-mt-[1rem]' : ''}`}>
                            {all_third_party_summary?.total_percentage_passed?.toFixed(2)}%
                        </p>
                    </div>
                    <div className="flex-1">
                        <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                    </div>
                </div>
                <div className="flex justify-center items-center w-full mt-[5rem]" hidden={view === 'Report View'}>
                    <div className="flex justify-between items-center w-[90%]">
                        <div
                            hidden={
                                !third_party_options?.includes('Anti-Money Laundering Check') ||
                                view === 'Report View' ||
                                all_third_party_summary?.aml_check_info?.length === 0
                            }
                            className="flex-1"
                        >
                            <div className="xl:flex items-center md:block gap-5">
                                <div className="w-[170px]">
                                    <Doughnut data={data_1} options={options} />
                                </div>
                                <div className="xl:mt-0 md:mt-3">
                                    <div className="flex items-center gap-2">
                                        <div className="w-[15px] h-[15px] rounded bg-[#05DEEC]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.aml_low_risk_count} Low Risk Detected
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2 mt-[1rem]">
                                        <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.aml_high_risk_count}{' '}
                                            <span className="text-[#FF5449]">High Risk Detected</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="text-[#2B3674] text-base font-bold mt-[2rem]">
                                    Anti-Money Laundering <br />
                                    Check
                                </p>
                            </div>
                        </div>
                        <div
                            hidden={
                                !third_party_options?.includes('Vendor Management') ||
                                view === 'Report View' ||
                                all_third_party_summary?.vendor_management_info?.length === 0
                            }
                            className="flex-1"
                        >
                            <div className="xl:flex items-center md:block gap-5">
                                <div className="w-[170px]">
                                    <Doughnut data={data_2} options={options} />
                                </div>
                                <div className="xl:mt-0 md:mt-3">
                                    <div className="flex items-center gap-2">
                                        <div className="w-[15px] h-[15px] rounded bg-[#FFDF91]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.vendors_with_response} vendors with response
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2 mt-[1rem]">
                                        <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.vendors_without_response} vendors{' '}
                                            <span className="text-[#FF5449]">without response</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="text-[#2B3674] text-base font-bold mt-[2rem]">
                                    Vendor Management <br />
                                    (Vendors)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center items-center w-full mt-[4rem]" hidden={view === 'Report View'}>
                    <div className="flex justify-between items-center w-[90%]">
                        <div
                            hidden={
                                !third_party_options?.includes('Transaction Fraud Monitoring') ||
                                view === 'Report View' ||
                                all_third_party_summary?.transaction_fraud_info?.length === 0
                            }
                            className="flex-1"
                        >
                            <div className="xl:flex items-center md:block gap-5">
                                <div className="w-[170px]">
                                    <Doughnut data={data_3} options={options} />
                                </div>
                                <div className="xl:mt-0 md:mt-3">
                                    <div className="flex items-center gap-2">
                                        <div className="w-[15px] h-[15px] rounded bg-[#05DEEC]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.non_fraudulent_cases_count} Non-Fraudulent cases
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2 mt-[1rem]">
                                        <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.total_fraud_monitoring}{' '}
                                            <span className="text-[#FF5449]">Fraudulent cases</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className="text-[#2B3674] text-base font-bold mt-[2rem]">Transaction Fraud monitoring</p>
                        </div>
                        <div
                            hidden={
                                !third_party_options?.includes('Vendor Management') ||
                                view === 'Report View' ||
                                all_third_party_summary?.acting_as_vendor_info?.length === 0
                            }
                            className="flex-1"
                        >
                            <div className="xl:flex items-center md:block gap-5">
                                <div className="w-[170px]">
                                    <Doughnut data={data_4} options={options} />
                                </div>
                                <div className="xl:mt-0 md:mt-3">
                                    <div className="flex items-center gap-2">
                                        <div className="w-[15px] h-[15px] rounded bg-[#FFDF91]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.acting_as_vendor_completed_count} Completed
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2 mt-[1rem]">
                                        <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                        <p className="text-[#64748B] text-[13px] font-medium">
                                            {all_third_party_summary?.acting_as_vendor_in_progress_count} In Progress
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className="text-[#2B3674] text-base font-bold mt-[2rem]">
                                Vendor Management
                                <br />
                                (Acting as a vendor)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-[5rem]">
                <div
                    hidden={
                        !third_party_options?.includes('Anti-Money Laundering Check') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.aml_check_info?.length === 0
                    }
                >
                    <div className="border border-[#E2E8F0] bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Anti-Money Laundering Check</p>
                    </div>
                    <table className="w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Risk Level
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.aml_check_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-[#E2E8F0]">
                                        {option?.name}
                                    </th>
                                    <th
                                        className={`${
                                            option?.risk_level
                                                ? option?.risk_level?.toLowerCase() === 'low'
                                                    ? 'text-[#55BE8B]'
                                                    : 'text-[#FF5449]'
                                                : 'text-[#64748B]'
                                        } text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {option?.risk_level || 'N/A'}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {moment(option?.date_created)?.format('Do MMM, YYYY')}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
                <div
                    hidden={
                        !third_party_options?.includes('Transaction Fraud Monitoring') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.transaction_fraud_info?.length === 0
                    }
                >
                    <div className="border border-[#E2E8F0] bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Transaction Fraud Monitoring</p>
                    </div>
                    <table className="w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Risk Level
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.transaction_fraud_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-[#E2E8F0]">
                                        {option?.name}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {option.fraud_percentage}%
                                    </th>
                                    <th
                                        className={`${
                                            option?.risk_level === 'Low' ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                        } text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {option?.status}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
                <div
                    hidden={
                        !third_party_options?.includes('Vendor Management') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.vendor_management_info?.length === 0
                    }
                >
                    <div className="border border-[#E2E8F0] bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Vendor Management(Vendors)</p>
                    </div>
                    <table className="w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Risk Level
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.vendor_management_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-[#E2E8F0]">
                                        {option?.name}
                                    </th>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-[#E2E8F0]">
                                        {option?.risk_level ? option?.risk_level : 'N/A'}
                                    </th>
                                    <th
                                        className={`${
                                            option?.status ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                        } text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {option?.status ? 'Responded' : 'Not Responded'}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {moment(option?.date_created)?.format('Do MMM, YYYY')}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
                <div
                    hidden={
                        !third_party_options?.includes('Vendor Management') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.acting_as_vendor_info?.length === 0
                    }
                >
                    <div className="border border-[#E2E8F0] bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Vendor Management(Acting as a vendor)</p>
                    </div>
                    <table className="w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-[#E2E8F0]">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.acting_as_vendor_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-[#E2E8F0]">
                                        {option?.file_name}
                                    </th>
                                    <th
                                        className={`${
                                            option?.status === 'completed'
                                                ? 'text-[#55BE8B]'
                                                : option?.status === 'in_progress'
                                                ? 'text-[#F2C021]'
                                                : 'text-[#395BA9]'
                                        } text-xs font-normal p-3 text-center border border-[#E2E8F0] capitalize`}
                                    >
                                        {option?.status?.replace(/_/g, ' ')}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-[#E2E8F0]`}
                                    >
                                        {moment(option?.date_added)?.format('Do MMM, YYYY')}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ThirdParty;

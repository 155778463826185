import React, { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TextSnippet } from '@mui/icons-material';
import Play from '../../../../../assets/img/bullet.svg';
import FieldItem from './FieldItem';

// core components
import AppButton from 'components/new_components/AppButton';
import AppUploadButton from 'components/new_components/AppUploadButton';
import { AppFormSelect, AppFormInput } from 'components/new_components/forms';
import DocumentDrawer from '../../common/document/DocumentDrawer';

import ItemSubheader from '../../common/ItemSubheader';

import { accept } from 'validate';

// Redux
import { UploadConditionalDocument } from 'store/actions/complianceActions';
import { connect } from 'react-redux';
import { RevertBlockchainCompliance, SubmitConditionalForm } from 'store/actions/complianceActions';

//translation
import { useTranslation } from 'react-i18next';

const { pdf, docx, excel, txt, ppt } = accept;

const ConditionalItem = (props) => {
    const theme = useTheme();
    const {
        onResourceChange,
        all_condition_answers,
        form,
        UploadConditionalDocument,
        setDocument_id,
        exception,
        documents_by_subcriteria,
        all_merchant_documents,
        condtitionalPayload,
        RevertBlockchainCompliance,
        exceptionId,
        getAllExemptedBlockchain,
        company_id,
        SubmitConditionalForm,
        documentId,
    } = props;
    const [drawerOpened, setDrawerOpened] = useState(null);
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const inputRef = useRef();

    //translation
    const { t } = useTranslation('compliance');

    const openDrawer = (tab) => setDrawerOpened(tab);
    const closeDrawer = () => setDrawerOpened(null);

    const handleSubmitAnswers = async () => {
        const res = await SubmitConditionalForm({
            answer: condtitionalPayload?.answer,
            merchant: company_id,
            conditional: form?.id,
            document_id: documentId,
            criteria: form?.criteria,
        });

        if (res?.success) {
            toast.success("You have successfully answered this question, click 'NEXT' to go to the next question.");
        } else {
            toast.error('Something went wrong!!');
        }
    };

    const uploadMerchantDocument = async (e) => {
        // get the form data
        const doc = new FormData();
        doc.append('document', e.target.files[0]);
        doc.append('document_id', form?.document_id);
        doc.append('subcriteria', form?.subcriteria);
        doc.append('tag', 'conditional');

        // sending the data
        setLoading({ ...loading, upload: true });
        const res = await UploadConditionalDocument(doc, form?.document_id);
        setLoading({ ...loading, upload: false });
        setDocument_id(res?.data?.id);
        if (res?.success) {
            handleSubmitAnswers();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
        inputRef.current.value = null;
    };

    // get the current question in view
    const currentQuestion = useMemo(() => {
        return all_condition_answers?.find((doc) => doc?.conditional === form?.id);
    }, [form, all_condition_answers]);

    const findDocument = useMemo(() => {
        return documents_by_subcriteria?.find((doc) => doc?.id === form?.document_id);
    }, [form, documents_by_subcriteria]);

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === form?.document_id);
    }, [form, all_merchant_documents]);

    // exempt a Question
    const removeExempt = async () => {
        setLoading({ ...loading, exempt: true });
        const res = await RevertBlockchainCompliance(exceptionId);
        setLoading({ ...loading, exempt: false });
        getAllExemptedBlockchain();
        onResourceChange();
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const document_id = {
        doc_id: selectedDocument?.document_id,
        user_doc_id: selectedDocument?.id,
        subcriteria: selectedDocument?.subcriteria,
    };

    const payload = {
        subcriteria: form?.subcriteria,
        conditional: form?.id,
        criteria: form?.criteria,
        reason: '',
    };

    const field = {
        id: currentQuestion?.answer,
        name: form?.conditional_detail?.question,
        options: 'Yes, No',
        type: 'dropdown',
        required: true,
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={Boolean(currentQuestion)}
                exempting={loading.exempt}
                exceptionReason={form}
                menuItems={[
                    { label: t('blockchain.docAndVersions'), action: () => openDrawer(0) },
                    { label: t('blockchain.comments'), action: () => openDrawer(1) },
                ]}
                resource="form"
                payload={payload}
                type="conditional"
                blockchain
                onResourceChange={onResourceChange}
                exception={exception}
            />
            {exception ? (
                <Box>
                    <FieldItem
                        field={field}
                        exception={exception}
                        removeExempt={removeExempt}
                        loading={loading}
                        conditional
                    />
                </Box>
            ) : (
                <Stack direction="row" alignItems={'start'} spacing={2} sx={{ marginBottom: 2 }}>
                    <Box sx={{ marginTop: 2.5 }}>
                        <img src={Play} alt="" />
                    </Box>
                    <Box sx={{ width: 500 }}>
                        <AppFormSelect
                            name={'answer'}
                            label={form?.question}
                            options={[
                                { name: t('blockchain.yes'), value: 'Yes' },
                                { name: t('blockchain.no'), value: 'No' },
                            ]}
                            required
                        />
                    </Box>
                </Stack>
            )}
            {exception || condtitionalPayload?.answer === '' ? null : (
                <Box>
                    {condtitionalPayload?.answer === 'No' ? (
                        <Stack direction="row" spacing={2}>
                            <Box sx={{ marginTop: 2.5 }}>
                                <img src={Play} alt="" />
                            </Box>
                            <Box sx={{ width: 500 }}>
                                <AppFormInput
                                    type="text"
                                    label={form?.no_label}
                                    name={'no_label'}
                                    placeholder={t('blockchain.explain')}
                                />
                            </Box>
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ py: 5 }}
                            spacing={3}
                        >
                            <TextSnippet color="primary" />
                            <Stack>
                                <Typography
                                    sx={{
                                        color: theme.palette.gray[500],
                                        fontWeight: 500,
                                        fontSize: 12,
                                    }}
                                >
                                    {t('blockchain.downloadTemplate')}
                                </Typography>
                                <Stack direction="row" spacing={3} sx={{ pt: 4 }}>
                                    <AppButton
                                        color="primary"
                                        variant="contained"
                                        name={t('blockchain.download')}
                                        icon={<FileDownloadIcon />}
                                        href={findDocument?.default}
                                        download
                                        target="_blank"
                                        rel="noreferer"
                                        sx={{
                                            textTransform: 'uppercase',
                                            '&, &:hover': { color: theme.palette.white.main },
                                        }}
                                    />
                                    <AppUploadButton
                                        color="primary"
                                        variant="outlined"
                                        label={selectedDocument ? t('uploadVersion') : t('uploadDocument')}
                                        startIcon={<FileUploadIcon />}
                                        loading={loading.upload}
                                        id={document?.document_title}
                                        onChange={uploadMerchantDocument}
                                        accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                                        sx={{ textTransform: 'uppercase' }}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Box>
            )}
            <DocumentDrawer
                open={Boolean(drawerOpened !== null)}
                tab={drawerOpened}
                handleClose={closeDrawer}
                document={document_id}
                onResourceChange={onResourceChange}
                form={currentQuestion}
                conditional
            />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_id: state?.authReducers?.company_id,
        all_condition_answers: state?.complianceReducers?.all_condition_answers,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        merchant_answers: state?.complianceReducers?.merchant_answers,
        documents_by_subcriteria: state?.adminReducers?.documents_by_subcriteria,
        company_id: state?.authReducers?.company_id,
    };
};

export default connect(mapStateToProps, {
    UploadConditionalDocument,
    RevertBlockchainCompliance,
    SubmitConditionalForm,
})(ConditionalItem);

/* eslint-disable no-unused-vars */

import { Box, Dialog, Menu, MenuItem, Typography } from '@mui/material';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Img from 'assets/img/img.svg';
import Png from 'assets/img/png.svg';
import Upload from 'assets/img/upload.png';
import Xsl from 'assets/img/xsl.svg';
import { Loader2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UploadReportFile } from 'store/actions/auditActions';
import { by3riskLevel, by5riskLevel } from 'utils';
import { accept } from 'validate';
import {
    inherentLikelihoodOrImpact3x3,
    inherentLikelihoodOrImpact3x3_,
    inherentLikelihoodOrImpact5x5,
    inherentLikelihoodOrImpact5x5_,
} from '../../utils/constants';
import NewPerson from '../RiskIdentifictaion/identificationComponents/NewPerson';
import AcceptFormvView from './mitigationComponents/AcceptForm';
import AddReviewPeriod from './mitigationComponents/AppReviewPeriod';
import AvoidFormvView from './mitigationComponents/AvoidForm';
import MitigationFormvView from './mitigationComponents/MitigationForm';
import TransferFormvView from './mitigationComponents/TransaferForm';

const mitigationPlanTabs = {
    mitigate: 'mitigate',
    accept: 'accept',
    avoid: 'avoid',
    transfer: 'transfer',
};

const mitigationTips = {
    mitigate: 'Implement controls and recovery measures to reduce the likelihood of the risk occurring.',
    accept: 'Acknowledge the risk and choose not to resolve, transfer or mitigate',
    avoid: 'Completely eliminate or forego risk',
    transfer: 'Assign or move the risk to a third-party',
};

export default function RiskMitigation({
    riskIdentificationTabStates,
    setRiskIdentificationTabStates,
    mitigation_plan,
    setMitigationPlan,
    riskFirstPageStates,
    new_document,
    setNewDocument,
    existing_document,
    setExistingDocument,
    management_approver,
    setManagementApprover,
    error,
    matrix,
    useAssetOwner,
    setUseAssetOwner,
    assets,
    setError,
    currency,
    setCurrency,
    continuous_monitoring,
    setContinousMonitoring,
    continuous_monitoring_frequency,
    setContinousMonitoringFrequency,
    recommendations,
    mitigation_controls,
    setMitigationControls,
    residual_impact,
    setResidualImpact,
    residual_likelihood,
    setResidualLikelihood,
    loadingAI,
    doc,
    setDoc,
    details,
    mitigation_array,
    // treatmentPlan,
}) {
    // states
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);
    const by5Matrix =
        get_risk_settings[0]?.risk_approach === 'qualitative'
            ? inherentLikelihoodOrImpact5x5
            : inherentLikelihoodOrImpact5x5_ || inherentLikelihoodOrImpact5x5;
    const by3Matrix =
        get_risk_settings[0]?.risk_approach === 'qualitative'
            ? inherentLikelihoodOrImpact3x3
            : inherentLikelihoodOrImpact3x3_ || inherentLikelihoodOrImpact3x3;
    const [residualLikelihoodOrImpact, setResidualLikelihoodOrImpact] = useState(
        matrix === '5x5' ? [...by5Matrix] : [...by3Matrix]
    );
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [docAnchorEl, setCDocAnchorEl] = useState(null);
    const [loading, setLoading] = useState(null);
    const [search, setSearch] = useState('');
    const { excel, pdf, png, img, docx, txt } = accept;

    // redux
    const dispatch = useDispatch();
    const { all_merchant_documents } = useSelector((state) => state.complianceReducers);
    const {
        all_merchant_users,
        risk_assessment_threats,
        risk_management_settings,
        all_merchant_assets,
        merchant_asset_groups,
    } = useSelector((state) => state.riskAssessmentReducers);
    const [risk_settings] = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings || []);

    const is_5x5 = matrix === '5x5';

    const all_users = all_merchant_users?.people?.concat(all_merchant_users?.users);
    const filtered_employees = all_users?.filter((user) => user?.email.toLowerCase().includes(search.toLowerCase()));
    // Upload
    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 5) {
            toast.info('File too large, minimum of 5MB');
            setNewDocument('');
        } else {
            const formData = new FormData();
            formData.append('report', event.target.files[0]);
            setLoading(true);
            const res = await dispatch(UploadReportFile(formData));
            setLoading(false);
            if (res?.success) {
                setNewDocument(res?.report);
                event.target.value = null;
            }
        }
    };

    const removeDocument = () => {
        setNewDocument('');
        setExistingDocument('');
    };

    // Asset Owner
    const current_asset_owner = all_merchant_assets?.find((asset) => asset?.id === assets?.[0]?.id);
    const other_asset_owner = merchant_asset_groups?.find((asset) => asset?.id === current_asset_owner?.group);

    const asset_owner = current_asset_owner?.group ? other_asset_owner?.owner_email : current_asset_owner?.owner_email;
    // docs
    const filterDoc = all_merchant_documents?.filter(
        (docs) =>
            docs?.document_type?.iso_title ||
            docs?.document_type?.ndpr_title ||
            docs?.document_type?.iso9001_title ||
            docs?.document_type?.pcislc_title ||
            docs?.document_type?.iso27001_title ||
            docs?.document_type?.pcidss_title ||
            docs?.document_type?.card4_title ||
            docs?.document_type?.soc2_title ||
            docs?.document_type?.document_title
    );

    const docLinkOptions = useMemo(
        () =>
            // Array.isArray(linkDocument) ?
            filterDoc
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((doc) => {
                    return {
                        name:
                            doc?.document_type?.iso_title ||
                            doc?.document_type?.ndpr_title ||
                            doc?.document_type?.document_title ||
                            doc?.document_type?.pcislc_title ||
                            doc?.document_type?.iso9001_title ||
                            doc?.document_type?.iso27001_title ||
                            doc?.document_type?.pcidss_title ||
                            doc?.document_type?.card4_title ||
                            doc?.document_type?.soc2_title,
                        value: doc?.id,
                        document: doc?.document,
                    };
                }),
        // : [],
        [filterDoc]
    );
    const filtered_docLinkOptions = docLinkOptions?.filter((doc) =>
        doc?.name.toLowerCase().includes(search.toLowerCase())
    );

    function RenderFormView() {
        switch (mitigation_plan) {
            case mitigationPlanTabs.mitigate:
                return (
                    <MitigationFormvView
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        setMitigationPlan={setMitigationPlan}
                        residualLikelihoodOrImpact={residualLikelihoodOrImpact}
                        error={error}
                        currency={currency}
                        setCurrency={setCurrency}
                        recommendations={recommendations}
                        mitigation_controls={mitigation_controls}
                        setMitigationControls={setMitigationControls}
                        residual_impact={residual_impact}
                        setResidualImpact={setResidualImpact}
                        residual_likelihood={residual_likelihood}
                        setResidualLikelihood={setResidualLikelihood}
                        loadingAI={loadingAI}
                        is_5x5={is_5x5}
                    />
                );

            case mitigationPlanTabs.accept:
                return (
                    <AcceptFormvView
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        residualLikelihoodOrImpact={residualLikelihoodOrImpact}
                        error={error}
                        setError={setError}
                        residual_impact={residual_impact}
                        setResidualImpact={setResidualImpact}
                        residual_likelihood={residual_likelihood}
                        setResidualLikelihood={setResidualLikelihood}
                        is_5x5={is_5x5}
                    />
                );

            case mitigationPlanTabs.avoid:
                return (
                    <AvoidFormvView
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        error={error}
                        setError={setError}
                    />
                );

            case mitigationPlanTabs.transfer:
                return (
                    <TransferFormvView
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        error={error}
                        setError={setError}
                    />
                );

            default:
                return (
                    <MitigationFormvView
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        setMitigationPlan={setMitigationPlan}
                        residualLikelihoodOrImpact={residualLikelihoodOrImpact}
                        error={error}
                        currency={currency}
                        setCurrency={setCurrency}
                        recommendations={recommendations}
                        mitigation_controls={mitigation_controls}
                        setMitigationControls={setMitigationControls}
                        residual_impact={residual_impact}
                        setResidualImpact={setResidualImpact}
                        residual_likelihood={residual_likelihood}
                        setResidualLikelihood={setResidualLikelihood}
                        loadingAI={loadingAI}
                        is_5x5={is_5x5}
                    />
                );
        }
    }

    useEffect(() => {
        if (matrix === '5x5') {
            setResidualLikelihoodOrImpact([...by5Matrix]);
        } else {
            setResidualLikelihoodOrImpact([...by3Matrix]);
        }
    }, [matrix]);

    useEffect(() => {
        if (!mitigation_plan || mitigation_plan === 'no response') {
            setMitigationPlan(mitigationPlanTabs.mitigate);
        }
    }, [mitigation_plan]);

    const inherent_risk = useMemo(() => {
        return matrix === '3x3'
            ? by3riskLevel(riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact)
            : by5riskLevel(riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact);
    }, []);
    const residual_risk = useMemo(() => {
        return matrix === '3X3'
            ? by3riskLevel(residual_impact * residual_likelihood)
            : by5riskLevel(residual_impact * residual_likelihood);
    }, [residual_impact, residual_likelihood]);
    useEffect(() => {
        const document = filtered_docLinkOptions?.find((document) => document?.value === existing_document);
        setDoc(document?.document);
    }, []);

    // useEffect(() => {
    //     if (!mitigation_plan) {
    //         setMitigationPlan(treatmentPlan);
    //     }
    // }, [treatmentPlan]);

    return (
        <div className="mb-[3rem]">
            <div className="flex flex-col gap-8">
                <div className="flex gap-7 items-center">
                    <div className="flex gap-6">
                        <div
                            className="flex flex-col justify-center items-center gap-1 !p-2 !rounded w-[128px] h-[108px] text-white"
                            style={{ backgroundColor: inherent_risk?.color }}
                        >
                            <p className="text-base font-semibold">Inherent Risk</p>

                            <p className="font-bold text-2xl">
                                {riskFirstPageStates?.inherent_likelihood * riskFirstPageStates?.inherent_impact}
                            </p>

                            <p className="text-base font-semibold capitalize text-center">
                                {is_5x5
                                    ? risk_settings?.label_5x5[inherent_risk?.type?.toLowerCase().replace(' ', '_')]
                                        ? risk_settings?.label_5x5[inherent_risk?.type?.toLowerCase().replace(' ', '_')]
                                        : inherent_risk?.type
                                    : risk_settings?.label_3x3[inherent_risk?.type?.toLowerCase().replace(' ', '_')]
                                    ? risk_settings?.label_3x3[inherent_risk?.type?.toLowerCase().replace(' ', '_')]
                                    : inherent_risk?.type}
                            </p>
                        </div>

                        <div
                            className="flex flex-col justify-center items-center gap-1 !p-2 !rounded w-[128px] h-[108px] text-white bg-[#FF5449]"
                            style={{ backgroundColor: residual_impact ? residual_risk?.color : '#6B7280' }}
                        >
                            <p className="text-base font-semibold">Residual Risk</p>

                            <p className="font-bold text-2xl">{residual_impact * residual_likelihood || 0}</p>

                            <p className="text-base font-semibold capitalize text-center">
                                {is_5x5
                                    ? risk_settings?.label_5x5[residual_risk?.type?.toLowerCase().replace(' ', '_')]
                                        ? risk_settings?.label_5x5[residual_risk?.type?.toLowerCase().replace(' ', '_')]
                                        : residual_risk?.type
                                    : risk_settings?.label_3x3[residual_risk?.type?.toLowerCase().replace(' ', '_')]
                                    ? risk_settings?.label_3x3[residual_risk?.type?.toLowerCase().replace(' ', '_')]
                                    : residual_risk?.type}
                            </p>
                        </div>
                    </div>

                    <div className="flex-1 flex flex-col gap-2">
                        <p className="text-[#64748B] font-semibold text-sm">
                            Risk Subject
                            <span className="font-normal">
                                : {riskFirstPageStates?.name ? riskFirstPageStates?.name : '--'}
                            </span>
                        </p>

                        <p className="text-[#64748B] font-semibold text-sm">
                            Threat
                            <span className="font-normal">
                                :{' '}
                                {riskFirstPageStates.threat
                                    ? risk_assessment_threats?.find(
                                          (threat) => threat?.id === riskFirstPageStates?.threat
                                      )?.name
                                    : '--'}
                            </span>
                        </p>

                        <ReportRisk
                            riskIdentificationTabStates={riskIdentificationTabStates}
                            setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex justify-between gap-4 items-center">
                        <div className="flex-1">
                            <p className="font-medium text-sm text-[#64748B] mb-1">Risk Treatment Plan</p>

                            <div className="flex flex-col gap-2">
                                <div className="flex gap-4">
                                    <button
                                        className={`flex flex-col gap-1 items-center justify-center !p-2 bg-white !rounded !w-[75px] !h-[65px] transition-all hover:brightness-95 ${
                                            mitigation_plan === 'mitigate'
                                                ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                                : '!border !border-[#64748B] text-[#64748B]'
                                        }`}
                                        onClick={() => {
                                            setMitigationPlan(mitigationPlanTabs.mitigate);
                                        }}
                                        hidden={!mitigation_array?.includes('mitigate')}
                                    >
                                        <img
                                            src="/img/risk-assessment/ion_umbrella-sharp.svg"
                                            alt="Umbrella icon"
                                            className={`object-contain ${
                                                mitigation_plan === mitigationPlanTabs.mitigate
                                                    ? 'grayscale-0'
                                                    : 'grayscale'
                                            }`}
                                        />

                                        <p className="text-sm font-medium">Mitigate</p>
                                    </button>

                                    <button
                                        className={`flex flex-col gap-1 items-center justify-center !p-2 bg-white !rounded !w-[75px] !h-[65px] transition-all hover:brightness-95 ${
                                            mitigation_plan === 'accept'
                                                ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                                : '!border !border-[#64748B] text-[#64748B]'
                                        }`}
                                        hidden={!mitigation_array?.includes('accept')}
                                        onClick={() => {
                                            setMitigationPlan(mitigationPlanTabs.accept);
                                        }}
                                    >
                                        <img
                                            src="/img/risk-assessment/check_circle.svg"
                                            alt="Check icon"
                                            className={`object-contain ${
                                                mitigation_plan === mitigationPlanTabs.accept
                                                    ? 'grayscale-0'
                                                    : 'grayscale'
                                            }`}
                                        />

                                        <p className="text-sm font-medium">Accept</p>
                                    </button>
                                    <button
                                        className={`flex flex-col gap-1 items-center justify-center !p-2 bg-white !rounded !w-[75px] !h-[65px] transition-all hover:brightness-95 ${
                                            mitigation_plan === 'avoid'
                                                ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                                : '!border !border-[#64748B] text-[#64748B]'
                                        }`}
                                        hidden={!mitigation_array?.includes('avoid')}
                                        onClick={() => {
                                            setMitigationPlan(mitigationPlanTabs.avoid);
                                        }}
                                    >
                                        <img
                                            src="/img/risk-assessment/game-icons_avoidance.svg"
                                            alt="Split Arrows icon"
                                            className={`object-contain ${
                                                mitigation_plan === mitigationPlanTabs.avoid
                                                    ? 'grayscale-0'
                                                    : 'grayscale'
                                            }`}
                                        />

                                        <p className="text-sm font-medium">Avoid</p>
                                    </button>

                                    <button
                                        className={`flex flex-col gap-1 items-center justify-center !p-2 bg-white !rounded !w-[75px] !h-[65px] transition-all hover:brightness-95 ${
                                            mitigation_plan === 'transfer'
                                                ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                                : '!border !border-[#64748B] text-[#64748B]'
                                        }`}
                                        hidden={!mitigation_array?.includes('transfer')}
                                        onClick={() => {
                                            setMitigationPlan(mitigationPlanTabs.transfer);
                                        }}
                                    >
                                        <img
                                            src="/img/risk-assessment/transfer-risk.svg"
                                            alt="Transfer Arrows icon"
                                            className={`object-contain ${
                                                mitigation_plan === mitigationPlanTabs.transfer
                                                    ? 'grayscale-0'
                                                    : 'grayscale'
                                            }`}
                                        />

                                        <p className="text-sm font-medium">Transfer</p>
                                    </button>
                                </div>
                                <div className="flex flex-col gap-1">
                                    {mitigation_array?.length === 4 && (
                                        <img
                                            src={`/img/risk-assessment/${mitigation_plan}-line.svg`}
                                            alt="Pointer line"
                                            className="object-contain w-[347px]"
                                        />
                                    )}

                                    {mitigation_array?.length === 3 && (
                                        <img
                                            src={`/img/risk-assessment/${
                                                mitigation_plan === 'accept'
                                                    ? 'mitigate'
                                                    : mitigation_plan === 'avoid'
                                                    ? 'accept'
                                                    : 'avoid'
                                            }-line.svg`}
                                            alt="Pointer line"
                                            className="object-contain w-[347px]"
                                        />
                                    )}
                                    {mitigation_array?.length === 2 && (
                                        <img
                                            src={`/img/risk-assessment/${
                                                mitigation_plan === 'mitigate'
                                                    ? 'mitigate'
                                                    : mitigation_plan === 'accept'
                                                    ? 'mitigate'
                                                    : 'accept'
                                            }-line.svg`}
                                            alt="Pointer line"
                                            className="object-contain w-[347px]"
                                        />
                                    )}
                                    {mitigation_array?.length === 1 && (
                                        <img
                                            src={`/img/risk-assessment/mitigate-line.svg`}
                                            alt="Pointer line"
                                            className="object-contain w-[347px]"
                                        />
                                    )}
                                    <p className="text-[#64748B] font-medium text-xs leading-[18px] max-w-[347px]">
                                        {mitigationTips[mitigation_plan]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {mitigation_plan === 'mitigate' && (
                            <div className="w-[400px]">
                                <p className="font-medium text-sm text-[#64748B]">Mitigation Owner</p>

                                <div>
                                    <button
                                        aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={categoryAnchorEl ? 'true' : undefined}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setCategoryAnchorEl(event.currentTarget);
                                        }}
                                        className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                                    >
                                        <p
                                            className={`font-normal text-sm ${
                                                riskIdentificationTabStates?.solution_owner
                                                    ? 'text-[hsl(215,20%,40%)]'
                                                    : 'text-[#94A3B8]'
                                            }`}
                                        >
                                            {riskIdentificationTabStates?.solution_owner
                                                ? riskIdentificationTabStates?.solution_owner
                                                : 'Select owner'}
                                        </p>

                                        <img
                                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                            alt="Arrow Down Icon"
                                            className="object-contain"
                                        />
                                    </button>

                                    <Menu
                                        anchorEl={categoryAnchorEl}
                                        open={Boolean(categoryAnchorEl)}
                                        onClose={() => setCategoryAnchorEl(null)}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                borderRadius: '4px',
                                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                            },
                                            '& .MuiList-root': {
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        {' '}
                                        <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                            <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                                Search email
                                            </p>

                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                    value={search}
                                                    onChange={(event) => {
                                                        event.stopPropagation();
                                                        setSearch(event.target.value);
                                                    }}
                                                />

                                                <img
                                                    src="/img/risk-assessment/ri_search-line-small.svg"
                                                    alt="Search Icon"
                                                    className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                                />
                                            </div>
                                        </div>
                                        {filtered_employees?.length ? (
                                            <div className="!p-2 flex flex-col min-w-[224px]">
                                                {filtered_employees?.map((category) => (
                                                    <MenuItem
                                                        key={category?.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setRiskIdentificationTabStates((prev) => ({
                                                                ...prev,
                                                                solution_owner: category?.email,
                                                            }));
                                                            setUseAssetOwner(false);
                                                            setCategoryAnchorEl(null);
                                                        }}
                                                        sx={{
                                                            color: '#64748B',
                                                            fontWeight: 500,
                                                            fontSize: '13px',
                                                            padding: '6px 8px',
                                                        }}
                                                    >
                                                        {category?.email}
                                                    </MenuItem>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                                User not found
                                            </p>
                                        )}
                                    </Menu>
                                </div>
                                {assets?.length === 1 && (
                                    <button
                                        className="flex items-center gap-2 transition-all hover:brightness-90 mt-2"
                                        onClick={() => {
                                            if (useAssetOwner) {
                                                setRiskIdentificationTabStates((prev) => ({
                                                    ...prev,
                                                    solution_owner: '',
                                                }));
                                                setUseAssetOwner(false);
                                            } else {
                                                setRiskIdentificationTabStates((prev) => ({
                                                    ...prev,
                                                    solution_owner: asset_owner,
                                                }));
                                                setUseAssetOwner(true);
                                            }
                                        }}
                                    >
                                        {useAssetOwner ? (
                                            <img
                                                src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                                alt="Checkbox icon"
                                                className="object-contain"
                                            />
                                        ) : (
                                            <img
                                                src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                                alt="Checkbox icon"
                                                className="object-contain"
                                            />
                                        )}

                                        <p className="font-medium text-sm text-[#395BA9]">
                                            Use asset owner as the risk mitigation owner
                                        </p>
                                    </button>
                                )}

                                {error && !riskIdentificationTabStates?.solution_owner && (
                                    <span className="text-xs text-[#FF0000] font-normal mt-2">
                                        Mitigation owner is required
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <RenderFormView />
                </div>
            </div>

            <div className="flex flex-col gap-2 mt-[1rem]">
                <p className="font-medium text-sm text-[#64748B]">Supporting documentation</p>

                <div className="flex items-center gap-3">
                    <label
                        htmlFor="file-input"
                        className="flex items-center gap-2 bg-[#F8FAFC] border border-[#E2E8F0] rounded p-2 cursor-pointer"
                    >
                        <img src={Upload} alt="Upload Icon" />
                        <p className="text-[#64748B] text-sm font-medium">Choose file</p>
                        <input
                            type="file"
                            accept={`${excel},${pdf},${png},${img},${docx},${txt}`}
                            onChange={(e) => uploadImage(e)}
                            hidden
                            id="file-input"
                        />
                    </label>
                    <div
                        className="flex items-center gap-2 bg-[#F8FAFC] border border-[#E2E8F0] rounded p-2 mt-[-0.5rem] cursor-pointer"
                        onClick={(event) => {
                            event.stopPropagation();
                            setCDocAnchorEl(event.currentTarget);
                        }}
                    >
                        <img src={Upload} alt="Upload Icon" />
                        <p className="text-[#64748B] text-sm font-medium">Use existing document</p>
                    </div>
                    <div>
                        <Menu
                            anchorEl={docAnchorEl}
                            open={Boolean(docAnchorEl)}
                            onClose={() => setCDocAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    pb: -2,
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                    use existing document
                                </p>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                        value={search}
                                        onChange={(event) => {
                                            event.stopPropagation();
                                            setSearch(event.target.value);
                                        }}
                                    />

                                    <img
                                        src="/img/risk-assessment/ri_search-line-small.svg"
                                        alt="Search Icon"
                                        className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                    />
                                </div>
                            </div>
                            {filtered_docLinkOptions?.length ? (
                                <div className="!p-2 flex flex-col w-fit">
                                    {filtered_docLinkOptions?.map((doc) => (
                                        <MenuItem
                                            key={doc?.value}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setExistingDocument(doc?.value);
                                                setDoc(doc?.document);
                                                setCDocAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                textTransform: 'capitalize',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {doc?.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            ) : (
                                <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                    Document not found
                                </p>
                            )}
                        </Menu>
                    </div>
                    {loading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />}
                    {(new_document || doc) && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={
                                        (new_document || doc)?.split('.').pop() === 'xlsx'
                                            ? Xsl
                                            : (new_document || doc)?.split('.').pop() === 'csv'
                                            ? Xsl
                                            : (new_document || doc)?.split('.').pop() === 'docx'
                                            ? Docx
                                            : (new_document || doc)?.split('.').pop() === 'png'
                                            ? Png
                                            : (new_document || doc)?.split('.').pop() === 'img'
                                            ? Img
                                            : FileTypes
                                    }
                                    alt="PdfIcon"
                                />
                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        sx={{
                                            color: '#475569',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            maxWidth: '300px !important',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        {new_document?.split('/')?.pop() || doc?.split('/')?.pop()}
                                    </Typography>
                                </Box>
                            </Box>

                            <button>
                                <HiOutlineTrash onClick={removeDocument} sx={{ cursor: 'pointer' }} color="#FF0000" />
                            </button>
                        </Box>
                    )}
                </div>
            </div>
            {mitigation_plan !== 'mitigate' && mitigation_plan !== 'avoid' && (
                <>
                    <button
                        className="flex items-center gap-2 transition-all hover:brightness-90"
                        onClick={() => setContinousMonitoring(!continuous_monitoring)}
                    >
                        {continuous_monitoring ? (
                            <img
                                src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                alt="Checkbox icon"
                                className="object-contain"
                            />
                        ) : (
                            <img
                                src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                alt="Checkbox icon"
                                className="object-contain"
                            />
                        )}

                        <p className="font-medium text-sm text-[#395BA9]">Enable continuous monitoring</p>
                    </button>
                    <div className="flex flex-col gap-4 !px-6 mt-1.5" hidden={!continuous_monitoring}>
                        <AddReviewPeriod
                            type={mitigation_plan}
                            continuous_monitoring_frequency={continuous_monitoring_frequency}
                            setContinousMonitoringFrequency={setContinousMonitoringFrequency}
                        />

                        <div className="flex gap-4" hidden={!continuous_monitoring_frequency?.frequency?.length}>
                            <div>
                                <img
                                    src="/img/risk-assessment/bell-ringing-02.svg"
                                    alt="Alert Icon"
                                    className="object-contain mt-[5px]"
                                />
                            </div>

                            <button
                                className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F8FAFC] !rounded !py-1 !px-2 hover:brightness-90 transition-all"
                                onClick={() =>
                                    setContinousMonitoringFrequency({
                                        frequency: '',
                                        day_of_week: '',
                                        number_of_units: '',
                                        day_of_month: '',
                                        month: '',
                                        time: '',
                                        unit: '',
                                    })
                                }
                            >
                                <p className="font-normal text-sm text-[#64748B]">
                                    {continuous_monitoring_frequency?.frequency}
                                </p>
                                {continuous_monitoring_frequency?.day_of_week && (
                                    <p className="font-normal text-sm text-[#64748B]">
                                        {continuous_monitoring_frequency?.day_of_week}
                                    </p>
                                )}
                                {continuous_monitoring_frequency?.number_of_units && (
                                    <p className="font-normal text-sm text-[#64748B]">
                                        {continuous_monitoring_frequency?.number_of_units}
                                    </p>
                                )}
                                {continuous_monitoring_frequency?.day_of_month && (
                                    <p className="font-normal text-sm text-[#64748B]">
                                        {continuous_monitoring_frequency?.day_of_month}
                                    </p>
                                )}
                                {continuous_monitoring_frequency?.month && (
                                    <p className="font-normal text-sm text-[#64748B]">
                                        {continuous_monitoring_frequency?.month}
                                    </p>
                                )}
                                {continuous_monitoring_frequency?.time && (
                                    <p className="font-normal text-sm text-[#64748B]">
                                        {continuous_monitoring_frequency?.time}
                                    </p>
                                )}
                                {continuous_monitoring_frequency?.unit && (
                                    <p className="font-normal text-sm text-[#64748B]">
                                        {continuous_monitoring_frequency?.unit}
                                    </p>
                                )}
                                <img
                                    src="/img/risk-assessment/x-close-review.svg"
                                    alt="Delete Icon"
                                    className="object-contain"
                                />
                            </button>
                        </div>
                    </div>
                </>
            )}
            {risk_management_settings[0]?.mgt_approval && (
                <button
                    className="flex items-center gap-2 transition-all hover:brightness-90 !mt-2"
                    onClick={() => {
                        setManagementApprover(!management_approver);
                    }}
                    disabled={details?.management_approver}
                >
                    {management_approver ? (
                        <img
                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                            alt="Checkbox icon"
                            className="object-contain"
                        />
                    ) : (
                        <img
                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                            alt="Checkbox icon"
                            className="object-contain"
                        />
                    )}

                    <p className="font-medium text-sm text-[#395BA9]">Enable management approval for risk mitigation</p>
                </button>
            )}
        </div>
    );
}
function ReportRisk({ riskIdentificationTabStates, setRiskIdentificationTabStates }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(riskIdentificationTabStates.report_risk_to);
    const [searchPerson, setSearchPerson] = useState('');
    const [peopleAnchorEl, setPeopleAnchorEl] = useState(null);

    const { all_merchant_users } = useSelector((state) => state.riskAssessmentReducers);
    const all_users = all_merchant_users?.people?.concat(all_merchant_users?.users);
    return (
        <>
            <button
                className="flex items-center gap-2 transition-all hover:brightness-90"
                onClick={() => {
                    if (riskIdentificationTabStates.report_to) {
                        setRiskIdentificationTabStates((prev) => ({
                            ...prev,
                            report_risk_to: '',
                            report_to: false,
                        }));
                    } else {
                        setIsDialogOpen(true);
                    }
                }}
            >
                {riskIdentificationTabStates.report_to ? (
                    <img
                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                        alt="Checkbox icon"
                        className="object-contain"
                    />
                ) : (
                    <img
                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                        alt="Checkbox icon"
                        className="object-contain"
                    />
                )}

                <p className="font-medium text-sm text-[#475569]">
                    Report risk
                    <span className="text-[#395BA9]" hidden={!riskIdentificationTabStates.report_to}>
                        {' '}
                        to {riskIdentificationTabStates.report_risk_to}
                    </span>
                </p>
            </button>

            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '406px',
                        // height: '406px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Report risk to</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-medium text-[13px] text-[#64748B]">Name of person</p>

                        <div>
                            <button
                                aria-controls={peopleAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={peopleAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setPeopleAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        selectedPerson ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {selectedPerson ? selectedPerson : 'Enter name'}
                                </p>
                            </button>

                            <Menu
                                anchorEl={peopleAnchorEl}
                                open={Boolean(peopleAnchorEl)}
                                onClose={() => setPeopleAnchorEl(null)}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow:
                                            '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                        zIndex: 10000,
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                            >
                                <div className="flex flex-col !py-2 !min-w-[336px]">
                                    <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                        <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                            Search name
                                        </p>

                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                value={searchPerson}
                                                onChange={(event) => setSearchPerson(event.target.value)}
                                            />

                                            <img
                                                src="/img/risk-assessment/ri_search-line-small.svg"
                                                alt="Search Icon"
                                                className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="flex flex-col max-h-[180px] overflow-y-auto">
                                            {all_users
                                                .filter((person) =>
                                                    person?.email.toLowerCase()?.includes(searchPerson?.toLowerCase())
                                                )
                                                .map((person, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setSelectedPerson(person?.email);
                                                            setPeopleAnchorEl(null);
                                                        }}
                                                        className="!py-2 !px-3 text-[#475569] font-medium text-[13px] hover:bg-[#F1F5F9] transition-colors !text-left"
                                                    >
                                                        {person?.email}
                                                    </MenuItem>
                                                ))}
                                        </div>

                                        <NewPerson />
                                    </div>
                                </div>
                            </Menu>
                        </div>
                    </div>

                    <button
                        className="flex items-center gap-1 transition-all hover:brightness-90"
                        hidden={!riskIdentificationTabStates.report_risk_to}
                        onClick={() => {
                            setRiskIdentificationTabStates((prev) => ({
                                ...prev,
                                report_to: false,
                            }));
                        }}
                    >
                        <img src="/img/risk-assessment/trash.svg" alt="delete icon" className="object-contain" />

                        <p className="font-medium text-sm text-[#FF5449]">Remove report to</p>
                    </button>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center"
                        disabled={!selectedPerson}
                        onClick={() => {
                            setRiskIdentificationTabStates((prev) => ({
                                ...prev,
                                report_to: true,
                                report_risk_to: selectedPerson,
                            }));
                        }}
                    >
                        {false && <Loader2 className="mr-2 animate-spin h-4 w-4" />}
                        Save
                    </button>
                </div>
            </Dialog>
        </>
    );
}

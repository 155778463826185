import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, Grow, Stack, DialogActions } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});
const AppCenteredModalNew = (props) => {
    const theme = useTheme();
    const {
        open,
        handleClose,
        children,
        title,
        subtitle,
        width,
        minWidth,
        actions,
        height,
        headerAction,
        padding,
        maxWidth,
        titleStyle = {},
        overflow,
        sx = {},
    } = props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: minWidth ? minWidth : 370,
                    borderRadius: 1,
                    top: 0,
                    left: 0,
                    width,
                    maxWidth: maxWidth ? maxWidth : 'unset',
                    height: height ? height : 'unset',
                    m: 0,
                    zIndex: 1002,
                    padding: padding ? padding : 'unset',
                    ...sx,
                    overflow: 'hidden',
                },
            }}
            scroll="paper"
        >
            {title && (
                <DialogTitle
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.gray[900],
                        marginBottom: 0,
                        paddingBottom: '8px',
                        ...titleStyle,
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {title}
                        {headerAction}
                    </Stack>
                    {subtitle}
                </DialogTitle>
            )}
            <DialogContent
                sx={{ padding: padding ? padding : '20px 28px', pb: 0.2, overflow: overflow ? overflow : 'auto' }}
            >
                {children}
            </DialogContent>
            {actions && (
                <DialogActions sx={{ display: 'block', mt: 3, px: 3, backgroundColor: '#F8FAFC' }}>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default AppCenteredModalNew;

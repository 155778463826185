import React, { useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import AppInput from 'components/new_components/AppInput';
import LightBulb from '../../../../../assets/img/icons/light-bulb.svg';
import { LoadingButton } from '@mui/lab';
// import { toast } from 'react-toastify';

const VotersIdModal = (props) => {
    const { handleClose, open, handleBackgroundCheck, loading } = props;
    // const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null);
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState(null);

    const onClose = () => {
        handleClose();
    };

    const { t } = useTranslation('onboardingPolicy');

    const VINValidate = (val) => {
        if (!val) {
            setErrors('Voters ID is required');
            return false;
        } else if (!/^\d{10,12}$/.test(val)) {
            setErrors('Voters ID must be between 10 and 12 digits');
            return false;
        }

        setErrors(null);
        return true;
    };

    const handleChange = (e) => {
        setId(e.target.value);
        setTouched(true);
        VINValidate(e.target.value);
    };

    const validateId = async () => {
        if (VINValidate(id)) {
            await handleBackgroundCheck({ vin: id, background_checktype: 'voters_id' });
        }
    };
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {`Voters’ Card ID verification`}
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 15, height: 15 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: 'fit-content',
                        pt: '0rem',
                        pb: '1rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        {t('backgroundCheck.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={validateId}
                        loading={loading}
                    >
                        {t('backgroundCheck.verify')}
                    </LoadingButton>
                </Box>
            }
            width="511px"
            height="fit-content"
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        backgroundColor: '#F8FAFC',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '400',
                        p: 2,
                        display: 'flex',
                        alignItems: 'start',
                    }}
                >
                    <img src={LightBulb} alt="light-bulb" style={{ marginRight: '10px' }} />
                    <p
                        style={{
                            fontSize: '14px',
                            fontWeight: 400,
                        }}
                    >
                        {t('backgroundCheck.votersIdInfo')}
                    </p>
                </Typography>

                <AppInput
                    label={'Voters’ ID Number:'}
                    placeholder={'Enter ID Number'}
                    onChange={handleChange}
                    name="vin"
                    id="vin"
                    error={errors}
                />
                {touched && errors && <FormHelperText error>{errors}</FormHelperText>}
            </Box>
        </AppCenteredModalNew>
    );
};
export default VotersIdModal;

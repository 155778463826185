export const initialEmployeeValues = {
    first_name: '',
    last_name: '',
    email: '',
    position: '',
    phone: '',
    department: '',
    address: '',
    job_description: '',
    country: '',
};
export const countryOptions = [
    {
        name: 'Nigeria',
        value: 'nigeria',
    },
    {
        name: 'Ghana',
        value: 'ghana',
    },
    {
        name: 'Uganda',
        value: 'uganda',
    },
    {
        name: 'Rwanda',
        value: 'rwanda',
    },
    {
        name: 'Kenya',
        value: 'kenya',
    },
];

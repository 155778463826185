import React, { useMemo, useEffect } from 'react';
//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { Box, List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    // Bento,
    Business,
    // Computer,
    // ContactMail,
    // Devices,
    // // DnsOutlined,
    GroupAdd,
    Groups,
    // ManageAccounts,
    // MeetingRoom,
    People as PeopleIcon,
    // Person,
    // Policy,
    // PrivacyTip,
    Scanner,
    // Security,
    // StackedLineChart,
    // Store,
    // Storefront,
    // // Summarize,
    // TextSnippet,
    VerifiedUser,
    // VerticalSplit,
    // ViewCarousel,
    Notifications,
} from '@mui/icons-material';
import { GetFromNowDate } from 'utils';
import { Link } from 'react-router-dom';
//redux
import { connect } from 'react-redux';
import { ReadAllMerchantNotifications } from 'store/actions/notificationActions';

const ListIcon = (props) => {
    const { Component } = props;

    const theme = useTheme();
    return <Component sx={{ color: theme.palette.primary[500] }} />;
};
const NotificationModal = (props) => {
    const { open, handleClose, title, width, icon, all_merchant_notifications, ReadAllMerchantNotifications } = props;

    //theme
    const theme = useTheme();
    const getNotificationIconType = (type) => {
        switch (type?.toLowerCase()) {
            case 'user':
                return { icon: PeopleIcon, path: '/merchant/onboarding/computers' };
            case 'pentest':
                return { icon: Business, path: '/merchant/scans/pentest' };
            case 'asv_scan':
                return { icon: Scanner, path: '/merchant/scans/pciscans' };
            case 'pci_scan':
                return { icon: Scanner, path: '/merchant/scans/pciscans' };
            case 'compliance':
                return { icon: VerifiedUser, path: '/merchant/compliance/frameworks' };
            case 'training':
                return { icon: VerifiedUser, path: '/merchant/onboarding/training' };
            case 'vendor':
                return { icon: GroupAdd, path: '/merchant/aml/vendors' };
            case 'employee':
                return { icon: Groups, path: '/merchant/onboarding/computers' };
            default:
                return { icon: Notifications, path: '' };
        }
    };
    // memo
    const newMerchantNotificationAvailable = useMemo(
        () =>
            all_merchant_notifications &&
            all_merchant_notifications?.length &&
            all_merchant_notifications?.some((notification) => !notification?.read_status),
        [all_merchant_notifications]
    );

    useEffect(() => {
        if (open && newMerchantNotificationAvailable) {
            ReadAllMerchantNotifications();
        }
    }, [open, newMerchantNotificationAvailable]);
    return (
        <AppViewModal open={open} handleClose={handleClose} title={title} width={width} icon={icon}>
            <Box>
                <List sx={{ p: 0 }}>
                    {all_merchant_notifications
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((notification) => {
                            const type = notification?.message?.includes('training')
                                ? 'training'
                                : notification?.message?.includes('acknowledge')
                                ? 'acknowledge'
                                : notification?.type;
                            const icon = getNotificationIconType(type)?.icon;
                            const path = getNotificationIconType(type)?.path;
                            return (
                                <ListItemButton
                                    sx={{
                                        display: 'flex',
                                        borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                        py: 3,
                                        alignItems: 'center',
                                        cursor: path ? 'pointer' : 'default',
                                        // justifyContent: 'space-between',
                                    }}
                                    {...(path && { to: path })}
                                    // to={path === '' ? window.location.pathname : path}
                                    component={Link}
                                    key={notification?.id}
                                    onClick={() => path && handleClose()}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ background: theme.palette.gray[50] }}>
                                            <ListIcon Component={icon} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            width: '100%',
                                            alignItems: 'center',
                                        }}
                                        disableTypography={true}
                                    >
                                        <Box
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: theme.palette.gray[900],
                                                width: '75%',
                                            }}
                                        >
                                            {notification?.message}
                                        </Box>
                                        <Box
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: theme.palette.gray[500],
                                                fontStyle: 'italic',
                                            }}
                                        >
                                            {GetFromNowDate(
                                                notification?.date_created,
                                                'YYYY-MM-DDTHH:mm:ss',
                                                'fromNow'
                                            )}
                                        </Box>
                                    </ListItemText>
                                </ListItemButton>
                            );
                        })}
                </List>
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_notifications: state?.notificationReducers?.all_merchant_notifications,
    };
};
export default connect(mapStateToProps, { ReadAllMerchantNotifications })(NotificationModal);

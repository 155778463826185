import { useState, useEffect, useCallback } from 'react';

const useSearchParams = () => {
    const [searchParams, setSearchParamsState] = useState(new URLSearchParams(window.location.search));

    useEffect(() => {
        const updateSearchParams = () => {
            setSearchParamsState(new URLSearchParams(window.location.search));
        };

        window.addEventListener('popstate', updateSearchParams);

        return () => {
            window.removeEventListener('popstate', updateSearchParams);
        };
    }, []);

    // Get a specific search param
    const getParam = useCallback((key) => searchParams.get(key), [searchParams]);

    // Set or update a search param
    const setParam = useCallback((key, value) => {
        const newSearchParams = new URLSearchParams(window.location.search);

        if (value) {
            newSearchParams.set(key, value);
        } else {
            newSearchParams.delete(key);
        }

        const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        setSearchParamsState(newSearchParams);
    }, []);

    return { getParam, setParam, searchParams };
};

export default useSearchParams;

import React from 'react';
import { Link } from 'react-router-dom';
import Calendar from '../../../../../../assets/img/icons/calendar.svg';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { TrashIcon } from 'lucide-react';
import { formatDate } from 'views/merchant/VendorManagement/utils/utils';
import { useTranslation } from 'react-i18next';
import AppTooltip from 'components/new_components/AppTooltip';
export default function QuestionnaireHistoryItem({ questionnaire, handleDelete, details }) {
    const { t } = useTranslation('vendorManagement');
    const theme = useTheme();

    return (
        <Box
            key={questionnaire.id}
            sx={{
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '7px 10px',
                mb: 2,
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontFamily: 'Poppins',
                    }}
                >
                    <AppTooltip
                        title={
                            <Typography sx={{ fontWeight: 400, fontSize: '12px', fontFamily: 'Poppins' }}>
                                {questionnaire.has_responded ? 'Responded' : 'Not Responded'}
                            </Typography>
                        }
                        arrow
                        placement="top-start"
                        sx={{
                            '& .MuiTooltip-arrow': {
                                color: '#000',
                            },
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: '#000',
                                color: theme.palette.white.main,
                                p: 1,
                            },
                        }}
                    >
                        <span
                            className="w-[8px] h-[8px] rounded-full"
                            style={{
                                backgroundColor: questionnaire.has_responded ? '#37A372' : '#E2E8F0',
                            }}
                        ></span>
                    </AppTooltip>
                    <p className="text-md">{questionnaire.name}</p>
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        fontSize: '12px',
                        fontWeight: '400',
                        mt: '5px',
                        fontFamily: 'Poppins',
                    }}
                >
                    <img src={Calendar} alt="calendar" />
                    &nbsp; {formatDate(questionnaire.date_created)} • {questionnaire.recipient_email}
                </Box>
            </Box>
            {questionnaire?.has_responded ? (
                <Link
                    to={`/vendor/responses/${details?.id}?questionnaire=${questionnaire.id}`}
                    style={{
                        borderRadius: '8px',
                        padding: '4px 8px',
                        textTransform: 'capitalize',
                        borderColor: theme.palette.gray[200],
                        fontFamily: 'Poppins',
                        border: '1px solid ',
                        fontSize: '14px',
                    }}
                >
                    {t('table.tableAction.view_detail')}
                </Link>
            ) : (
                <div className="flex items-center justify-end">
                    <Typography
                        sx={{
                            borderRadius: '8px',
                            textTransform: 'capitalize',
                            fontFamily: 'Poppins',
                            background: '#F8FAFC',
                            padding: '2px 8px',
                            fontSize: '12px',
                            border: '1px solid #E2E8F0',
                            height: 'fit-content',
                        }}
                    >
                        Not responded
                    </Typography>

                    <IconButton sx={{ ml: 0, fontSize: '16px' }} onClick={handleDelete}>
                        <TrashIcon size={16} />
                    </IconButton>
                </div>
            )}
        </Box>
    );
}

import { Menu, MenuItem, Stack } from '@mui/material';
import React, { useState } from 'react';

import { ActiveStatusOptions } from '../../utils/constants';
// import AppSelectInput from 'components/new_components/AppSelectInput';
// import SearchIcon from '@mui/icons-material/Search';
import SearchIcon from 'assets/img/Searchicon.svg';

import VectorDown from 'assets/img/vector-down.svg';
const VendorTitle = (props) => {
    const { onSearch, status, setStatus } = props;

    // const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (e) => setAnchorEl(e.target);
    const closeMenu = () => setAnchorEl(null);
    //functions
    const handleStatusChange = (val) => {
        setStatus(val);
    };

    return (
        <Stack direction="row" alignItems="center" sx={{ ml: '-1rem', gap: 2, mb: 2.5 }}>
            <div className="relative">
                <input
                    placeholder="Search..."
                    onChange={onSearch}
                    className="text-gray-400 text-sm font-400 placeholder:text-gray-400 border border-gray-200 py-2.5 rounded-[4px] w-[230px] px-[30px] font-normal"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-3 w-[17px]" />
            </div>

            {/*    <AppSelectInput
                name="status"
                options={ActiveStatusOptions}
                defaultValue={'Status'}
                sx={{ borderRadius: '5px', height: 38, fontSize: 12, width: '150px', ml: 4 }}
                onChange={handleStatusChange}
                value={status}
                size="small"
            />
 */}
            <div className="relative">
                <button
                    className={`border border-gray-200 py-2.5 ${
                        status !== 'all' ? 'text-[#395BA9] w-[170px]' : 'text- gray-500 w-[123px]'
                    } text-[13px] font-medium rounded-[4px] flex justify-between items-center px-2 capitalize`}
                    onClick={openMenu}
                >
                    Status: {status?.replace('_', ' ') || 'All'}
                </button>
                <img
                    src={VectorDown}
                    alt="VectorDown"
                    className="text-neutral-40 w-[8px] h-[5px] absolute right-3 top-5 font-normal"
                />
            </div>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
                {[
                    {
                        name: 'All',
                        value: '',
                    },
                ]
                    .concat(ActiveStatusOptions)
                    ?.map((option) => (
                        <MenuItem
                            sx={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: 500,
                                width: '160px',
                                textTransform: 'capitalize',
                            }}
                            onClick={() => {
                                handleStatusChange(option?.value);
                                closeMenu();
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
            </Menu>
        </Stack>
    );
};

export default VendorTitle;

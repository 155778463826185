import React from 'react';
import { IconButton, Typography, Stack, Box } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import ContentBody from './ContentBody';
import CookiesTable from './CookiesTable';
import { useTranslation } from 'react-i18next';

const CookieNoticeModal = (props) => {
    const { open, handleClose } = props;
    const theme = useTheme();
    const { t } = useTranslation('auth');

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Stack direction="row" alignItems="center" gap={1}>
                    <IconButton sx={{ backgroundColor: '#EEF0FF4D' }}>
                        <HiOutlineArrowNarrowLeft />
                    </IconButton>
                    <Typography sx={{ color: theme.palette.primary[20], fontSize: 20, fontWeight: 700 }}>
                        {t('footerLinks.link2')}
                    </Typography>
                </Stack>
            }
            headerAction={
                <IconButton onClick={handleClose} sx={{ backgroundColor: '#EEF0FF4D' }}>
                    <Close />
                </IconButton>
            }
            width="720px"
            minWidth="400px"
            maxWidth="unset"
            height="90%"
        >
            <Box sx={{ my: 3 }}>
                <Box>
                    <Typography
                        component="h4"
                        sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            letterSpacing: '0.1px',
                            color: theme.palette.gray[30],
                            mb: 3,
                        }}
                    >
                        {t('cookieNotice.date')}
                    </Typography>
                    <ContentBody title={t('headers.b')}>{t('cookieNotice.who-we-are.a')} </ContentBody>
                    <ContentBody title={t('headers.o')}>{t('cookieNotice.overview')}</ContentBody>
                    <ContentBody title={t('cookieNotice.cookies.title')}>
                        {t('cookieNotice.cookies.a')}
                        <br />
                        {t('cookieNotice.cookies.b')}
                        <br />
                        {t('cookieNotice.cookies.c')}
                    </ContentBody>
                    <ContentBody title={t('headers.s')}>
                        {t('cookieNotice.cookie-list.body1')}
                        <br />
                        {t('cookieNotice.cookie-list.body2')}
                        <p className="mb-3">
                            <strong>{t('cookieNotice.cookie-list.a')}</strong>
                            {t('cookieNotice.cookie-list.b')}{' '}
                        </p>
                        <p className="mb-3">
                            <strong>{t('cookieNotice.cookie-list.c')}</strong>
                            {t('cookieNotice.cookie-list.d')}
                        </p>{' '}
                        <p className="mb-3">
                            <strong>{t('cookieNotice.cookie-list.e')}</strong>
                            {t('cookieNotice.cookie-list.f')}
                        </p>
                        <p>{t('cookieNotice.cookie-list.g')}</p>
                    </ContentBody>
                    <CookiesTable />
                    <h4 className="font-bold text-[#002C72] mb-2">{t('cookieNotice.cookie-list.sectionOne.header')}</h4>
                    <ContentBody title={t('cookieNotice.cookie-list.sectionTwo.header')}>
                        <p className="mb-4">{t('cookieNotice.cookie-list.sectionTwo.content')}</p>

                        <CookiesTable data={t('cookieNotice.necessaryCookies', { returnObjects: true })} />
                    </ContentBody>
                    <ContentBody title={t('cookieNotice.cookie-list.sectionThree.header')}>
                        <p className="mb-4">{t('cookieNotice.cookie-list.sectionThree.content')}</p>

                        <CookiesTable data={t('cookieNotice.preferenceCookies', { returnObjects: true })} />
                    </ContentBody>
                    <ContentBody title={t('cookieNotice.cookie-list.sectionFour.header')}>
                        <p className="mb-4">{t('cookieNotice.cookie-list.sectionFour.content')}</p>

                        <CookiesTable data={t('cookieNotice.statisticalCookies', { returnObjects: true })} />
                    </ContentBody>
                    <ContentBody title={t('cookieNotice.cookie-list.sectionFive.header')}>
                        <p className="mb-4">{t('cookieNotice.cookie-list.sectionFive.content')}</p>

                        <CookiesTable data={t('cookieNotice.marketingCookies', { returnObjects: true })} />
                    </ContentBody>
                    <ContentBody title={t('headers.q')}>
                        <p>{t('cookieNotice.delete')}</p>
                        <ul className="ml-3 list-disc mb-4">
                            <li className="underline">
                                <a
                                    href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Firefox
                                </a>
                            </li>
                            <li className="underline">
                                <a
                                    href="https://privacy.microsoft.com/en-us/privacystatement"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Internet explorer
                                </a>
                            </li>
                            <li className="underline">
                                <a
                                    href="https://support.google.com/chrome/answer/95647?hl=en"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Google chrome
                                </a>
                            </li>
                        </ul>
                        <p>
                            {t('cookieNotice.footer.a')}{' '}
                            <a className="underline" href="mailto:ope@smartcomplyapp.com.">
                                ope@smartcomplyapp.com.
                            </a>
                        </p>
                        <p>
                            {t('cookieNotice.footer.b')}{' '}
                            <a className="underline" href="mailto:info@ndpc.gov.ng" target="_blank" rel="noreferrer">
                                info@ndpc.gov.ng
                            </a>
                        </p>
                    </ContentBody>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

export default CookieNoticeModal;

import { Drawer, Slide } from '@mui/material';
import BackIcon from 'assets/img/arrow-narrow-right.png';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddNewTemplate, GetRiskMitagations, UpdateTemplate } from 'store/actions/riskAssessmentActions';
import { by3riskLevel, by5riskLevel } from 'utils';
import { mitigation_plans } from 'views/merchant/Risk_Assessment_2.5/components/RiskRegister/utils/constants';
import {
    NewRiskTemplateTabs,
    riskAcceptPayload,
    riskAvoidPayload,
    riskIdentificationPayload,
    riskMitigationPayload_,
    riskTransferPayload,
} from '../utils/constants';
import RiskIdentification from './RiskIdentification/RiskIdentification';
import RiskMitigation from './RiskMitigation/RiskMitigation';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const RiskAssessmentSettingsRiskTemplate = (props) => {
    const { open, handleClose, details } = props;
    // States
    const [currentTab, setCurrentTab] = useState(NewRiskTemplateTabs.riskIdentification);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [cia_impact_, setCiaImpact_] = useState(false);
    const [matrix, setMatrix] = useState('5x5');
    const [riskIdentificationTabStates, setRiskIdentificationTabStates] = useState(riskIdentificationPayload);
    const [riskMitigation, setRiskMitigation] = useState(riskMitigationPayload_);
    const [riskAccept, setRiskAccept] = useState(riskAcceptPayload);
    const [riskAvoid, setRiskAvoid] = useState(riskAvoidPayload);
    const [riskTransfer, setRiskTransfer] = useState(riskTransferPayload);
    const [cia_impact, setCiaImpact] = useState(null);
    const [management_approver, setManagementApprover] = useState(false);
    const [mitigation_plan, setMitigationPlan] = useState('mitigate');
    const [mitigation_controls, setMitigationControls] = useState([]);
    const [useAssetOwner, setUseAssetOwner] = useState(false);
    const [loadingAI, setLoadingAI] = useState(false);
    const [currency, setCurrency] = useState('NGN (₦)');
    const [vulnerabilities, setVulnerablities] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [continuous_monitoring, setContinousMonitoring] = useState(false);
    const [mitigation_array, setMitigation_array] = useState([]);
    const [residual_impact, setResidualImpact] = useState(0);
    const [residual_likelihood, setResidualLikelihood] = useState(0);
    const [continuous_monitoring_frequency, setContinousMonitoringFrequency] = useState({
        frequency: '',
        day_of_week: '',
        number_of_units: '',
        day_of_month: '',
        count: '',
        time: '',
        unit: '',
    });

    // redux

    const dispatch = useDispatch();
    const { risk_assessment_threats, risk_assessment_vulnerabilities, risk_assessment_categories } = useSelector(
        (state) => state.riskAssessmentReducers
    );

    const merchant = useSelector((state) => state.authReducers?.company_id);
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);

    const onClose = () => {
        setCurrentTab('Risk identification');
        setMatrix('5x5');
        setRiskIdentificationTabStates(riskIdentificationPayload);
        setRiskMitigation(riskMitigationPayload_);
        setRiskAccept(riskAcceptPayload);
        setError('');
        setMitigationPlan('mitigate');
        setManagementApprover(false);
        setUseAssetOwner(false);
        setCiaImpact(null);
        setCiaImpact_(false);
        setMitigationControls([]);

        setContinousMonitoring(false);
        setContinousMonitoringFrequency({
            frequency: '',
            day_of_week: '',
            number_of_units: '',
            day_of_month: '',
            count: '',
            time: '',
            unit: '',
        });
        setLoading(false);
        setRiskAvoid(riskAvoidPayload);
        setRiskTransfer(riskTransferPayload);
        setLoadingAI(false);
        setCurrency('NGN (₦)');
        setVulnerablities([]);
        setRecommendations([]);
        setMitigation_array([]);
        setResidualImpact(0);
        setResidualLikelihood(0);
        handleClose();
    };

    useEffect(() => {
        const filtered_vulnerabilities = risk_assessment_vulnerabilities?.filter((asset) =>
            details?.vulnerabilities?.includes(asset?.id)
        );
        if (details) {
            setMatrix(details?.matrix);
            setVulnerablities(filtered_vulnerabilities);
            setCurrency(details?.currency);
            setRiskIdentificationTabStates({
                template_name: details?.template_name,
                category: details?.category,
                threat: details?.threat,
                vulnerabilities: details?.vulnerabilities,
                inherent_likelihood: details?.inherent_likelihood,
                inherent_impact: details?.inherent_impact,
                existing_controls: details?.existing_controls,
            });
            setResidualImpact(details.residual_impact);
            setResidualLikelihood(details.residual_likelihood);
            setMitigationPlan(details?.mitigation_plan);

            if (details?.mitigation_plan === 'mitigate') {
                setRiskMitigation({
                    report_risk_to: details?.report_risk_to,
                    report_to: Boolean(details?.report_risk_to),
                    mitigation_owner: details?.mitigation_owner,
                    mitigation_timeline: {
                        time: details?.mitigation_timeline?.time,
                        count: details?.mitigation_timeline?.count,
                    },
                    mitigation_cost_amount: details?.mitigation_cost_amount,
                    comments: details?.comments,
                    residual_impact: details.residual_impact,
                    solution_owner: details?.solution_owner,
                });
                setMitigationControls(
                    Array.isArray(details?.mitigation_controls) ? [] : details?.mitigation_controls?.split(',')
                );
                setCurrency('NGN (₦)');
            }
            if (details?.mitigation_plan === 'accept') {
                setRiskAccept({
                    report_risk_to: details?.report_risk_to,
                    report_to: Boolean(details?.report_risk_to),
                    justification: details?.justification,
                    mitigation_owner: details?.mitigation_owner,
                    contingency_timeline: {
                        time: details?.contingency_timeline?.time,
                        count: details?.contingency_timeline?.count,
                    },
                    contingency_plan_text: details?.contingency_plan_text,
                    contingency_plan: details?.contingency_plan,
                    solution_owner: details?.solution_owner,
                });
                setResidualImpact(details?.residual_impact);
                setResidualLikelihood(details?.residual_likelihood);
                setContinousMonitoring(details?.continuous_monitoring);
                setContinousMonitoringFrequency(details?.continuous_monitoring_frequency);
            }
            if (details?.mitigation_plan === 'avoid') {
                setRiskAvoid({
                    report_risk_to: details?.report_risk_to,
                    report_to: Boolean(details?.report_risk_to),
                    risk_source: details?.risk_source,
                    mitigation_owner: details?.mitigation_owner,
                    mitigation_timeline: {
                        time: details?.mitigation_timeline?.time,
                        count: details?.mitigation_timeline?.count,
                    },
                    risk_elimination_method: details?.risk_elimination_method,
                    residual_likelihood: details?.residual_likelihood,
                    residual_impact: details?.residual_impact,
                    comments: details?.comments,
                    solution_owner: details?.solution_owner,
                });
                setContinousMonitoring(details?.continuous_monitoring);
                setContinousMonitoringFrequency(details?.continuous_monitoring_frequency);
            }
            if (details?.mitigation_plan === 'transfer') {
                setRiskTransfer({
                    report_risk_to: details?.report_risk_to,
                    report_to: Boolean(details?.report_risk_to),
                    justification: details?.justification,
                    mitigation_owner: details?.mitigation_owner,
                    mitigation_timeline: {
                        time: details?.mitigation_timeline?.time,
                        count: details?.mitigation_timeline?.count,
                    },
                    contingency_plan_text: details?.contingency_plan_text,
                    contingency_plan: details?.contingency_plan,
                    residual_likelihood: details?.residual_likelihood,
                    residual_impact: details?.residual_impact,
                    transfer_method: details.transfer_method,
                    vendor: details.vendor,
                    solution_owner: details?.solution_owner,
                });
                setContinousMonitoring(details?.continuous_monitoring);
                setContinousMonitoringFrequency(details?.continuous_monitoring_frequency);
            }
            setManagementApprover(details?.management_approver);
        }
    }, [details]);

    function RenderActiveTab() {
        switch (currentTab) {
            case NewRiskTemplateTabs.riskIdentification:
                return (
                    <RiskIdentification
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        error={error}
                        cia_impact_={cia_impact_}
                        setCiaImpact_={setCiaImpact_}
                        setCiaImpact={setCiaImpact}
                        matrix={matrix}
                        setMatrix={setMatrix}
                        details={details}
                        vulnerabilities={vulnerabilities}
                        setVulnerablities={setVulnerablities}
                    />
                );
            case NewRiskTemplateTabs.riskMitigation:
                return (
                    <RiskMitigation
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        error={error}
                        matrix={matrix}
                        setMatrix={setMatrix}
                        setRiskMitigation={setRiskMitigation}
                        riskMitigation={riskMitigation}
                        setRiskAccept={setRiskAccept}
                        riskAccept={riskAccept}
                        setRiskAvoid={setRiskAvoid}
                        riskAvoid={riskAvoid}
                        setRiskTransfer={setRiskTransfer}
                        riskTransfer={riskTransfer}
                        mitigation_plan={mitigation_plan}
                        setMitigationPlan={setMitigationPlan}
                        management_approver={management_approver}
                        setManagementApprover={setManagementApprover}
                        useAssetOwner={useAssetOwner}
                        setUseAssetOwner={setUseAssetOwner}
                        setError={setError}
                        currency={currency}
                        setCurrency={setCurrency}
                        details={details}
                        continuous_monitoring={continuous_monitoring}
                        setContinousMonitoring={setContinousMonitoring}
                        continuous_monitoring_frequency={continuous_monitoring_frequency}
                        setContinousMonitoringFrequency={setContinousMonitoringFrequency}
                        recommendations={recommendations}
                        mitigation_controls={mitigation_controls}
                        setMitigationControls={setMitigationControls}
                        residual_impact={residual_impact}
                        setResidualImpact={setResidualImpact}
                        residual_likelihood={residual_likelihood}
                        setResidualLikelihood={setResidualLikelihood}
                        loading={loadingAI}
                        mitigation_array={mitigation_array}
                    />
                );
            default:
                return (
                    <RiskIdentification
                        riskIdentificationTabStates={riskIdentificationTabStates}
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        error={error}
                        cia_impact_={cia_impact_}
                        setCiaImpact_={setCiaImpact_}
                        setCiaImpact={setCiaImpact}
                        matrix={matrix}
                        setMatrix={setMatrix}
                        details={details}
                        vulnerabilities={vulnerabilities}
                        setVulnerablities={setVulnerablities}
                    />
                );
        }
    }

    function extractTextInsideBraces(input) {
        const match = input.split(' ');
        return match ? match[0] : null;
    }
    const { template_name, category, threat, inherent_likelihood, inherent_impact } = riskIdentificationTabStates;

    const validate = !template_name || !category || !threat || !inherent_likelihood || !inherent_impact;

    const handleCreateMitigateTemplate = async () => {
        const { mitigation_cost_amount, mitigation_owner, mitigation_timeline } = riskMitigation;
        // validate
        const validate_mitigate =
            !residual_likelihood ||
            !residual_impact ||
            !mitigation_cost_amount ||
            !mitigation_owner ||
            mitigation_timeline?.count === 0 ||
            !mitigation_timeline?.time ||
            !mitigation_controls;

        if (validate) {
            setError(true);
        } else if (validate_mitigate) {
            setError(true);
        } else {
            setLoading(true);
            const body = {
                ...riskIdentificationTabStates,
                ...riskMitigation,
                mitigation_controls: mitigation_controls?.toString(),
                mitigation_plan,
                matrix,
                merchant,
                management_approver,
                currency: extractTextInsideBraces(currency),
                cia_impact,
                tag: 'custom',
                residual_likelihood,
                residual_impact,
                vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
            };
            const res = details
                ? await dispatch(UpdateTemplate(body, details?.id))
                : await dispatch(AddNewTemplate(body));
            setLoading(false);
            if (res?.success) {
                toast.success('Risk Template successfully');
                onClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    const handleCreateAcceptTemplate = async () => {
        setLoading(true);
        const body = {
            ...riskIdentificationTabStates,
            ...riskAccept,
            mitigation_plan,
            management_approver,
            matrix,
            merchant,
            continuous_monitoring,
            continuous_monitoring_frequency,
            cia_impact,
            residual_likelihood,
            residual_impact,
            tag: 'custom',
            vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
        };
        const res = details ? await dispatch(UpdateTemplate(body, details?.id)) : await dispatch(AddNewTemplate(body));
        setLoading(false);
        if (res?.success) {
            toast.success('Risk Template successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const handleCreateAvoidTemplate = async () => {
        setLoading(true);
        const body = {
            ...riskIdentificationTabStates,
            ...riskAvoid,
            mitigation_plan,
            management_approver,
            continuous_monitoring,
            continuous_monitoring_frequency,
            matrix,
            merchant,
            cia_impact,
            tag: 'custom',
            vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
        };
        const res = details ? await dispatch(UpdateTemplate(body, details?.id)) : await dispatch(AddNewTemplate(body));
        setLoading(false);
        if (res?.success) {
            toast.success('Risk Template successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const handleCreateTransferTemplate = async () => {
        setLoading(true);
        const body = {
            ...riskIdentificationTabStates,
            ...riskTransfer,
            matrix,
            mitigation_plan,
            management_approver,
            merchant,
            continuous_monitoring,
            continuous_monitoring_frequency,
            cia_impact,
            tag: 'custom',
            vulnerabilities: vulnerabilities?.map((vul) => vul?.id),
        };
        const res = details ? await dispatch(UpdateTemplate(body, details?.id)) : await dispatch(AddNewTemplate(body));
        setLoading(false);
        if (res?.success) {
            toast.success('Risk Template successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const getRiskmitigations = async () => {
        setLoadingAI(true);
        const get_threat = risk_assessment_threats?.find(
            (threat) => threat?.id === riskIdentificationTabStates?.threat
        );
        const get_category = risk_assessment_categories?.find(
            (category) => category?.id === riskIdentificationTabStates?.category
        );
        const body = {
            data: {
                category: get_category?.name,
                vulnerability: vulnerabilities?.map((vul) => vul?.name)?.toString(),
                threat: get_threat?.name,
                mitigation_plan,
            },
        };
        const res = await dispatch(GetRiskMitagations(body));
        setLoadingAI(false);
        if (res?.success) {
            setRecommendations(JSON?.parse(res?.data)?.recommendations);
        } else {
            // toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (
            open &&
            mitigation_plan &&
            vulnerabilities?.length &&
            riskIdentificationTabStates?.category &&
            riskIdentificationTabStates?.threat
        ) {
            getRiskmitigations();
        }
    }, [
        open,
        mitigation_plan,
        riskIdentificationTabStates?.threat,
        riskIdentificationTabStates?.category,
        vulnerabilities,
    ]);

    useEffect(() => {
        if (open && riskIdentificationTabStates?.inherent_likelihood && riskIdentificationTabStates?.inherent_impact) {
            const inherent_risk =
                matrix === '3x3'
                    ? by3riskLevel(
                          riskIdentificationTabStates?.inherent_likelihood *
                              riskIdentificationTabStates?.inherent_impact
                      )
                    : by5riskLevel(
                          riskIdentificationTabStates?.inherent_likelihood *
                              riskIdentificationTabStates?.inherent_impact
                      );
            if (matrix === '3x3') {
                if (inherent_risk?.type === 'Low') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_3x3?.low?.includes(plan)
                    );

                    // only update mitigation plan if mitigation plan doesn't exist in available plans or is undefined
                    (!mitigation_plan || !get_risk_settings[0]?.risk_plan_3x3?.low?.includes(mitigation_plan)) &&
                        setMitigationPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else if (inherent_risk?.type === 'Medium') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_3x3?.medium?.includes(plan)
                    );

                    // only update mitigation plan if mitigation plan doesn't exist in available plans or is undefined
                    (!mitigation_plan || !get_risk_settings[0]?.risk_plan_3x3?.medium?.includes(mitigation_plan)) &&
                        setMitigationPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_3x3?.high?.includes(plan)
                    );

                    // only update mitigation plan if mitigation plan doesn't exist in available plans or is undefined
                    (!mitigation_plan || !get_risk_settings[0]?.risk_plan_3x3?.high?.includes(mitigation_plan)) &&
                        setMitigationPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                }
            } else {
                if (inherent_risk?.type === 'Low') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_5x5?.low?.includes(plan)
                    );

                    // only update mitigation plan if mitigation plan doesn't exist in available plans or is undefined
                    (!mitigation_plan || !get_risk_settings[0]?.risk_plan_5x5?.low?.includes(mitigation_plan)) &&
                        setMitigationPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else if (inherent_risk?.type === 'Medium') {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_5x5?.medium?.includes(plan)
                    );

                    // only update mitigation plan if mitigation plan doesn't exist in available plans or is undefined
                    (!mitigation_plan || !get_risk_settings[0]?.risk_plan_5x5?.medium?.includes(mitigation_plan)) &&
                        setMitigationPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                } else {
                    const treatment_plan_check = mitigation_plans?.filter((plan) =>
                        get_risk_settings[0]?.risk_plan_5x5?.high?.includes(plan)
                    );

                    // only update mitigation plan if mitigation plan doesn't exist in available plans or is undefined
                    (!mitigation_plan || !get_risk_settings[0]?.risk_plan_5x5?.high?.includes(mitigation_plan)) &&
                        setMitigationPlan(treatment_plan_check?.[0]);
                    setMitigation_array(treatment_plan_check);
                }
            }
        }
    }, [open, riskIdentificationTabStates?.inherent_likelihood, riskIdentificationTabStates?.inherent_impact]);
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    flex: 1,
                    width: '100%',
                    overflow: 'scroll',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <>
                {' '}
                <div className="flex justify-between items-center border-b border-[#F1F5F9] px-[2rem] py-[0.5rem]">
                    <div className="cursor-pointer flex items-center gap-3" onClick={onClose}>
                        <img src={BackIcon} alt="Back icon" className="cursor-pointer" />
                        <p className="text-[#002C72] text-lg font-bold">{details ? 'Edit' : 'New'} Template</p>
                    </div>
                </div>
                <main className="!px-[72px] !py-8 bg-white flex gap-28">
                    <section className="self-start flex flex-col shadow-[0px,8px,20px,0px,#3D6BA314] !border !border-[#F1F5F9]">
                        <button
                            className={`flex items-center gap-2.5 hover:brightness-90 transition-all relative !border-b !border-[#F1F5F9] !py-4 !px-6 before:bg-[#395BA9] before:h-full before:rounded-tr before:rounded-br before:absolute before:-left-1 overflow-hidden ${
                                currentTab === NewRiskTemplateTabs.riskIdentification
                                    ? 'bg-[#F1F5F9] before:w-2'
                                    : 'bg-[#fff] before:w-0'
                            }`}
                            onClick={() => setCurrentTab(NewRiskTemplateTabs.riskIdentification)}
                        >
                            <p className="text-base font-semibold rounded-sm !py-0.5 !px-1.5 text-white bg-[#94A3B8]">
                                1
                            </p>

                            <p
                                className={`font-semibold text-base ${
                                    currentTab === NewRiskTemplateTabs.riskMitigation
                                        ? 'text-[#395BA9]'
                                        : 'text-[#3D6BA3]'
                                }`}
                            >
                                Risk identification
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-2.5 hover:brightness-90 transition-all relative !border-b !border-[#F1F5F9] !py-4 !px-6 before:bg-[#395BA9] before:h-full before:rounded-tr before:rounded-br before:absolute before:-left-1 overflow-hidden ${
                                currentTab === NewRiskTemplateTabs.riskMitigation
                                    ? 'bg-[#F1F5F9] before:w-2'
                                    : 'bg-[#fff] before:w-0'
                            }`}
                            disabled={validate && vulnerabilities?.length === 0}
                            onClick={() => {
                                setCurrentTab(NewRiskTemplateTabs.riskMitigation);
                            }}
                        >
                            <p className="text-base font-semibold rounded-sm !py-0.5 !px-1.5 text-white bg-[#94A3B8]">
                                2
                            </p>

                            <p
                                className={`font-semibold text-base ${
                                    currentTab === NewRiskTemplateTabs.riskMitigation
                                        ? 'text-[#395BA9]'
                                        : 'text-[#3D6BA3]'
                                }`}
                            >
                                Risk treatment
                            </p>
                        </button>
                    </section>

                    <section className="flex-1 !pr-24 !pb-24">
                        <RenderActiveTab />
                    </section>
                </main>
                <footer className="!border-t !border-[#F1F5F9] flex items-center justify-end !py-6 !px-8 w-full fixed bg-white bottom-0">
                    <div className="!border-t !border-[#F1F5F9] flex items-center justify-end flex justify-end bg-white gap-5">
                        {!validate && vulnerabilities?.length > 0 && (
                            <button
                                className="hover:brightness-90 transition-all text-[#395BA9] font-semibold text-base bg-[#F1F5F9] shadow-[2px,4px,8px,0px,#0000001A] rounded !py-2.5 !px-4 flex items-center gap-2"
                                onClick={() => {
                                    if (currentTab === 'Risk treatment') {
                                        setCurrentTab('Risk identification');
                                    } else {
                                        setCurrentTab('Risk treatment');
                                    }
                                }}
                            >
                                {currentTab === 'Risk treatment'
                                    ? 'Back to risk identification'
                                    : 'Proceed to risk treatment'}
                            </button>
                        )}

                        <button
                            className="hover:brightness-90 transition-all text-white font-semibold text-base bg-[#202D66] shadow-[2px,4px,8px,0px,#0000001A] disabled:opacity-50 disabled:pointer-events-none rounded !py-2.5 !px-4 flex items-center gap-2"
                            disabled={currentTab !== NewRiskTemplateTabs.riskMitigation}
                            onClick={
                                mitigation_plan === 'mitigate'
                                    ? handleCreateMitigateTemplate
                                    : mitigation_plan === 'accept'
                                    ? handleCreateAcceptTemplate
                                    : mitigation_plan === 'avoid'
                                    ? handleCreateAvoidTemplate
                                    : handleCreateTransferTemplate
                            }
                        >
                            {loading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} {details ? 'Update' : 'Save'}{' '}
                            template
                        </button>
                    </div>
                </footer>
            </>
        </Drawer>
    );
};
export default RiskAssessmentSettingsRiskTemplate;

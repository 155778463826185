import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import BreadCrumb from 'components/Breadcrumb';
import SubReqOperations from './PcidssActions/SubReqOperations';
import SubReqModal from './PcidssActions/SubReqModal';

// antd components
import { Button, Row, Col, Table, Modal } from 'antd';

// icons
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    CreatePcidss4Point0Requirement,
    GetOnePcidss4Point0Requirement,
    EditPcidss4Point0Requirement,
    DeletePcidss4Point0Requirement,
} from 'store/actions/pcidssActions';

// sub-components
const { confirm } = Modal;

const RequirementPage = (props) => {
    const { location } = props;
    //useEffect
    useEffect(() => {
        getOneRequirement();
    }, []);
    // state
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState({ content: false });
    const [oneSubReq, setOneSubReq] = useState({});

    // redux
    const dispatch = useDispatch();
    const sub_requirements = useSelector(
        (state) => state?.pcidssReducers?.one_pcidss4point0_requirement?.sub_requirements
    );

    // functions
    const getOneRequirement = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetOnePcidss4Point0Requirement(location?.state?.req_id));
        setLoading({ ...loading, content: false });
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    const openModal = (mode, data) => {
        setModal(mode);
        setOneSubReq(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const deleteSubReq = (id) => {
        confirm({
            title: "Are you sure you want to delete this sub requirement? Note: Deleting this sub requirement means you're deleting this subrequirement, documents, audit questions as well as merchant data associated with this subrequirement",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeletePcidss4Point0Requirement(id, true));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Requirement Number',
            render: (record) => `Requirement ${record.requirement_number}`,
            sorter: (a, b) => a.requirement_number - b.requirement_number,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Title',
            render: (record) => record.requirement_title,
        },
        {
            title: 'Description',
            render: (record) => record.requirement_description,
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <SubReqOperations
                    openEditModal={openModal}
                    sub_requirement={record}
                    deleteSubReq={deleteSubReq}
                    parent_req_num={location?.state?.req_num}
                />
            ),
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'PCIDSS 4.0.1', link: '/pcidss4.0' },
                            { label: `Requirement ${location?.state?.req_num}` },
                        ]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Sub-Requirement
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={sub_requirements}
                    loading={loading?.content}
                    rowKey={(sub_requirement) => sub_requirement.id}
                />
            </section>
            <SubReqModal
                open={modal}
                handleClose={closeModal}
                one_subreq={oneSubReq}
                parent_requirement={location?.state?.req_id}
                CreateRequirement={CreatePcidss4Point0Requirement}
                EditRequirement={EditPcidss4Point0Requirement}
                parent_req_num={location?.state?.req_num}
                sub_requirements={sub_requirements}
            />
        </div>
    );
};

export default RequirementPage;

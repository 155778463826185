/* eslint-disable no-unused-vars */
import { Menu, MenuItem } from '@mui/material';
import CircularLoader from 'components/Loaders/CircularLoader';
import { Loader2 } from 'lucide-react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllExceptions } from 'store/actions/automatedScansActions';
import { getType } from 'utils';
import AssignTaskModal from './Modals/AssignTaskModal';
import ViewExemptedScanDrawer from './ViewExemptedScanDrawer';

const ViewScanDrawer = lazy(() => import('./ViewScanDrawer'));

export default function ScansViewModified(props) {
    const { goBack, category, loading, isScan } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isScansExceptionLoading, setIsScansExceptionLoading] = useState(false);
    const [currentSub, setCurrentSub] = useState(null);
    const [isViewExemptedScanDrawerOpen, setIsViewExemptedScanDrawerOpen] = useState(false);

    const all_group_automated_scans = useSelector((state) => state?.automatedScansReducers?.all_group_automated_scans);

    const all_scan_exceptions = useSelector((state) => state?.automatedScansReducers?.all_scan_exceptions);

    const dispatch = useDispatch();

    const group_scans = all_group_automated_scans?.filter(
        (subcat) => subcat?.category === category?.id && (subcat?.accounts || subcat?.account)
    );

    const filtered_scan_exception = all_scan_exceptions?.find((scan) => scan?.category === category?.name);

    const getAllScansExceptions = async () => {
        try {
            setIsScansExceptionLoading(true);
            const res = await dispatch(GetAllExceptions());
            if (!res.success) {
                toast.error(res?.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsScansExceptionLoading(false);
        }
    };

    useEffect(() => {
        getAllScansExceptions();
        return () => {
            setIsScansExceptionLoading(false);
        };
    }, [all_group_automated_scans]);

    return (
        <>
            <div className="-mx-6 bg-white border-b px-[26px] !py-2 border-[#F1F5F9] flex items-center justify-between">
                <div className="flex items-center gap-3">
                    <img
                        src="/img/automated-scan/arrow-left-detailed-icon.svg"
                        alt="Arrow left Icon"
                        className="object-contain cursor-pointer"
                        onClick={() => goBack('groups')}
                    />

                    <h3 className="text-[#002C72] font-bold text-lg capitalize">{category?.name}</h3>
                </div>

                <div>
                    <button
                        aria-controls={anchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        className="relative bg-[#F1F5F9] px-2 !py-1 rounded-sm text-[#202D66] font-medium text-[13px] hover:bg-[#E2E8F0] transition-all after:absolute after:-top-[2%] after:-right-[2%] after:w-2 after:h-2 after:bg-[#FF5449] after:rounded-full after:border-2 after:border-white disabled:after:hidden flex items-center disabled:opacity-50 disabled:pointer-events-none"
                        hidden={!filtered_scan_exception}
                        disabled={isScansExceptionLoading}
                    >
                        {isScansExceptionLoading && <Loader2 className="h-4 w-4 animate-spin mr-2" />} Exempted scans
                    </button>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                        }}
                    >
                        {filtered_scan_exception?.subcategory?.map(
                            (sub, index) =>
                                Object.keys(sub?.account).length !== 0 && (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setCurrentSub(sub);
                                            setIsViewExemptedScanDrawerOpen(true);
                                            setAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {sub?.name?.replace(/_/g, ' ')}
                                    </MenuItem>
                                )
                        )}
                    </Menu>
                </div>
            </div>

            {loading ? (
                <CircularLoader />
            ) : (
                <div className="-mx-6">
                    <div className="mt-8 mx-auto w-full max-w-[954px] rounded flex flex-col !py-6 gap-6 bg-white">
                        <div className="mx-6 bg-[#F8FAFC] border border-[#F0F0FA] rounded flex gap-2 px-4 !py-2 items-start">
                            <img
                                src="/img/automated-scan/bulb-icon.svg"
                                alt="Bulb Icon"
                                className="object-contain mt-0.5"
                            />

                            <p className="text-[#64748B] font-normal text-[13px] leading-5">
                                These are the categories available for this scan constraint. Click any of them to view
                                all instances of the scans performed. You can assign a category to any of your user in
                                the same organization by clicking the more (...) button.
                            </p>
                        </div>

                        <div>
                            {group_scans?.map(
                                (sub, index) =>
                                    Object.keys(sub?.accounts || sub?.account || {}).length !== 0 && (
                                        <RenderScanCategory key={index} sub={sub} category={category} />
                                    )
                            )}
                        </div>
                    </div>
                </div>
            )}

            <ViewExemptedScanDrawer
                open={isViewExemptedScanDrawerOpen}
                onClose={() => setIsViewExemptedScanDrawerOpen(false)}
                sub={currentSub}
            />
        </>
    );
}

function RenderScanCategory({ sub, category }) {
    const [assignTask, setAssignTask] = useState(false);
    const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const role = getType('role');

    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);

    const find_failled_scan = Object?.keys(sub?.accounts || sub?.account)?.find((item) =>
        sub?.accounts ? sub?.accounts[item] === 'failed' : null
    );

    return (
        <>
            <div
                className={`border-b border-[#F1F5F9] cursor-pointer hover:bg-[#F8FAFC] transition-colors flex items-center justify-between px-6 !py-3 ${
                    find_failled_scan ? 'bg-[#FFFFFF]' : 'bg-[#F8FAFC]'
                }`}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsViewDrawerOpen(true);
                }}
            >
                <div className="flex flex-col gap-6">
                    <h5 className="text-[#395BA9] font-medium text-[15px] leading-5 capitalize">
                        {sub?.name?.replace(/_/g, ' ')}
                    </h5>

                    {Object?.keys(sub?.accounts)?.length !== 0 && (
                        <div className="flex items-center gap-2">
                            {Object?.keys(sub?.accounts)?.map((scan, index) => {
                                return scan === 'scan_time' ? null : (
                                    <div key={index} className="flex items-center gap-1">
                                        <img
                                            src={
                                                sub?.accounts[scan] === 'success'
                                                    ? '/img/automated-scan/instance-success-icon.svg'
                                                    : '/img/automated-scan/instance-error-icon.svg'
                                            }
                                            alt="Instance Icon"
                                            className="object-contain"
                                        />

                                        <p className="text-[#64748B] font-normal text-[13px] capitalize">{scan}</p>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="flex items-center gap-2">
                    <div className="flex gap-1 items-center !py-1">
                        <div
                            className={`w-1.5 h-1.5 rounded-full ${
                                find_failled_scan ? 'bg-[#FF897D]' : 'bg-[#72DAA5]'
                            }`}
                        />

                        <p className="text-[#64748B] font-semibold text-xs">
                            {find_failled_scan ? 'Needs attention' : 'OK'}
                        </p>
                    </div>

                    {sub?.accounts && sub.is_assigned_to_current_user && (
                        <p className="bg-[#D2A500] rounded-sm px-1 !py-0.5">Assigned</p>
                    )}

                    <div
                        hidden={
                            category?.is_assigned_to_current_user ||
                            sub?.is_assigned_to_current_user ||
                            role === 'auditor' ||
                            organization?.merchant_plan?.name === 'free_version' ||
                            organization?.merchant_plan?.name === '3_days_trial'
                        }
                        onClick={(event) => event.stopPropagation()}
                    >
                        <button
                            aria-controls={anchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                            }}
                            className="bg-[#F8FAFC] h-6 w-6 rounded hover:bg-[#E2E8F0] transition-colors grid place-items-center"
                        >
                            <img
                                src="/img/automated-scan/horizontal-dots-icon.svg"
                                alt="Horizontal Dots"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAssignTask(true);
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                Assign
                            </MenuItem>

                            {/* <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                            >
                                Set SLA
                            </MenuItem> */}
                        </Menu>
                    </div>
                </div>
            </div>

            <AssignTaskModal
                open={assignTask}
                onClose={() => setAssignTask(false)}
                type="Scan Sub-constraint"
                modalTitle="scan category"
                sub_category={sub}
                category={category}
            />

            <Suspense fallback={<></>}>
                <ViewScanDrawer
                    open={isViewDrawerOpen}
                    onClose={() => setIsViewDrawerOpen(false)}
                    sub={sub}
                    category={category}
                />
            </Suspense>
        </>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loaders/CircularLoader';
import { West } from '@mui/icons-material';

import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import html2canvas from 'html2canvas';
// Redux
import { connect, useSelector } from 'react-redux';
import { GetMerchantCertificate } from 'store/actions/auditActions';
import CertificateComponent from './CertificateComponent';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { jsPDF } from 'jspdf';

const ViewCertificate = (props) => {
    const { GetMerchantCertificate, merchant_info, current_client, setView, merchant_certificates } = props;
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);

    const merchantCertificates = async () => {
        setLoading(true);
        const response = await GetMerchantCertificate(client_id);
        setLoading(false);
        if (!response?.success) {
            toast.error('Certificate');
        }
    };

    useEffect(() => {
        merchantCertificates();
    }, []);

    const targetRef = useRef();

    const generateCertificate = async () => {
        if (!targetRef.current) return;

        try {
            setIsDownloading(true);
            // Ensure fonts are fully loaded before capture
            await document.fonts.ready;

            const canvas = await html2canvas(targetRef.current, {
                scale: 3, //Ensure high quality
                useCORS: true,
                backgroundColor: '#ffffff',
                letterRendering: true,
            });

            const imgData = canvas.toDataURL('image/png');

            // Create a new jsPDF instance (landscape)
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [canvas.width + 40, canvas.height + 40],
            });

            // Add image to PDF with small margin
            pdf.addImage(imgData, 'PNG', 20, 20, canvas.width, canvas.height);

            // Save PDF
            pdf.save('PCI_DSS_4_certificate.pdf', { returnPromise: true });
        } catch (error) {
            console.error('Certificate PDF generation failed:', error);
        } finally {
            setIsDownloading(false);
        }
    };
    return (
        <Box
            sx={{
                paddingBottom: 5,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 6 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.primary[900],
                        textDecoration: 'none',
                        gap: 1,
                        m: 2,
                    }}
                    role="button"
                    onClick={() => setView('table')}
                >
                    <West color="primary" />
                    <Typography sx={{ color: theme.palette.primary[900], fontWeight: 700, textDecoration: 'none' }}>
                        Back to Audit
                    </Typography>
                </Box>
                <AppLoadingButton
                    text="Download Certificate"
                    color="primary"
                    variant="contained"
                    loading={isDownloading}
                    onClick={generateCertificate}
                />
            </Stack>
            {loading ? (
                <CircularLoader />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        overflow: 'auto',
                        position: 'relative',
                        // paddingLeft: '20rem',
                    }}
                >
                    <CertificateComponent
                        certificate={merchant_certificates?.[0] || null}
                        merchant_info={merchant_info}
                        user_info={user_info}
                        current_client={current_client}
                        targetRef={targetRef}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_certificates: state?.auditReducers?.merchant_certificate,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};

export default connect(mapStateToProps, { GetMerchantCertificate })(ViewCertificate);
